import React from 'react';
import { toast } from "react-toastify";
import { CKEditor } from 'ckeditor4-react';
import { IoInformation } from "react-icons/io5";
import { BsBoxArrowInRight } from "react-icons/bs";
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import AddURL from '../import-site/add-url';
import ImportSite from '../import-site/import-site';
import Loader from '../../../component/loader/loader';
import Spinner from '../../../component/spinner/spinner';
import ImportLandingFromStore from './import-landing-from-store'
export default class Pages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            html: '',
            addURL: '',
            show: false,
            importUrl: '',
            loading: false,
            loadHtml: false,
            landing_urls: [],
            showAddURL: false,
            ransonware_file: '',
            loadingImport: false,
            capture_passwords: 0,
            capture_credentials: 0,
            auto_instant_awarenes: 0,
            showImportModal: false,
            ransomwareFileId: 0,
            ransomwareFiles: [],
            htmlKey: 1,
            landing_url: this.props.landing_url? this.props.landing_url: '-1',
        }
    }

    componentWillMount = () => {
        if (this.props.pageId) {
            axios.get('/api/pages/' + this.props.pageId, {})
                .then(res => {
                    this.setState({
                        loadHtml: true,
                        html: res.data.page.html,
                        url: res.data.page.redirect_url,
                        auto_instant_awarenes: res.data.page.bluePage,
                        ransonware_file: res.data.page.ransonware_file,
                        ransomwareFileId: res.data.page.ransomwareFileId,
                        capture_passwords: res.data.page.capture_passwords,
                        capture_credentials: res.data.page.capture_credentials,
                        landing_url: res.data.page.landing_url ? res.data.page.landing_url : '',
                    }, () => {
                        this.loadLandingUrls();
                        this.loadRansomwareFile();
                    })
                })
                .catch(err => {
                    apiError(err)
                })
        } else {
            this.setState({ loadHtml: true })
            this.loadLandingUrls();
            this.loadRansomwareFile();
        }
    }

    loadRansomwareFile = () => {
        axios.get('/api/ransomware/getFile', {})
            .then(res => {
                this.setState({ loading: false, ransomwareFiles: res.data.ransomwareFiles })
            })
            .catch(error => {
                apiError(error);
            })
    }

    loadLandingUrls = () => {
        axios.get(`/api/pages/url?status=${true}`, {})
            .then(res => {
                this.setState({ loading: false, landing_urls: res.data.landing_urls })
            })
            .catch(error => {
                apiError(error);
            })
    }

    handleChange = (e) => {
        this.setState({ html: e.editor.getData() });
    }

    handleCreateTemplate = () => {
        const data = {
            html: this.state.html,
            redirect_url: this.state.url,
            landing_url: this.state.landing_url,
            ransonware_file: this.state.ransonware_file,
            ransomwareFileId: this.state.ransomwareFileId,
            campaignTemplateId: this.props.campaignTemplateId,
            bluePage: this.state.auto_instant_awarenes ? true : false,
            capture_passwords: this.state.capture_passwords ? true : false,
            capture_credentials: this.state.capture_credentials ? true : false,
        }
        if (!this.state.landing_url || this.state.landing_url === '-1' || this.state.landing_url.length === 0 || (this.state.landing_url).trim().length === 0) {
            toast.error("Please Enter Landing URL");
            return;
        }
        if (!this.state.auto_instant_awarenes) {
            if (!this.state.url.length || (this.state.url).trim().length === 0) {
                toast.error("Redirect URL Required");
                return;
            }
        }
        if (this.props.attack_type_name === "Ransomware") {
            if (!this.state.ransonware_file || this.state.ransonware_file.length === 0 || (this.state.ransonware_file).trim().length === 0) {
                toast.error("Please Enter Ransonware File Name");
                return;
            }
            if (!this.state.ransomwareFileId || parseInt(this.state.ransomwareFileId, 10) === 0) {
                toast.error("Please Select Ransomware File");
                return;
            }
        }
        this.setState({ loading: true })
        if (this.props.pageId) {
            axios.patch('/api/pages/' + this.props.pageId, data)
                .then(res => {
                    this.setState({ loading: false })
                    this.props.handleTabChange('next');
                })
                .catch(error => {
                    apiError(error);
                    this.setState({ loading: false })
                })
        } else {
            axios.post('/api/pages/', data)
                .then(res => {
                    this.setState({ loading: false })
                    this.props.handleTabChange('next');
                    this.props.handlePageId(res.data.page.id);
                    toast.success('Landing Page Created Successfully')
                })
                .catch(error => {
                    apiError(error)
                    this.setState({ loading: false })
                })
        }
    }

    handleImportSite = async () => {
        if (this.state.importUrl.length === 0) {
            toast.error("Please Enter URL");
            return;
        }
        await this.setState({ loadingImport: true });
        axios.get('/api/import/site', { params: { url: this.state.importUrl } })
            .then(res => {
                this.setState({ 
                    html: res.data.html, 
                    loadingImport: false, 
                    htmlKey: this.state.htmlKey + 1 
                });
                this.handleImportSiteModal();
            })
            .catch(err => {
                apiError(err)
                this.setState({ loadingImport: false });
            })
    }

    handleImportSiteModal = () => {
        this.setState({ show: !this.state.show });
    }

    handleContent = (e) => {
        this.setState({ importUrl: e.target.value })
    }

    validURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    handleAddURL = () => {
        if (this.state.addURL.length === 0) {
            toast.error("Please Enter Landing URL");
            return;
        } else if (!this.validURL(this.state.addURL)) {
            toast.error("Please Enter Valid Landing URL");
            return;
        } else {
            axios.post('/api/pages/url', { url: this.state.addURL })
                .then(res => {
                    this.loadLandingUrls();
                    this.setState({ showAddURL: false })
                    toast.success('Landing URL Added Successfully')
                })
                .catch(err => {
                    apiError(err)
                })
        }
    }

    //i write this function so that this can update the state for the data that is taking in
    handleLoadSelectedStoreTemplateHtml = (html, name, capture_credentials, capture_passwords) => {
        this.setState({
            html,
            capture_passwords,
            capture_credentials,
            htmlKey: this.state.htmlKey + 1,
        }, () => {
            toast.success(`Template "${name}" Loaded Successfully`);
        });
    }

    render() {
        return (
            <>
                <div className="email-template-wrap">
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-3 tab-right-header">
                                <h6>Landing Page</h6>
                                <p>Create Landing Page</p>
                            </div>
                            <div className="col-md-9 text-right">
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Import a landing page from our template store</Tooltip>}>
                                    <Button variant="secondary" type="button" onClick={() => this.setState({ showImportModal: true })} className="mr-2">Import From Store</Button>
                                </OverlayTrigger>
                                    <OverlayTrigger
                                        placement='top'
                                          overlay={<Tooltip id='top'>Enter the URL of an existing landing page to import</Tooltip>}>
                                      <Button variant="primary" type="button" onClick={this.handleImportSiteModal} className="mr-2"><BsBoxArrowInRight className='btn-icon' />Import From URL</Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className='setMarginCss'>
                        <Form>
                            <Form.Group className='checkBoxWrap'>
                                <Form.Label>Landing Page Url
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Add URL to redirect the users at your preferred landing page</Tooltip>}>
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                    </OverlayTrigger>
                                </Form.Label>

                                <Form.Control as="select" value={this.state.landing_url} onChange={e => this.setState({ landing_url: e.target.value })}>
                                    {this.state.landing_urls.map((url, index) => {
                                        return <option key={index} disabled={!url.isValid} value={url.name}>{url.name}{url.name === this.props.landing_url ? "  (Default)" : ""}</option>
                                    })}
                                </Form.Control>

                            </Form.Group>
                        </Form>
                    </div>
                    {this.props.attack_type_name === "Ransomware" ?
                        <div className='setMarginCss'>
                            <Form.Group>
                                <Form.Label>Ransomware File Name
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>This would be the name of the executable ransomware file</Tooltip>}>
                                        <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Control type="text" value={this.state.ransonware_file} onChange={e => this.setState({ ransonware_file: e.target.value })} placeholder="Please enter ransomware file name" />
                            </Form.Group>
                        </div> : null
                    }

                    {this.props.attack_type_name === "Ransomware" ?
                        <div className='setMarginCss'>
                            <Form.Group>
                                <Form.Label>Select Ransomware File
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Choose the ransomware file you will be using for the campaign</Tooltip>}>
                                        <HelpOutlineIcon className="tooltip-icon"></HelpOutlineIcon>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control as="select" value={this.state.ransomwareFileId} onChange={(e) => this.setState({ ransomwareFileId: parseInt(e.target.value, 10) })}>
                                        <option value="-1">Select Ransomware File</option>
                                        {this.state.ransomwareFiles.map((file, index) => {
                                            return <option key={`ransomware-${index}`} value={file.id}>{file.name} ({file.description})</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Group>
                        </div> : null
                    }

                    <div className='setMarginCss'>
                        {this.state.loadHtml ?
                            <CKEditor
                                editorUrl={`${process.env.REACT_APP_API_URL}/staticpra/ckeditor/ckeditor.js`}
                                config={{
                                    allowedContent: true,
                                    fullPage: true
                                }}
                                key={this.state.htmlKey}
                                initData={this.state.html}
                                onChange={this.handleChange}
                            ></CKEditor>: null
                        }
                    </div> 
                    <div className='checkBoxWrap setMarginCss ml-1'>
                        <Form.Group controlId="formBasicCheckbox" className='mb-0'>
                            <Form.Check type="checkbox"
                                label="Capture Username/Email"
                                checked={this.state.capture_credentials}
                                onChange={e => this.setState({ capture_credentials: !this.state.capture_credentials })} />
                        </Form.Group>
                    </div>
                    <div className='checkBoxWrap setMarginCss ml-1'>
                        <Form.Group controlId="formBasicCheckbox2" className="mb-0 d-flex">
                            <Form.Check type="checkbox" checked={this.state.capture_passwords} onChange={e => this.setState({ capture_passwords: !this.state.capture_passwords })} label="Capture Password" />
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='top'>Allows you to capture the credentials of the user who has been redirected to the landing page</Tooltip>}>
                                <IoInformation className="tooltip-icon"></IoInformation>
                            </OverlayTrigger>
                        </Form.Group>
                    </div>

                    <div className='checkBoxWrap setMarginCss ml-1'>
                        <Form.Group controlId="formBasicCheckbox3" className="mb-0 d-flex">
                            <Form.Check type="checkbox" checked={this.state.auto_instant_awarenes} onChange={e => this.setState({ auto_instant_awarenes: !this.state.auto_instant_awarenes })} label="Auto-Instant Awareness" />
                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='top'>Select this to redirect the user to an awareness page as soon as they submit their credentials and password on the landing page</Tooltip>}>
                                <IoInformation className="tooltip-icon " ></IoInformation>
                            </OverlayTrigger>
                        </Form.Group>
                    </div>

                    {!this.state.auto_instant_awarenes ?
                        <Form.Group className="col-md-12 pl-0">
                            <Form.Label>Redirect URL/Link to Instant Awareness Page</Form.Label>
                            <Form.Control type="text" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} placeholder="https://awareness.threatcop.ai/abc.html" />
                        </Form.Group> : ''
                    }
                </div>

                <div className="row mt-2">
                    <div className="footer-wrapper justify-content-end d-flex">
                        <Button className="btn closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>

                <ImportSite
                    show={this.state.show}
                    importUrl={this.state.importUrl}
                    handleContent={this.handleContent}
                    handleImportSite={this.handleImportSite}
                    handleImportSiteModal={this.handleImportSiteModal}
                ></ImportSite>
                <ImportLandingFromStore
                    showImportModal={this.state.showImportModal}
                    handleClose={e => this.setState({ showImportModal: false })}
                    handleLoadSelectedStoreTemplateHtml={this.handleLoadSelectedStoreTemplateHtml}
                ></ImportLandingFromStore>
                <AddURL
                    addURL={this.state.addURL}
                    show={this.state.showAddURL}
                    handleSubmit={this.handleAddURL}
                    handleAddURL={e => this.setState({ showAddURL: false })}
                    handleContent={e => this.setState({ addURL: e.target.value })}
                ></AddURL>
                {this.state.loadingImport ? <Loader></Loader> : null}
            </>
        )
    }
}