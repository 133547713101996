import React, { Component } from "react";
import swal from "sweetalert";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from "@material-ui/core/Tooltip";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import { PiCloudArrowDownLight } from "react-icons/pi";
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { FormControl, Button, InputGroup, Form, Dropdown, Table } from 'react-bootstrap';

import axiosRequest from 'axios';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import ADPassword from '../../../component/department/adPassword';
import Department from '../../../component/department/department';
import AzureModal from '../../../component/rightside-modal/azure-modal';
import GSuiteModal from '../../../component/rightside-modal/gsuite-modal';
import ADGroups from '../../../component/rightside-modal/right-side-group-ad';

import './department.css';
var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;

export default class DepartmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            limit: 10,
            search: '',
            groups: [],
            activePage: 1,
            loading: true,
            syncLoading: false,
            showHierarchy: false,

            password: '',
            adModal: false,
            adLoading: false,
            showAdPassword: false,

            adGroups: [],
            groupModal: false,

            gsuiteModal: false,
            azureModal: false
        }
    }

    componentDidMount = () => {
        this.load();
        this.loadADPassword();
    }

    nextPath = path => this.props.history.push(path);

    load = async () => {
        // await this.setState({ loading: true })
        if (cancelRequest) cancelRequest();
        axios.get('/api/groups', {
            params: {
                page: this.state.activePage,
                limit: this.state.limit,
                search: this.state.search
            },
            cancelToken: new CancelToken(function executor(c) { cancelRequest = c })
        })
            .then(async (res) => {
                await this.setState({
                    loading: false,
                    count: res.data.count,
                    groups: res.data.groups,
                })
            })
            .catch(error => {
                if (!axiosRequest.isCancel(error)) {
                    this.setState({ groups: [], count: 0, loading: false })
                    apiError(error);
                }
            })
    }

    handleDeleteGroup = (groupId) => {
        swal({
            title: "Are You Sure You Want To Delete This Group? The Deletion Will Also Apply To TLMS If It’s Synced.",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
        })
            .then(async willSuccess => {
                if (willSuccess) {
                    await this.setState({ loading: true })
                    axios.delete('/api/groups/' + groupId, {})
                        .then(r => {
                            this.setState({ loading: false })
                            toast.success("Group Deleted Successfully");
                            this.load();
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error);
                        })
                }
            });
    }
    handleGroupCSV = () => {
        axios.get('/api/groups/csv', {})
            .then(async (res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `GroupsList.csv`); 
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
            })
            .catch(error => {
                apiError(error);
            })
    }

    loadADPassword = () => {
        axios.get('/api/meta/ad', {})
            .then(res => {
                if (res.data && res.data.data && res.data.data.value) {
                    var value = JSON.parse(res.data.data.value);
                    this.setState({ showAdPassword: !value.savePassword })
                }
            })
            .catch(err => {
                apiError(err);
            })
    }

    setTreeJson = (obj, t, group, dataObj) => {
        if (group && group.length > 0) {
            group.forEach(g => {
                var temp = {
                    "text": `<a href="/department/groups/${g.id}">${g.name}  ( ${g.targets} Targets )</a>`,
                    "id": g.id
                }
                if (dataObj && dataObj[g.id]) {
                    temp["state"] = { "opened": true }
                    temp["children"] = this.setTreeJson([], 'c', dataObj[g.id], dataObj);
                }
                obj.push(temp);
            })
        }
        return obj;
    }

    syncGroups = async () => {
        await this.setState({ syncLoading: true, loading: true })
        axios.get('/api/groups/sync', {})
            .then(r => {
                var count = 1;
                toast.success("Group Sync Will Be Done Soon");
                var interval = setInterval(() => {
                    this.load();
                    if (count === 5) {
                        clearInterval(interval);
                    }
                    count = count + 1;
                }, 5000)
                this.setState({ syncLoading: false })
            })
            .catch(error => {
                apiError(error);
                this.setState({ syncLoading: false, loading: false })
            })
    }

    handleADSAVE = async () => {
        if ((this.state.password && this.state.password.length) || !this.state.showAdPassword) {
            await this.setState({ adLoading: true })
            axios.post('/api/groups/check/AD', {
                password: this.state.password
            })
                .then(res => {
                    this.loadAdGroups();
                    toast.success(res.data.message)
                    this.setState({ adLoading: false, adModal: false })
                })
                .catch(err => {
                    apiError(err);
                    this.setState({ adLoading: false })
                })
        } else {
            toast.error('Please Enter Password');
        }
    }

    handleShowModal = () => {
        if (this.state.showAdPassword) {
            this.setState({ adModal: true })
        } else {
            this.handleADSAVE();
        }
    }

    loadAdGroups = async () => {
        await this.setState({ loading: true })
        axios.get('/api/groups/active/directory/groups', {
            password: this.state.password
        })
            .then(res => {
                this.setState({ loading: false, groupModal: true, adGroups: res.data.groups })
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
    }

    handleGSuiteModal = () => {
        this.setState({ gsuiteModal: !this.state.gsuiteModal })
    }
    handleAzureModal = () => {
        this.setState({ azureModal: !this.state.azureModal })
    }

    responseGoogle = async (response) => {
        try {
            if (response && response.accessToken && response.accessToken.length > 0) {
                this.setState({ loading: true })

                let googleAdminSettingsObject = {
                    accessToken: response.accessToken,
                    email: response.profileObj.email,
                }
                let setting = await axios.get('/api/company/getGoogleAdminSettings')
                if (setting.data.groupEnabled) {
                    googleAdminSettingsObject["groupId"] = setting.data.groupId
                }
                await axios.post('/api/groups/g-suite', googleAdminSettingsObject);
                this.load();
                this.handleGSuiteModal();
                this.setState({ gsuiteModal: false });
                toast.success("G-Suite User List Will Be Updated Soon");

            } else {
                throw Error('Invalid Response From GoogleAdmin')
            }
        } catch (err) {
            apiError(err);
            this.setState({ loading: false })
        }
    }

    responseAzure = (error, response) => {
        if (!error && response && response.accessToken && response.accessToken.length) {
            this.setState({ loading: true })
            axios.post('/api/groups/azure', {
                accessToken: response.accessToken,
                adminEmail: response.account.username ? response.account.username : null,
                groups: ((response.groups)?.length>0) ? response.groups: [{azureGroupId: null}]
            })
                .then(res => {
                    this.load();
                    this.handleAzureModal();
                    this.setState({ azureModal: false });
                    toast.success("Azure User List Will Be Updated Soon");
                })
                .catch(err => {
                    //hacky fix
                    //this code is  redundant
                    let errString = err.response.data.errors[0];
                    if (errString.includes('License Limit Exceeded')) {
                        const regex = /file:(.+)/;
                        const match = errString.match(regex);
                        let fileName;
                        if (match && match[1]) {
                            fileName = match[1].trim();
                            // Triggering a download
                            const link = document.createElement('a');
                            link.href = `${process.env.REACT_APP_API_URL}/staticpra/failedcsv/${fileName}`; // Assuming fileName is the full path to the file
                            link.download = 'failed-users.csv';   // This will make it download using the original file name. You can provide a name if you want to rename the downloaded file.
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                        err.response.data.errors[0] = 'License Limit Exceeded';
                    }
                    apiError(err);
                    this.setState({ loading: false })
                })
        } else {
            console.log(error); // Log the error to diagnose issues
        }
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Groups</h4>
                            </div>
                        </div>
                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">
                                <Dropdown className="dropdownIcon">
                                    <Dropdown.Toggle id="dropdown-basic" variant="secondary" className="dropdown-toggle mr-2">
                                        <PiCloudArrowDownLight className="ti-cloud-down tiIcon" />Import Groups
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={this.handleGSuiteModal}>Import from G-Suite</Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleAzureModal}>Import With Azure</Dropdown.Item>
                                        <Dropdown.Item onClick={this.handleShowModal}>Import from AD</Dropdown.Item>
                                        <Dropdown.Item onClick={this.syncGroups}>Sync from TLMS</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button
                                    style={{marginRight: '6px'}}
                                    variant="secondary"
                                    onClick={this.handleGroupCSV}>
                                    <span className="btn-text">Export CSV</span>
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={e => this.nextPath('/department/groups/0')}>
                                    <AddSharpIcon className="btn-icon"></AddSharpIcon>
                                    <span className="btn-text">Create Group</span>
                                </Button>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="alignItemsCenter row">
                        <div className="col-md-3 p-0">
                            <div className="positionSearchIcon">
                                <InputGroup>
                                    <FormControl type="text" placeholder="Search" value={this.state.search} className="inputSearch mr-sm-2 form-control" onChange={e => this.setState({ search: e.target.value, activePage: 1 }, () => { this.load() })} /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                                </InputGroup>
                            </div>
                        </div>

                        <div className="col-sm-9 p-0">
                            <div className="rightFilters">
                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block">
                                    <Form.Control as="select" className="mr-2 btn form-control">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Form.Group>
                                <Tooltip title="Refresh" placement="top"><Button className="mr-2 btn disable-primary-btn" onClick={this.load}><SyncIcon className="btn-icon"></SyncIcon></Button></Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentbar">
                    <div className="tableContainerWrapInner">
                        <Table responsive>
                            <thead className="bg-light">
                                <tr>
                                    <th>Group Name</th>
                                    <th>Created At</th>
                                    <th>Members</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            {this.state['groups'] && this.state['groups'].length ?
                                this.state['groups'].map((group, index) => {
                                    return <Department
                                        key={index}
                                        group={group}
                                        index={index}
                                        nextPath={this.nextPath}
                                        handleDeleteGroup={this.handleDeleteGroup}
                                    ></Department>
                                }) : <h6 className="noGroupfound">No groups found</h6>
                            }
                        </Table>
                        <div className="pagination-Wrap">
                            <div className="row">
                                <div className="col-md-6">
                                    {!this.state['loading'] ?
                                        <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.count}
                                            onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.load())}
                                        /> : null
                                    }
                                </div>
                                <div className="col-md-6 text-right d-flex align-items-center footer-pg">
                                    <div className="w-100 pgCount"><p>Total No Of Groups: {this.state.count} </p> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ADPassword
                        adModal={this.state.adModal}
                        password={this.state.password}
                        adLoading={this.state.adLoading}
                        handleADSAVE={this.handleADSAVE}
                        handleAdModal={e => this.setState({ adModal: false })}
                        handlePassword={e => this.setState({ password: e.target.value })}
                    ></ADPassword>
                    <ADGroups
                        show={this.state.groupModal}
                        adGroups={this.state.adGroups}
                        handleAdGroup={e => this.setState({ groupModal: false })}
                    ></ADGroups>
                    <GSuiteModal
                        show={this.state.gsuiteModal}
                        showHandle={this.handleGSuiteModal}
                        responseGoogle={this.responseGoogle}
                    ></GSuiteModal>
                    <AzureModal
                        show={this.state.azureModal}
                        showHandle={this.handleAzureModal}
                        responseAzure={this.responseAzure}
                    ></AzureModal>
                    {this.state.loading ? <Loader /> : null}
                </div>
            </div>
        );
    }
}