import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class SMTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: '',
            limit: '',
            ssl: false,
            pool: false,
            disabled: true,
            maxMessage: '',
            maxConnection: '',
            connectionTimeout: '',
        }
    }

    componentDidMount = () => {
        this.loadSmtp();
    }

    loadSmtp = () => {
        axios.get('/api/meta', {
            params: {
                keys: ['delay', 'limit', 'ssl', 'pool', 'maxMessage', 'maxConnection', 'connectionTimeout']
            }
        })
            .then(res => {
                var data = {};
                if (res.data && res.data.data && res.data.data.length) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].key === 'ssl' || res.data.data[i].key === 'pool') {
                            data[res.data.data[i].key] = parseInt(res.data.data[i].value, 10) === 1 ? true : false;
                        } else {
                            data[res.data.data[i].key] = res.data.data[i].value;
                        }
                    }
                    this.setState(data);
                }
            })
            .catch(err => {
                apiError(err);
            })
    }

    validate = () => {
        if (!this.state.limit || this.state.limit.length === 0) {
            toast.error('Please Enter Limit');
            return null
        }
        if (!this.state.delay || this.state.delay.length === 0) {
            toast.error('Please Enter Delay');
            return null
        }
        return true;
    }

    handleSmtp = () => {
        var data = this.validate();
        if (data) {
            axios.post('/api/meta', {
                delay: this.state.delay,
                limit: this.state.limit,
                ssl: this.state.ssl,
                pool: this.state.pool,
                maxMessage: this.state.maxMessage,
                maxConnection: this.state.maxConnection,
                connectionTimeout: this.state.connectionTimeout,
            })
                .then(res => {
                    toast.success("SMTP updated successfully");
                })
                .catch(err => {
                    apiError(err);
                })
        }
    }


    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>SMTP</h6>
                    <p>Settings for Simple Mail Transfer Protocol, enabling the system to send emails.</p>
                </div>
                <div className="col-md-4 pl-0">
                    <Form>
                        <Form.Group>
                            <Form.Label>Limit</Form.Label>
                            <Form.Control type="number" placeholder="Limit" value={this.state.limit} onChange={e => this.setState({ limit: e.target.value, disabled: false })} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Delay (In MiliSeconds)</Form.Label>
                            <Form.Control type="number" placeholder="Delay" value={this.state.delay} onChange={e => this.setState({ delay: e.target.value, disabled: false })} />
                        </Form.Group>
                        <Button variant="success" type="button" onClick={this.handleSmtp} disabled={this.state.disabled}>Update</Button>
                    </Form>
                </div>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}