import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Spinner from '../../../component/spinner/spinner';
export default class AddUser extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            lastname: '',
            firstname: '',
            emailError: false,
            buttonLoading: false,
            firstnameError: false,
        }
        this.load();
    }

    load = () => {
        if(this.props.userId && this.props.userId > 0){
            axios.get('/api/iam-user/'+this.props.userId, {})
            .then(res => {
                this.setState({
                    email: res.data.employee.email,
                    lastname: res.data.employee.lastname,
                    firstname: res.data.employee.firstname,
                })
            })
            .catch(err => apiError(err));
        }
    }

    handleResetPassword = () => {
        axios.post('/api/iam-user/resetPassword/', {
            userId: this.props.userId
        })
        .then(res => {
            this.props.handleModal();
            toast.success("Reset Password Link Has Been Sent To Emaployee Email Id");
        })
        .catch(err => apiError(err));
    }

    createUser = () => {
        if(this.state.firstname.length === 0 || (this.state.firstname).trim().length === 0){
            toast.error("Please Enter First Name");
            this.setState({firstnameError: true})
            return false;
        } else if(this.state.email.length === 0){
            toast.error("Please Enter Email Id");
            this.setState({emailError: true})
            return false;
        }   
        this.setState({buttonLoading: true})
        if(this.props.userId && this.props.userId > 0){
            axios.patch('/api/iam-user/addEmployee/'+this.props.userId, {
                firstname: (this.state.firstname).trim(),
                lastname: (this.state.lastname).trim(),
                email: this.state.email,
            })
            .then(res => {
                this.setState({
                    email: '',
                    lastname: '',
                    firstname: '',
                    buttonLoading: false,
                })
                toast.success("User Updated Successfully");
                this.props.handleModal();
            })
            .catch(err => {
                this.setState({buttonLoading: false})
                apiError(err)
            });
        } else {
            axios.post('/api/iam-user/addEmployee', {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
            })
            .then(res => {
                this.setState({
                    email: '',
                    lastname: '',
                    firstname: '',
                    buttonLoading: false,
                })
                this.props.load();
                this.props.handleModal();
                toast.success("User Added Successfully");
            })
            .catch(err => {
                this.setState({buttonLoading: false})
                apiError(err)
            });
        }
    }
    
    render() {
        return (
            <Modal show={this.props.show} className="chooseModal commonModal" onHide={() => this.props.handleModal()} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header closeButton>
                    <div id="example-custom-modal-styling-title">
                        <h5>IAM User Profile</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                        <div>
                            <Form.Group>
                                <Form.Label>First Name<span className="red-star">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter first name" isInvalid={this.state.firstnameError} value={this.state.firstname} onChange={e => this.setState({firstname: e.target.value, firstnameError: false})} />
                            </Form.Group>
                        </div>
                    
                        <div>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter last name" value={this.state.lastname} onChange={e => this.setState({lastname: e.target.value})}/>
                            </Form.Group>
                        </div>
                 

                        <div>
                        <Form.Group>
                            <Form.Label>Email Id<span className="red-star">*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter email id" value={this.state.email} onChange={e => this.setState({email: e.target.value, emailError: false})}/>
                        </Form.Group>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="#">
                        <Button variant="secondary" className="mr-2" onClick={this.props.handleModal}> Close </Button>
                        {this.props.userId && this.props.userId > 0? <Button variant="secondary" className="btn btn-warning mr-2" onClick={this.handleResetPassword}> Change Password </Button>: ''}
                        {this.props.userId && this.props.userId > 0? 
                            <Button className='btn closeBtn' onClick={this.createUser} disabled={this.state.buttonLoading}><Spinner loading={this.state.buttonLoading}></Spinner>Update User </Button>:
                            <Button className='btn btn-success' onClick={this.createUser} disabled={this.state.buttonLoading}><Spinner loading={this.state.buttonLoading}></Spinner>Add User </Button>
                        }
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }
}