import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

export default class ReplyModal extends Component {
    render() {
        try{
            setTimeout(() => {
                if(document.getElementById(`iframedetail`) && this.props.buffer && this.props.buffer.length){
                    var buffer = this.props.buffer;
                    buffer = buffer.replaceAll('&gt;', '');
                    buffer = buffer.replaceAll('&lt;', '');
                    document.getElementById(`iframedetail`).insertAdjacentHTML("beforeEnd", buffer);
                }
            }, 10)
        } catch(err){}
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} className="reply-modal" autoFocus keyboard>
                 <ClearOutlinedIcon  className="modal-cancel" onClick={this.props.handleClose}/>
                <Modal.Body>
                    <div id="iframedetail" className="lmstempWrapper"></div>
                </Modal.Body>
            </Modal>
        )
    }
}