import React, { Component } from "react";
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import {  Form, Table, Dropdown } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';

import DmiModal from './dmi-modal'
import axios from '../../../axios';
import apiError from '../../../error';

export default class Dmi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domains: [],
            showDMIModal: false,
            isDMI: false,
            enforceOnlyDMI: false,
            config: null,
            domainId: null
        }
    }

    enableOrDisableDMI = () => {
        this.setState({ isDMI: !this.state.isDMI }, () => {
            axios.post('/api/company/dmi', {
                status: this.state.isDMI
            })
            .catch(err => {
                apiError(err);
            })
        })
    };

    enableOrDisableEnforceDMI = () => {
        this.setState({ enforceOnlyDMI: !this.state.enforceOnlyDMI }, () => {
            axios.post('/api/company/enforce-dmi', {
                status: this.state.enforceOnlyDMI
            })
            .catch(err => {
                apiError(err);
            })
        })
    };

    openDMIModal = (config, domainId) => {
        this.setState({ showDMIModal: true, config, domainId });
    }

    closeDMIModal = () => {
        this.setState({ showDMIModal: false })
    }

    componentDidMount = () => {
        this.loadDomain();
        this.loadDMISetting();
    }

    loadDMISetting = () => {
        axios.get('/api/company/dmi', {})
            .then(res => {
                this.setState({ 
                    isDMI: res.data.isDMI, 
                    enforceOnlyDMI: res.data.enforceOnlyDMI 
                });
            })
            .catch(err => {
                apiError(err);
            })
    }

    loadDomain = () => {
        axios.get('/api/dmi', {})
            .then(res => {
                this.setState({ domains: res.data.domains });
            })
            .catch(err => {
                apiError(err);
            })
    }

    deleteDMI = (domainId) => {
        axios.patch('/api/dmi', { domainId })
            .then(res => {
                toast.success('DMI Disabled Successfully')
                this.loadDomain();
            })
            .catch(err => {
                apiError(err);
            })
    }

    render() {
        const { showDMIModal } = this.state;
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>DMI</h6>
                    <p>Configuration and management of internet domains associated with the organization. </p>
                </div>

                <div className="dflex mb-3">
                    <Form.Check
                        type="switch"
                        label="Enable DMI"
                        id="custom-switch-899128"
                        className="mr-3"
                        checked={this.state.isDMI}
                        onChange={this.enableOrDisableDMI}
                    />
                    {this.state.isDMI?
                        <div className="dmiCheck">
                            <Form.Group className="d-flex" controlId="formBasicCheckbox">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Set to DMI only"
                                    checked={this.state.enforceOnlyDMI}
                                    onChange={this.enableOrDisableEnforceDMI}
                                />
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="top">Enable this setting to ensure all emails are sent using only the DMI method.</Tooltip>}
                                >
                                    <IoInformation
                                        className="tooltip-icon"
                                        style={{ position: "absolute", left: "166px !important", bottom: "4px", zIndex: "10" }}
                                    ></IoInformation>
                                </OverlayTrigger>

                            </Form.Group>
                        </div>: ''
                    }
                </div>
                {this.state.isDMI?
                    <div className="tableContainerWrapInner">
                        <Table responsive>
                            <thead className="bg-light">
                                <tr>
                                    <th>Domain Name</th>
                                    <th>Configuration Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {this.state.domains.map((domain, index) => {
                                    return <tr key={'domain-' + index}>
                                        <td>{domain.name}</td>
                                        <td>{domain.isDmiConfigured? 'Configured': 'Not Configured'}</td>
                                        <td>
                                            <Dropdown
                                                className="action-dropdown dropdownIcon"
                                                ref={this.selectRef}
                                            >
                                                <Dropdown.Toggle
                                                    className="btn-only"
                                                    id="dropdown-basic"
                                                >
                                                    <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {!domain.isDmiConfigured? 
                                                        <Dropdown.Item onClick={() => this.openDMIModal(null, domain.id)}>
                                                            Add Config
                                                        </Dropdown.Item>:
                                                        <Dropdown.Item onClick={() => this.openDMIModal(domain.domain_dmi, domain.id)}>
                                                            Edit Config
                                                        </Dropdown.Item>
                                                    }
                                                    <Dropdown.Item onClick={() => this.deleteDMI(domain.id)}>
                                                        Delete Config
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>: ''
                }

                {showDMIModal && (
                    <DmiModal 
                        show={showDMIModal} 
                        config={this.state.config} 
                        loadDomain={this.loadDomain}
                        domainId={this.state.domainId}
                        handleClose={this.closeDMIModal} 
                    />
                )}
            </div>
        );
    }
}