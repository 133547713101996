import React, { Component } from 'react';
import swal from "sweetalert";
import { toast } from "react-toastify";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { Button, Form, Table, Image, Modal, Tabs, Tab } from 'react-bootstrap';
import { PiArrowsOutThin, PiNotePencil, PiTrash, PiTelegramLogo, PiCopy } from "react-icons/pi";

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../loader/loader';
import { getDate } from '../../../function';
import PreviewThumbnail from '../../preview/preview';
import TestEmails from '../../test-campaign-email/test-campaign-email';
import imageDefault from "../../../assets/campaign-thumbnail-phishing.png";

import './campaign-preview.css'

export default class CampaignPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            page: null,
            game: null,
            user: null,
            emails: '',
            loading: true,
            template: null,
            category: null,
            language: null,
            subject: null,
            training: null,
            bluePage: false,
            attackType: null,
            categoryId: null,
            showModal: false,
            landing_url: null,
            campaignName: null,
            from_address: null,
            awarenessPath: null,
            redirect_url: false,
            simulationType: null,
            showTestModal: false,
            emailTracking: false,
            capture_passwords: false,
            capture_credentials: false,
            credential_harvesting: false,

            templateName: null,
            brodcastName: null,

            imageURL: '',
            courseName: '',
            previewURL: '',
            lmsCourses: [],
            activeCourses: [],
            classCSS: {
                Active: "badges",
                Inactive: "badges",
                Draft: "badges",
            },
            courseIds: [],
            myWindow: null,

            currentSrc: '',
            showThumbnail: false,

            firstname: '',
            lastname: '',
            tab: 'simulation',
            templateImg: ''
        }
        this.load();
    }

    load = () => {
        this.setState({ loading: true })
        axios.get('/api/campaign/template/preview/' + parseInt(this.props.campaignTemplateId, 10), {})
            .then(res => {
                var campaignTemplate = res.data.campaign_template;
                this.setState({
                    loading: false,
                    user: res.data.user,
                    pageImg: res.data.pagePath,
                    game: campaignTemplate.game,
                    type: campaignTemplate.type,
                    templateImg: res.data.templatePath,
                    campaignName: campaignTemplate.name,
                    training: campaignTemplate.courseId,
                    awarenessPath: res.data.awarenessPath,
                    credential_harvesting: campaignTemplate.credential_harvesting,
                    from_address: campaignTemplate.sender ? campaignTemplate.sender.from_address : null,

                    subject: campaignTemplate.template ? campaignTemplate.template.subject : null,
                    emailTracking: campaignTemplate.template ? campaignTemplate.template.emailTracking : null,

                    templateName: campaignTemplate.template ? campaignTemplate.template.templateName : null,
                    brodcastName: campaignTemplate.template ? campaignTemplate.template.brodcastName : null,

                    category: campaignTemplate.category ? campaignTemplate.category.name : null,
                    categoryId: campaignTemplate.category ? campaignTemplate.category.id : null,
                    language: campaignTemplate.language ? campaignTemplate.language.name : null,
                    attackType: campaignTemplate.attack_type ? campaignTemplate.attack_type.name : null,
                    simulationType: campaignTemplate.simulation_type ? campaignTemplate.simulation_type.name : {},

                    bluePage: campaignTemplate.page ? campaignTemplate.page.bluePage : null,
                    landing_url: campaignTemplate.page ? campaignTemplate.page.landing_url : null,
                    capture_passwords: campaignTemplate.page ? campaignTemplate.page.capture_passwords : null,
                    capture_credentials: campaignTemplate.page ? campaignTemplate.page.capture_credentials : null,
                    redirect_url: campaignTemplate && campaignTemplate.credential_harvesting && campaignTemplate.page ? campaignTemplate.page.redirect_url : res.data.awarenessUrl,
                })
                if (campaignTemplate.courseId) {
                    var courseIds = [];
                    for (let i = 0; i < campaignTemplate.courses.length; i++) {
                        courseIds.push(campaignTemplate.courses[i].courseId)
                    }
                    this.setState({ courseIds: courseIds }, () => {
                        this.loadCourseLMS();
                    })
                }
            })
            .catch(error => {
                this.setState({ loading: false })
                apiError(error)
            })
    }

    testCampaign = (targetDetails) => {
        if (parseInt(this.props.campaignTemplateId, 10)) {
            this.setState({ loading: true })
            axios.post('/api/simulation/test', {
                targetDetails,
                campaignTemplateId: parseInt(this.props.campaignTemplateId, 10),
            })
                .then(res => {
                    toast.success("Test campaign run successfully");
                    this.setState({ loading: false, showTestModal: false })
                    setTimeout(() => { window.open('/campaign/test-results/?attackTypeId=' + this.state.attackType) }, 500);
                    this.setState({ loading: false })
                })
                .catch(error => {
                    this.setState({ loading: false })
                    apiError(error);
                })
        } else toast.error("Invalid Campaign Template");
    }

    launchCampaignTemplate = () => {
        let url = '/templates/launch/' + this.state.simulationType + '/' + this.state.attackType + '/' + this.state.credential_harvesting;
        let search = '?type=' + this.state.type + '&category=' + this.state.categoryId + '&ctID=' + parseInt(this.props.campaignTemplateId, 10);
        this.props.nextPath(`${url}${search}`)
    }

    loadCourseLMS = async () => {
        await this.setState({ loading: true });
        axios.get('/api/lms/course-detail', {
            params: {
                courseIds: this.state.courseIds
            }
        })
            .then(res => {
                var courseName = {};
                for (let i = 0; i < res.data.courses.length; i++) {
                    courseName[res.data.courses[i].id] = res.data.courses[i].name
                }
                this.setState({
                    loading: false,
                    courseName: courseName,
                    imageURL: res.data.imageURL,
                    lmsCourses: res.data.courses,
                    activeCourses: res.data.activeCourses,
                    previewURL: res.data.previewURL,
                });
            })
            .catch(err => {
                this.setState({ loading: false });
                apiError(err)
            })
    }

    handleLMS = async (courseId) => {
        axios.get('/api/lms', {
            params: {
                url: `${this.state.previewURL}${courseId}?r=Sw4s56g`
            }
        })
            .then(res => {
                window.open(res.data.url, '_blank');
            })
            .catch(err => {
                apiError(err);
            });
    }

    deleteCampaignTemplate = (campaignTemplateId) => {
        swal({
            title: "Do you want to delete CampaignTemplate?",
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        }).then((willSuccess) => {
            if (willSuccess) {
                axios
                    .delete("/api/campaign/template/" + campaignTemplateId, {})
                    .then((r) => {
                        toast.success("CampaignTemplate Deleted Successfully");
                        this.props.handleModal(false);
                        this.props.load();
                    })
                    .catch((error) => {
                        apiError(error);
                    });
            }
        });
    };

    copyCampaignTemplate = (campaignTemplateId) => {
        swal({
            title: "Do you want to clone CampaignTemplate?",
            icon: "info",
            buttons: ["No", "Yes"],
        }).then(async (willSuccess) => {
            if (willSuccess) {
                await this.setState({ loading: true });
                axios
                    .post("/api/campaign/template/clone/" + campaignTemplateId, {})
                    .then((res) => {
                        this.setState({ loading: false });
                        toast.success("Campaign Template Added Successfully");
                        this.props.nextPath("/templates/" + res.data.campaignTemplateId)
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        apiError(error);
                    });
            }
        });
    };

    getStatusInfo = (status, inCampaign) => {
        const courseIsDraft = status == "Draft";
        const courseIsActive = status == "Published" && !!inCampaign
        const courseIsInActive = status == "Active" || (status != "Draft" && !inCampaign);

        return { courseIsDraft, courseIsActive, courseIsInActive }
    }

    getNewCourseStatus = (status, courseId) => {
        const inCampaign = this.state.activeCourses ? !!this.state.activeCourses[courseId]: false;
        const { courseIsDraft, courseIsActive, courseIsInActive } = this.getStatusInfo(status, inCampaign) 
        let courseStatus = "";

        switch(true){
            case courseIsDraft: courseStatus = "Draft";         break;
            case courseIsActive: courseStatus = "Active";       break;
            case courseIsInActive: courseStatus = "Inactive";   break;
        }
        return courseStatus;
    }

    render() {
        return (
            <Modal className="info-filter right right-preview-modal" show={this.props.show} onHide={() => this.props.handleModal(false)} autoFocus keyboard>
                <Modal.Header closeButton>
                    <div>
                        <h5>Template Details</h5>
                        <p>View your template details</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div id="preview">
                        {!this.props.fromCampaign?
                            <div className='btn-container tempDdtailsBtn'>
                                {this.props.campaignType === "My Templates" ?
                                    <Button
                                        variant="secondary"
                                        className="mr-2"
                                        onClick={() => this.props.nextPath("/templates/" + this.props.campaignTemplateId)}>
                                        <PiNotePencil className="btn-icon"></PiNotePencil>Edit
                                    </Button> : null}
                                {this.props.campaignType === "My Templates" ?
                                    <Button
                                        variant="secondary"
                                        className="mr-2"
                                        onClick={(e) =>
                                            this.deleteCampaignTemplate(this.props.campaignTemplateId)
                                        }
                                    ><PiTrash className="btn-icon"></PiTrash>Delete</Button> : null}
                                <Button
                                    variant="secondary"
                                    className="mr-2"
                                    onClick={(e) =>
                                        this.copyCampaignTemplate(this.props.campaignTemplateId)
                                    }
                                >
                                    <PiCopy className="btn-icon"></PiCopy>Clone</Button>

                                <Button variant="secondary" className="mr-2" onClick={e => this.setState({ showTestModal: true })}><PiTelegramLogo className="btn-icon"></PiTelegramLogo>Test Template</Button>
                                <Button variant="primary" onClick={this.launchCampaignTemplate}> <AddSharpIcon className="btn-icon"></AddSharpIcon>Create Campaign</Button>
                                <p className="badgeYn tdBadge">{this.props.campaignType === "My Templates"? "Custom Templates": this.props.campaignType}</p>
                            </div>: null
                        }
                        <div className='templateDetailsWrapper'>
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className='templateList'>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className='keyHeading'>Template Name:</td>
                                                    {this.state.attackType === "WhatsApp" ? <td className='keyHeading'>Message Name:</td> : ""}
                                                    <td className='valueHeading'>{this.state.campaignName}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Template Category: </td>
                                                    <td className='valueHeading'><span className="p-badge">{this.state.category}</span></td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Language:</td>
                                                    <td className='valueHeading'>{this.state.language}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className='templateList'>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td className='keyHeading'>Type of Simulation :</td>
                                                    <td className='valueHeading'>{this.state.simulationType}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Attack Vector : </td>
                                                    <td className='valueHeading'>{this.state.attackType}</td>
                                                </tr>

                                                <tr>
                                                    <td className='keyHeading'>Credential Harvesting :</td>
                                                    <td className='valueHeading'> {this.state.credential_harvesting && this.state.attackType !== "Vishing" ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.attackType !== 'Vishing' && this.state.attackType !== 'Training + Quiz') ?
                            <div className='tabTamplate' id="tab-wrapper">
                                <Tabs activeKey={this.state.tab} onSelect={(tab) => this.setState({ tab: tab })}>
                                    <Tab eventKey="simulation" title="simulation">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className='contHeading'>
                                                    <h6>Email Template </h6>
                                                </div>

                                                <div className="img-hover-wrap templateView" onClick={e => {
                                                    this.state.templateImg?
                                                        this.setState({
                                                            showThumbnail: true,
                                                            currentSrc: this.state.templateImg
                                                        }):
                                                        toast.info('Email Template Image Not Available')
                                                }}>
                                                    <div className='cardImg'>
                                                        <img src={!this.state.templateImg ? imageDefault : this.state.templateImg} className="img-fluid" alt=""></img>
                                                        <div className='hover-btn'>
                                                            <PiArrowsOutThin className="btn-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.credential_harvesting ?
                                                <div className="col-md-4">
                                                    <div className='contHeading'>
                                                        <h6>Landing Page </h6>
                                                    </div>
                                                    <div className="img-hover-wrap templateView" onClick={e => {
                                                        this.state.pageImg?
                                                            this.setState({
                                                                showThumbnail: true,
                                                                currentSrc: this.state.pageImg
                                                            }):
                                                            toast.info('Landing Page Image Not Available')
                                                    }}>
                                                        <div className='cardImg'>
                                                            <img src={!this.state.pageImg ? imageDefault : this.state.pageImg} className="img-fluid" alt=""></img>
                                                            <div className='hover-btn'>
                                                                <PiArrowsOutThin className="btn-icon" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>: null
                                            }
                                            <div className="col-md-4">
                                                <div className='contHeading'>
                                                    <h6>Awareness Page </h6>
                                                </div>
                                                <div className="img-hover-wrap templateView" onClick={e => {
                                                    this.state.awarenessPath?
                                                        this.setState({
                                                            showThumbnail: true,
                                                            currentSrc: this.state.awarenessPath
                                                        }): 
                                                        toast.info('Awareness Page Image Not Available');
                                                }}>
                                                    <div className='cardImg'>
                                                        <img src={!this.state.awarenessPath ? imageDefault : this.state.awarenessPath} className="img-fluid" alt=""></img>
                                                        <div className='hover-btn'>
                                                            <PiArrowsOutThin className="btn-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='url-wrapper'>
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <div className='contHeading'>
                                                        <h6>Subject </h6>
                                                        <p>{!this.state.subject ? "No Subject Found" : this.state.subject}</p>
                                                    </div>

                                                    <div className='contHeading'>
                                                        <h6>Attacker Profile </h6>
                                                        <p> {!this.state.from_address ? "No Attacker Profile Found" : this.state.from_address}</p>
                                                    </div>
                                                </div>
                                                {this.state.credential_harvesting ?
                                                    <div className="col-md-4">
                                                        <div className='contHeading'>
                                                            <h6>Url </h6>
                                                            <p>{!this.state.landing_url ? "No URL Found" : this.state.landing_url}</p>
                                                        </div>

                                                        <Table>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='keyHeading'>Capture Credential </td>
                                                                    <td className='valueHeading'> {this.state.capture_credentials ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='keyHeading'>Capture Password </td>
                                                                    <td className='valueHeading'>{this.state.capture_passwords ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='keyHeading'>Auto-Instant Awareness </td>
                                                                    <td className='valueHeading'> {this.state.bluePage ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>: null
                                                }
                                                <div className='col-md-4'>
                                                    <div className='contHeading'>
                                                        <h6>Url </h6>
                                                        <p>{!this.state.redirect_url ? "No URL Found" : this.state.redirect_url}</p>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </Tab>

                                    <Tab eventKey="training" title="training">

                                        <div className='contHeading'>
                                            <h6>Training </h6>
                                        </div>
                                        <div className="tableContainerWrapInner">
                                            <Table className="training-table" borderless>
                                                <thead>
                                                    <tr>
                                                    <th>#</th>
                                                    <th>Course</th>
                                                    <th>Date</th>
                                                    <th>LECTURES</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.lmsCourses.length ? this.state.lmsCourses.map((course, index) => {
                                                        return <tr key={index} className="setcheckBox">
                                                            <td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="default-1"
                                                                    className="checkBox"
                                                                />
                                                            </td>
                                                            <td className="onlyForPinter">
                                                                <div className="flexTableData">
                                                                    <div className="companyLogoIcon">
                                                                      <Image src={this.state.imageURL + course.logo} alt="no image" className="training-img"></Image>
                                                                    </div>
                                                                    <div>
                                                                        <h6>{course.name}</h6>
                                                                        <p>{course.courseType}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><p>{getDate(course.createdAt)}</p></td>
                                                            <td>{course.totalLecture}</td>
                                                            <td> <p className={`badges ${this.state.classCSS[this.getNewCourseStatus(course.status, course.id)]}`}> {this.getNewCourseStatus(course.status, course.id)}</p></td>
                                                            {/* <td>{course.Language.name}</td> */}
                                                            <td><VisibilitySharpIcon className="btn-icon training-preview-icon" onClick={e => this.handleLMS(course.id)} ></VisibilitySharpIcon></td>
                                                        </tr>
                                                    }) : <p style={{ margin: 0, padding: "10px", fontSize: "12px" }}>No Data Found</p>}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>: null
                        }
                        <PreviewThumbnail
                            src={this.state.currentSrc}
                            attackType={this.state.attackType}
                            showModal={this.state.showThumbnail}
                            showHandle={e => this.setState({ showThumbnail: false })}
                        ></PreviewThumbnail>
                        <TestEmails
                            attackType={this.state.attackType}
                            emails={this.state.emails}
                            firstname={this.state.firstname}
                            lastname={this.state.lastname}
                            nextPath={path => this.props.nextPath(path)}
                            testCampaign={this.testCampaign}
                            showModal={this.state.showTestModal}
                            showHandle={e => this.setState({ showTestModal: false })}
                        ></TestEmails>
                        {this.state['loading'] ? <Loader /> : null}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}