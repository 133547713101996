import React, { Component } from "react";
import html2canvas from "html2canvas";
import Tooltip from "react-bootstrap/Tooltip";
import { WorldMap } from "react-svg-worldmap";
import { IoInformation } from "react-icons/io5";
import GavelSharpIcon from '@material-ui/icons/GavelSharp';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BarChartSharpIcon from "@material-ui/icons/BarChartSharp";
import { ProgressBar, Table, Alert, Dropdown } from "react-bootstrap";
import LocationOnSharpIcon from "@material-ui/icons/LocationOnSharp";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";
import { PiCursorClick, PiMegaphone, PiShapes, PiBug, PiDetective } from "react-icons/pi";

import TouchAppIcon from '@material-ui/icons/TouchApp';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import DescriptionSharpIcon from '@material-ui/icons/DescriptionSharp';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';

import Loader from "../../component/loader/loader";
import BreachTime from "../../component/dashboard/breach-time";
import TopCategory from "../../component/dashboard/top-category";
import MonthlyRisk from "../../component/dashboard/monthly-risk";
import TopOffender from "../../component/dashboard/top-offenders";
import PhishRate from "../../component/dashboard/phish-rate-graph";
import PhishRateLine from "../../component/dashboard/phish-rate-line";

import MonthlyRiskNew from "../../component/dashboard/monthly-risk-new";
import PhishRateNew from "../../component/dashboard/phish-rate-graph-new";
import BreachTimeNew from "../../component/dashboard/breach-time-new";
import TopOffenderNew from "../../component/dashboard/top-offenders-new";

import vishingIcon from "../../assets/Vishing.png";
import whatsappIcon from "../../assets/whatsapp.png";
import smishingIcon from "../../assets/Smishing.png";
import phishingIcon from "../../assets/Phishing.png";
import cyberScamIcon from "../../assets/CyberScam.png";
import AttachmentIcon from "../../assets/attchment.png";
import ransomewareIcon from "../../assets/Ransomeware.png";
import QRcodeIcon from "../../assets/qr-code_4299774 1.png";

import badge from "../../assets/badge.png";
import dashImg from "../../assets/dash-img.png";
import vishing from "../../assets/Vishing-r.png";
import phishing from "../../assets/Phishing-r.png";
import smishing from "../../assets/Smishing-r.png";
import cyberScam from "../../assets/CyberScam-r.png";
import ransomeware from "../../assets/Ransomeware-r.png";
import removalMedia from "../../assets/removal-back.png";

import axios from "../../axios";
import apiError from "../../error";
import { getDate, getCookie, timeInAgoFormat, setCookie } from "../../function";
import "./dashboard.css";

export default class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			evs: [],
			data: [],
			licence: {},
			attacks: [],
			message: "",
			phishRate: 0,

			loading: true,

			currentRate: 0,
			initialRate: 0,
			industryRate: 0,

			breachTime: 0,
			clickRate: {},
			totalClick: 0,
			countryCount: 0,
			topOffender: {},
			overviewCount: {},
			categoryLabel: [],
			categorySeries: [],
			licenceError: false,
			showReportBtn: false,
			recentCampaigns: [],
			countryLoaded: false,
			countryWiseClickRate: [],
			billingType: getCookie("billingType"),
			simulationRisk: { datasubmitted: [], linkclicked: [], date: [] },
			breachTimeGraph: { data: [], date: [] },
			dateGraph: [],
			currentRateGraph: [],
			industryRateGraph: [],

			Vishing: getCookie("Vishing"),
			Phishing: getCookie("Phishing"),
			Smishing: getCookie("Smishing"),
			Ransomware: getCookie("Ransomware"),
			Attachment: getCookie("Attachment"),
			QRcode: getCookie("QRcode"),
			WhatsApp: getCookie("WhatsApp"),
			RemovalMedia: getCookie("Removal Media"),
			CyberScam: getCookie("Cyber Scam"),
			layout: localStorage["layout"] ? localStorage["layout"] : "OLD",
		};
	}

	useQuery = () => {
		return new URLSearchParams(window.location.search);
	};

	componentDidMount = () => {
		this.checkValidToken();
	};

	checkValidToken = () => {
		axios
			.post("/api/auth/check-token", {})
			.then((res) => {
				this.handleRefresh();
			})
			.catch((err) => apiError(err));
	};

	handleRefresh = () => {
		this.phishRate();
		this.loadLicence();
		this.topCategory();
		this.loadOverview();
		this.topOffenders();
		this.loadBreachTime();
		this.loadAttackType();
		this.loadMostVanrable();
		this.loadTop5ClickRate();
		this.loadRecentCampaigns();
		this.industryCamparision();
		this.simulationWiseRiskGraph();
		this.industryCamparisionGraph();
	};

	loadBreachTime = () => {
		axios
			.get("/api/overview/averageBreachTime", {})
			.then((res) => {
				let total = 0,
					breachTimeGraph = { data: [], date: [] },
					results = res.data.results;
				for (let i = 0; i < results.rows.length; i++) {
					total += parseInt(results.rows[i]["breachTime"], 10);
					breachTimeGraph.date.push(parseInt(results.rows[i]["breachTime"], 10));
					breachTimeGraph.data.push(parseInt(results.rows[i]["breachTime"], 10));
				}
				let breachTime = Math.ceil(total / results.rows.length);
				this.setState({
					breachTime: breachTime ? breachTime : 0,
					breachTimeGraph,
				});
			})
			.catch((err) => apiError(err));
	};

	loadLicence = () => {
		axios
			.get("/api/overview/licence", {})
			.then((res) => {
				var expiredDate = new Date(res.data.expiredAt);
				var currentDate = new Date();
				var userCount = res.data.targetCount;
				let userLimit = res.data.userlimit;

				if (expiredDate <= currentDate) {
					this.setState({
						licenceError: true,
						message: "Your Licence has been expired.....",
					});
				} else if (userCount >= userLimit) {
					this.setState({
						licenceError: true,
						message: "User Limit has been exceeded.....",
					});
				} else {
					currentDate = new Date(
						new Date().setMonth(new Date().getMonth() + 1)
					);
					if (expiredDate <= currentDate) {
						let msg =
							"Your Licence Will Expire In " +
							(new Date() < new Date(res.data.expiredAt)
								? timeInAgoFormat(new Date(res.data.expiredAt))
								: "0 Days");
						this.setState({ licenceError: true, message: msg });
					}
				}
				this.setState({ licence: res.data });
			})
			.catch((err) => apiError(err));
	};

	loadOverview = () => {
		axios
			.get("/api/overview", {})
			.then((res) => {
				this.setState({
					overviewCount: res.data.counts ? res.data.counts : {},
				});
			})
			.catch((err) => apiError(err));
	};

	loadRecentCampaigns = () => {
		axios
			.get("/api/campaigns/recents/", {})
			.then((res) => {
				this.setState({ recentCampaigns: res.data.campaigns });
			})
			.catch((err) => apiError(err));
	};

	loadTop5ClickRate = () => {
		axios
			.get("/api/overview/countryWiseClickRate/", {})
			.then((res) => {
				let worldMap = [];
				for (let i = 0; i < res.data.results.length; i++) {
					let click = res.data.results[i];
					if (click.countryCode && click.countryCode !== "0") {
						worldMap.push({
							country: click.countryCode,
							value: parseInt(click.linkclicked, 10),
						});
					}
				}
				this.setState({
					worldMap: worldMap,
					countryLoaded: true,
					totalClick: res.data.total,
					countryCount: res.data.country,
					countryWiseClickRate: res.data.results,
				});
			})
			.catch((err) => apiError(err));
	};

	loadMostVanrable = () => {
		axios
			.get("/api/evs/most/", {})
			.then((res) => {
				this.setState({ evs: res.data.evs });
			})
			.catch((err) => apiError(err));
	};

	phishRate = () => {
		axios
			.get("/api/overview/phishRate", {})
			.then((res) => {
				this.setState({
					phishRate: res.data.phishRate ? res.data.phishRate : 0,
				});
			})
			.catch((err) => apiError(err));
	};

	topCategory = async () => {
		axios
			.get("/api/overview/topCategory", {})
			.then((res) => {
				let label = [],
					series = [];
				for (let i = 0; i < res.data.results.length; i++) {
					let category = res.data.results[i];
					label.push(category.campaign_template.category.name);
					series.push(parseInt(category["datasubmitted"], 10));
				}
				this.setState({ categoryLabel: label, categorySeries: series });
			})
			.catch((err) => {
				apiError(err);
			});
	};

	topOffenders = () => {
		axios
			.get("/api/overview/topOffenders", {})
			.then((res) => {
				let topOffender = {},
					name = [],
					count = [];
				for (let i = 0; i < 5 && i < res.data.topOffenders.length; i++) {
					if (parseInt(res.data.topOffenders[i].count, 10) > 1) {
						count.push(parseInt(res.data.topOffenders[i].count, 10));
						name.push(res.data.topOffenders[i].target.firstname);
					}
				}
				topOffender["name"] = name;
				topOffender["count"] = count;
				topOffender["count"] = count;
				this.setState({ topOffender: topOffender });
			})
			.catch((err) => apiError(err));
	};

	industryCamparision = () => {
		axios
			.get("/api/overview/industryCamparision", {})
			.then((res) => {
				this.setState({
					loading: false,
					industryRate: res.data.industry ? res.data.industry : 0,
					initialRate: res.data.initial ? res.data.initial : 0,
					currentRate: res.data.current ? res.data.current : 0,
				});
			})
			.catch((err) => {
				apiError(err);
				this.setState({ loading: false });
			});
	};

	loadAttackType = () => {
		axios
			.get("/api/attacktypes/vector", {})
			.then((res) => {
				for (let i = 0; i < res.data.attackTypes.length; i++) {
					let attack = res.data.attackTypes[i];
					this.setState({ [attack.name]: true });
					setCookie(attack.name, true, 300000);
				}
			})
			.catch((err) => {
				apiError(err);
			});
	};

	simulationWiseRiskGraph = () => {
		axios
			.get("/api/overview/campaign/risk", {})
			.then((res) => {
				if (res.data.results && res.data.results.length) {
					let linkclicked = [],
						datasubmitted = [],
						date = [];
					for (let i = 0; i < res.data.results.length; i++) {
						linkclicked.push(parseInt(res.data.results[i]["linkclicked"], 10));
						datasubmitted.push(
							parseInt(res.data.results[i]["datasubmitted"], 10)
						);
						date.push(res.data.results[i]["createdAt"]);
					}
					this.setState({
						simulationRisk: {
							linkclicked: linkclicked,
							datasubmitted: datasubmitted,
							date: date,
						},
					});
				}
			})
			.catch((err) => {
				apiError(err);
			});
	};

	secondsToHms = (d) => {
		d = Number(d);
		var h = Math.floor(d / 3600);
		var m = Math.floor((d % 3600) / 60);
		var s = Math.floor((d % 3600) % 60);

		var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + " : " : "00 : ";
		var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + " : " : "00 : ";
		var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s + "" : "00";
		return hDisplay + mDisplay + sDisplay;
	};

	industryCamparisionGraph = () => {
		axios
			.get("/api/overview/industryCamparisionGraph", {})
			.then((res) => {
				let industryRate = [],
					currentRate = [],
					date = [];
				for (let i = 0; i < res.data.results.length; i++) {
					if (res.data.results[i].total > 0) {
						currentRate.push(
							Math.ceil(
								(parseInt(res.data.results[i].datasubmitted, 10) * 100) /
								res.data.results[i].total
							)
						);
						date.push(res.data.results[i].createdAt);
						industryRate.push(parseFloat(res.data.industry.phishRisk, 10));
					}
				}
				this.setState({
					industryRateGraph: industryRate,
					currentRateGraph: currentRate,
					dateGraph: date,
				});
			})
			.catch((err) => {
				apiError(err);
			});
	};

	downloadReport = async () => {
		const input = document.getElementById("dashboard");
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			this.downloadBase64File(imgData, "dashboard.png");
		});
	};

	downloadBase64File = (base64Data, fileName) => {
		const downloadLink = document.createElement("a");
		const linkSource = base64Data;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};

	nFormatter = (num, digits) => {
		const lookup = [
			{ value: 1, symbol: "" },
			{ value: 1e3, symbol: "k" },
			{ value: 1e6, symbol: "M" },
			{ value: 1e9, symbol: "G" },
			{ value: 1e12, symbol: "T" },
			{ value: 1e15, symbol: "P" },
			{ value: 1e18, symbol: "E" },
		];
		const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		var item = lookup
			.slice()
			.reverse()
			.find(function (item) {
				return num >= item.value;
			});
		return item
			? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
			: "0";
	};

	handleRedirect = (pageUrl) => {
		this.props.history.push(pageUrl);
	};

	render() {
		const totalClick = this.state.overviewCount && this.state.overviewCount["clicks"] ? this.state.overviewCount["clicks"] : 0;
		return (
			<div className="mainBodyChildren">
				<div className="licensAlert">
					{this.state.message.length &&
						this.state.licenceError &&
						this.state.billingType === "Threatcop" ? (
						<Alert
							variant="danger"
							dismissible
							onClose={(e) => this.setState({ licenceError: false })}
						>
							{" "}
							<h6>{this.state.message}</h6>{" "}
						</Alert>
					) : (
						""
					)}
				</div>

				<div className="dashboard_Body" id="dashboard">
					<div className="breadcrumbbar">
						<div className="flexRow row">
							<div className="col-md-6 p-0">
								<div className="page-head d-flex align-items-center justify-content-between">
									<h4 className="page-title">Dashboard</h4>
								</div>
							</div>
							<div className="col-md-6 text-right p-0">
								<div>
									<Dropdown className="dropdownIcon">
										<Dropdown.Toggle id="dropdown-basic" variant="secondary" className="dropdown-toggle mr-2">Dashboard {this.state.layout === 'OLD' ? 1 : 2}</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => { localStorage["layout"] = "OLD"; this.setState({ layout: "OLD" }) }}>Dashboard 1</Dropdown.Item>
											<Dropdown.Item onClick={() => { localStorage["layout"] = "NEW"; this.setState({ layout: "NEW" }) }}>Dashboard 2</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					{this.state.layout === "NEW" ?
						<>
							<div className="row">
								<div className="col-cs-9 set_common_padding_right">
									<div className="dashCard h_sm">
										<div className="dashHeader">
											<h6>Key Metrics Summary</h6>
										</div>
										<div className="keyMetricsWrap">
											<div className="summaryData" onClick={(e) => this.handleRedirect("/reports/analytics")}>
												<div className="summaryIcon">
													<PiBug className="dash-icon icon-one"></PiBug>
												</div>

												<div
													className="summaryContent">
													<h6>Attacks</h6>
													<p>{this.state.overviewCount["attacks"]? this.state.overviewCount["attacks"]: 0}
													</p>
												</div>
											</div>

											<div className="summaryData" onClick={(e) =>
														this.handleRedirect("/reports/clickreports")
													}>
												<div className="summaryIcon">
													<PiCursorClick className="dash-icon icon-one"></PiCursorClick>
												</div>
												<div
													className="summaryContent">
													<h6>Clicks</h6>
													<p>
														{this.state.overviewCount["clicks"]? this.state.overviewCount["clicks"]: 0}
													</p>
												</div>
											</div>

											<div className="summaryData" onClick={(e) => this.handleRedirect("/reports/analytics")}>
												<div className="summaryIcon">
													<PiDetective className="dash-icon icon-one"></PiDetective>
												</div>

												<div
													className="summaryContent">
													<h6>Hacks</h6>
													<p>
														{this.state.overviewCount["hacks"]? this.state.overviewCount["hacks"]: 0}
													</p>
												</div>
											</div>

											<div className="summaryData" onClick={(e) => this.handleRedirect("/campaign")}>
												<div className="summaryIcon">
													<PiMegaphone className="dash-icon icon-one"></PiMegaphone>
												</div>

												<div
													className="summaryContent">
													<h6>Campaigns</h6>
													<p>
														{this.state.overviewCount["campaigns"]? this.state.overviewCount["campaigns"]: 0}
													</p>
												</div>
											</div>

											<div className="summaryData" onClick={(e) => this.handleRedirect("/templates")}>
												<div className="summaryIcon">
													<PiShapes className="dash-icon icon-one"></PiShapes>
												</div>

												<div
													className="summaryContent">
													<h6>Templates</h6>
													<p>
														{this.state.overviewCount["templates"] ? this.state.overviewCount["templates"]: 0}
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>Average Phish Risk Rate</h6>
										</div>

										<div className="dashBody">
											<div className="row">
												<div className='col-md-3 pr-0'>
													<div className='currentPhishrate'>
														<div className='phishBox'>
															<h5>Current Phish Risk Rate</h5>
															<h6>{this.state.phishRate}%</h6>
															<ProgressBar variant="danger" now={this.state.phishRate} />
														</div>
														<div className='phishBox'>
															<h5>Latest Campaign</h5>
															<h6>{this.state.currentRate}%</h6>
															<ProgressBar variant="success" now={this.state.currentRate} />
														</div>
														<div className='phishBox'>
															<h5>First Campaign</h5>
															<h6>{this.state.initialRate}%</h6>
															<ProgressBar variant="success" now={this.state.initialRate} />
														</div>
														<div className='phishBox'>
															<h5>Industry Rate</h5>
															<h6>{this.state.industryRate}%</h6>
															<ProgressBar variant="success" now={this.state.industryRate} />
														</div>
													</div>
												</div>
												<div className="col-md-9 set_common_padding_left">
													<div>
														<PhishRateNew
															date={
																this.state.dateGraph ? this.state.dateGraph : []
															}
															phishRate={
																this.state.industryRateGraph? this.state.industryRateGraph: []
															}
															currentRate={
																this.state.currentRateGraph? this.state.currentRateGraph: []
															}
														></PhishRateNew>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-cs-3 set_common_padding_left">
									<div className="dashCard h_sm">
										<div className="dashHeader">
											<h6>Package</h6>
										</div>

										<div className="dashBody">
											<div className="row">
												<div className="col-md-5">
													<div className="progressbarDate">
														<h6>{this.state.licence.targetCount ? this.nFormatter(this.state.licence.targetCount, 2) : 0} of {this.state.licence.userlimit ? this.nFormatter(this.state.licence.userlimit, 2) : 0}</h6>
													</div>
												</div>
												<div className="col-md-7 ">
													<div className="progressbarDate textRight">
														<h6>Employees</h6>
													</div>
												</div>
											</div>
											<ProgressBar
												striped
												variant="primary"
												now={Math.ceil(
													(parseInt(this.state.licence.targetCount, 10) * 100) /
													parseInt(this.state.licence.userlimit, 10)
												)}
											/>
											{this.state.billingType === "AppSumo" ? (
												<div className="row m-0 expiry-block">
													<div className="col-md-6 pl-0">
														<h6>Pack Expiry</h6>
													</div>
													<div className="col-md-6 pr-0 text-right">
														<span className="pink-text">LifeTime</span>
													</div>
												</div>
											) : (
												<div className="dashcontentFooter">
													<div className="row">
														<div className="col-md-4">
															<div className="progressbarDate">
																<h6>Time Left</h6>
																<span className="pink-text">
																	{new Date() <
																		new Date(this.state.licence.expiredAt)
																		? timeInAgoFormat(
																			new Date(this.state.licence.expiredAt)
																		)
																		: "0 Days"}
																</span>
															</div>
														</div>
														<div className="col-md-8">
															<div className="progressbarDate textRight">
																<h6>Pack Expiry</h6>
																<span className="pink-text">
																	{getDate(this.state.licence.expiredAt)}
																</span>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>Active Attack Vector</h6>
										</div>

										<div className="dashBody vectorWrap">
											<div className="row">
												<div className="col-sm-4">
													<div className={this.state["Phishing"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={phishingIcon} />
														</div>
														<h6>Phishing</h6>
													</div>
												</div>

												<div className="col-sm-4">
													<div className={this.state["Attachment"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={AttachmentIcon} />
														</div>
														<h6>Attachment</h6>
													</div>
												</div>

												<div className="col-sm-4">
													<div className={this.state["QRcode"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={QRcodeIcon} />
														</div>
														<h6>QR Code</h6>
													</div>
												</div>

												<div className="col-sm-4">
													<div className={this.state["Smishing"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={smishingIcon} />
														</div>
														<h6>Smishing</h6>
													</div>
												</div>


												<div className="col-sm-4">
													<div className={this.state["Vishing"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={vishingIcon} />
														</div>
														<h6>Vishing</h6>
													</div>
												</div>

												<div className="col-sm-4">
													<div className={this.state["WhatsApp"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={whatsappIcon} />
														</div>
														<h6>WhatsApp</h6>
													</div>
												</div>


												<div className="col-sm-4">
													<div className={this.state["Ransomware"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={ransomewareIcon} />
														</div>
														<h6>Ransomware</h6>
													</div>
												</div>

												<div className="col-sm-4">
													<div className={this.state["CyberScam"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={cyberScamIcon} />
														</div>
														<h6>Cyber Scam</h6>
													</div>
												</div>
												<div className="col-sm-4">
													<div className={this.state["RemovalMedia"] ? "vectorCard" : "vectorCard disabledCls"}>
														<div className="icon">
															<img alt="no image" src={phishingIcon} />
														</div>
														<h6>Removal Media</h6>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 set_common_padding_right">
									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>Recent Simulation Risk</h6>
										</div>
										<div className="dashBody">
											<MonthlyRiskNew
												simulationRisk={this.state.simulationRisk}
											></MonthlyRiskNew>
										</div>
									</div>
								</div>

								<div className="col-md-6 set_common_padding_left">
									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>Breach Time</h6>
										</div>
										<div className="dashBody">

											<div className="CurrentBreachTime">
												<h5>Your Current Breach Time</h5>
												<h6>{this.secondsToHms(this.state.breachTime)} Hr</h6>
											</div>
											<BreachTimeNew
												breachTimeGraph={this.state.breachTimeGraph}
											></BreachTimeNew>
										</div>

									</div>
								</div>

								<div className="col-md-6 set_common_padding_right">
									<div className="dashCard h_med">
										<div className="dashHeader">
											<h6>Top Repeat Offenders</h6>
										</div>
										<div className="dashBody" style={{marginTop:"-15px"}}>
											<TopOffenderNew
												topOffender={this.state.topOffender}
											></TopOffenderNew>
										</div>
									</div>
								</div>

								<div className="col-md-3 set_common_padding_left set_common_padding_right">
									<div className="dashCard h_med">
										<div className="dashHeader">
											<h6>Most Vulnerable Employees</h6>
										</div>

										<div className="dashBody">
											{this.state.evs.map((e, index) => {
												let score = e.percent;
												return (
													<div
														className="vulnerableEmployeeBox"
														key={"evs-" + index}
														style={{ cursor: "pointer" }}
														onClick={(eq) =>
															this.props.history.push("/evs/" + e.id)
														}
													>
														<div className="dFlex">
															<div className="vulnerabilityName">
																<span>{e.target.firstname.charAt(0)}</span>
															</div>
															<div className="emplName">
																<h6>{e.target.firstname}</h6>
																<p>{getDate(e.createdAt)}</p>
															</div>
														</div>
														<div className="percentag">
															{score >= 66 ? (
																<p style={{ color: "red" }}>{Math.trunc(score)}%</p>
															) : score >= 33 ? (
																<p style={{ color: "#F1B44C" }}>
																	{Math.trunc(score)}%
																</p>
															) : (
																<p style={{ color: "#82b8f5" }}>
																	{Math.trunc(score)}%
																</p>
															)}
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
								<div className="col-md-3 set_common_padding_left set_common_padding_right">
									<div className="dashCard  h_med">
										<div className="dashHeader">
											<h6>Recent Campaigns</h6>
										</div>
										<div className="dashBody">
											{this.state.recentCampaigns.map((campaign, index) => {
												let thumbnail =
													campaign.attack_type.name === "Phishing"? phishing: 
													campaign.attack_type.name === "Ransomware"? ransomeware: 
													campaign.attack_type.name === "Smishing"? smishing: 
													campaign.attack_type.name === "Vishing"? vishing: 
													campaign.attack_type.name === "Cyber Scam"? cyberScam: removalMedia;
												return (
													<div
														className="vulnerableEmployeeBox recentCamp camp-links"
														key={"recent-" + index}
														onClick={(e) =>
															this.props.history.push("/campaign/" + campaign.id + "/" + campaign.simulations[0].id)
														}
													>
														<div className="dFlex">
															<div className="imgTag">
																<img
																	src={thumbnail}
																
																	className="img-fluid"
																></img>
															</div>
															<div className="emplName">
																<h6>{campaign.name}</h6>
																<p>{getDate(campaign.createdAt)}</p>
															</div>
														</div>

														<div className="percentag">
															<ArrowForwardIosSharpIcon className="r-arrow"></ArrowForwardIosSharpIcon>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>

								<div className="col-md-9 set_common_padding_right">
									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>Location-based Click Rate</h6>
										</div>

										<div className="row">
											<div className="col-md-3">
												<div className="dashBody">
													<div className="locationCard">
														<div className="location">
															<h6>{this.state.countryCount}</h6>
															<p>Locations</p>
														</div>

														<div className="locationTable">
															<Table responsive>
																<thead>
																	<tr>
																		<th>Location</th>
																		<th>Click</th>
																	</tr>
																</thead>

																<tbody>
																	{this.state.countryWiseClickRate.map((click, index) => {
																		return <tr key={'click-' + index}>
																			<td>{click.countryCode ? click.countryCode : 'Unknown'}</td>
																			<td>{click.linkclicked}</td>
																		</tr>
																	})}

																</tbody>
															</Table>
														</div>
													</div>

												</div>

											</div>
											<div className="col-md-9">
												<div className="map-wrap">
													{(this.state.data && this.state.data.length) ||
														this.state.countryLoaded ? (
														<WorldMap
															color="red"
															size="lg"
															styleFunction={() => {
																return {
																	fill: "red",
																	stroke: "red",
																	strokeWidth: 1,
																	fillOpacity: 1,
																	strokeOpacity: 0.2,
																};
															}}
															data={this.state.worldMap}
														></WorldMap>
													) : null}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3 set_common_padding_left">
									<div className="dashCard heightL">
										<div className="dashHeader">
											<h6>High Risk Category</h6>
										</div>

										<div className="dashBody">
											<TopCategory
												categoryLabel={this.state.categoryLabel}
												categorySeries={this.state.categorySeries}
											></TopCategory>
										</div>
									</div>
								</div>
							</div>
						</> :
						<div className="row layout1">
							<div className="col-lg-9 left-dash">
								<div className="row mb-2">
									<div className="col-lg-2 card-block" onClick={e => this.handleRedirect('/reports/analytics')}>
										<h6><GavelSharpIcon className="dash-icon icon-one"></GavelSharpIcon> Attacks</h6>
										<h4>{this.state.overviewCount['attacks'] ? this.state.overviewCount['attacks'] : 0}</h4>
										{/* <p><span className="text-success"><ArrowUpwardSharpIcon className="arrow-icon"></ArrowUpwardSharpIcon> 2.1%</span> Vs last 7 days</p> */}
									</div>
									<div className="col-lg-2 card-block" onClick={e => this.handleRedirect('/reports/clickreports')}>
										<h6><TouchAppIcon className="dash-icon icon-two"></TouchAppIcon> Clicks</h6>
										<h4>{this.state.overviewCount['clicks'] ? this.state.overviewCount['clicks'] : 0}</h4>
										{/* <p><span className="text-success"><ArrowUpwardSharpIcon className="arrow-icon"></ArrowUpwardSharpIcon> 2.1%</span> Vs last 7 days</p> */}
									</div>
									<div className="col-lg-2 card-block" onClick={e => this.handleRedirect('/reports/analytics')}>
										<h6><ErrorOutlineSharpIcon className="dash-icon icon-three"></ErrorOutlineSharpIcon> Hacks</h6>
										<h4>{this.state.overviewCount['hacks'] ? this.state.overviewCount['hacks'] : 0}</h4>
										{/* <p><span className="text-success"><ArrowUpwardSharpIcon className="arrow-icon"></ArrowUpwardSharpIcon> 2.1%</span> Vs last 7 days</p> */}
									</div>
									<div className="col-lg-2 card-block" onClick={e => this.handleRedirect('/campaign')}>
										<h6><AssignmentSharpIcon className="dash-icon icon-four"></AssignmentSharpIcon> Campaigns</h6>
										<h4>{this.state.overviewCount['campaigns'] ? this.state.overviewCount['campaigns'] : 0}</h4>
										{/* <p><span className="text-success"><ArrowUpwardSharpIcon className="arrow-icon"></ArrowUpwardSharpIcon> 2.1%</span> Vs last 7 days</p> */}
									</div>
									<div className="col-lg-2 card-block" onClick={e => this.handleRedirect('/templates')}>
										<h6><DescriptionSharpIcon className="dash-icon icon-four"></DescriptionSharpIcon> Templates</h6>
										<h4>{this.state.overviewCount['templates'] ? this.state.overviewCount['templates'] : 0}</h4>
										{/* <p><span className="text-success"><ArrowUpwardSharpIcon className="arrow-icon"></ArrowUpwardSharpIcon> 2.1%</span> Vs last 7 days</p> */}
									</div>
								</div>
								<div className="row">
									<div className="col-md-8 pd_dash">
										<div className="card-wrap">
											<h6>Average Phish Risk Rate
												<OverlayTrigger
													placement='top'
													overlay={<Tooltip id='top'>Your organization's current threat posture indicated by the ratio of emails sent to data submitted</Tooltip>}>
													<IoInformation className="tooltip-icon" style={{ 'marginTop': '-5px' }} ></IoInformation>
												</OverlayTrigger>
											</h6>
											<div className="row m-0">
												<div className="col-md-3 p-0 rating-num">
													<h4>{this.state.phishRate}%</h4>
													<div className="d-flex align-items-center border-bottom">
														<BarChartSharpIcon className="green-icon"></BarChartSharpIcon>
														<p className="rate-text">Your Current <br></br>Phish Risk Rate</p>
													</div>
													<div className="d-flex align-items-center py-2">
														<div id="line" className="line-graph"></div>
														<PhishRateLine></PhishRateLine>
													</div>
													<p className="para">Industry Comparison Rating</p>
												</div>

												<div className="col-md-9 pr-0">
													<PhishRate
														date={this.state.dateGraph ? this.state.dateGraph : []}
														phishRate={this.state.industryRateGraph ? this.state.industryRateGraph : []}
														currentRate={this.state.currentRateGraph ? this.state.currentRateGraph : []}
													></PhishRate>
												</div>

											</div>
											<div className="row align-items-center justify-content-between bottom-wrap m-0">
												<div className="col-md-4">
													<p><span className="dot greend"></span> Latest Campaign</p>
													<h5>{this.state.currentRate}%</h5>
													<ProgressBar className="bg-voilet" now={Math.trunc(this.state.currentRate)} />
												</div>
												<div className="col-md-4 border-l-r">
													<p><span className="dot"></span> First Campaign</p>
													<h5>{this.state.initialRate}%</h5>
													<ProgressBar className="bg-green" now={Math.trunc(this.state.initialRate)} />
												</div>
												<div className="col-md-4">
													<p><span className="dot blue"></span> Industry Rate</p>
													<h5>{this.state.industryRate}%</h5>
													<ProgressBar className="bg-blue" now={Math.trunc(this.state.industryRate)} />
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 pd_dash">
										<div className="card-wrap">
											<h6>Average Breach Time
												<OverlayTrigger
													placement='top'
													overlay={<Tooltip id='top'>Time taken by the simulated cyber attack to breach your organization</Tooltip>}>
													<IoInformation className="tooltip-icon" style={{ 'marginTop': '-5px' }} ></IoInformation>
												</OverlayTrigger>
											</h6>
											<div className="rating-num">
												<h5>{this.secondsToHms(this.state.breachTime)} Hrs</h5>
												<div className="d-flex align-items-center">
													<BarChartSharpIcon className="green-icon"></BarChartSharpIcon>
													<p className="rate-text">Your Current <br></br>Breach Time</p>
												</div>
											</div>
											<div className="col-md-12 border-bottom pb-2">
												<BreachTime breachTimeGraph={this.state.breachTimeGraph}></BreachTime>
											</div>

											<h6 className="mb-1 mt-3">Top Repeat Offenders
												<OverlayTrigger
													placement='top'
													overlay={<Tooltip id='top'>Employees who fall for the simulated cyber attacks repeatedly</Tooltip>}>
													<IoInformation className="tooltip-icon" style={{ 'marginTop': '-5px' }} ></IoInformation>
												</OverlayTrigger>
											</h6>
											<TopOffender
												topOffender={this.state.topOffender}
											></TopOffender>
										</div>
									</div>
									<div className="col-md-4 pd_dash">
										<div className="card-wrap height-375">
											<h6 className="recent-camp">Recent Campaigns</h6>
											{this.state.recentCampaigns.map((campaign, index) => {
												let thumbnail = campaign.attack_type.name === "Phishing" ? phishing : campaign.attack_type.name === "Ransomware" ? ransomeware : campaign.attack_type.name === "Smishing" ? smishing : campaign.attack_type.name === "Vishing" ? vishing : campaign.attack_type.name === "Cyber Scam" ? cyberScam : removalMedia
												return (
													<div className="camp-links" key={'recent-' + index} onClick={e => this.props.history.push('/campaign/' + campaign.id + '/' + campaign.simulations[0].id)}>
														<img alt="no image" src={thumbnail} className="img-fluid"></img>
														<div className="ml-2">
															<h6>{campaign.name}</h6>
															<p>{getDate(campaign.createdAt)}</p>
														</div>
														<ArrowForwardIosSharpIcon className="r-arrow"></ArrowForwardIosSharpIcon>
													</div>
												)
											})}
										</div>
									</div>
									<div className="col-md-8 pd_dash">
										<div className="card-wrap height-375">
											<h6>Recent Simulation Risk
												<OverlayTrigger
													placement='top'
													overlay={<Tooltip id='top'>This graph shows the click and hack rates for the recent simulated campaigns</Tooltip>}>
													<IoInformation className="tooltip-icon" style={{ 'marginTop': '-5px' }} ></IoInformation>
												</OverlayTrigger>
											</h6>
											<MonthlyRisk
												simulationRisk={this.state.simulationRisk}
											></MonthlyRisk>
										</div>
									</div>

									<div className="col-lg-12 last-block pd_dash">
										<div className="card-wrap row m-0">
											<div className="col-md-8 border-right pl-0 pd_dash">
												<h6>Location-Based Click Rate</h6>
												<div className="d-flex geo-rating mb-3">
													<div className="">
														<h5>Campaigns</h5>
														<p>{this.state.overviewCount['campaigns'] ? this.state.overviewCount['campaigns'] : 0}</p>
													</div>
													<div className="border-left border-right l-r-mid">
														<h5>Clicks</h5>
														<p>{totalClick}</p>
													</div>
													<div className="">
														<h5>Locations</h5>
														<p>{this.state.countryCount}</p>
													</div>
												</div>
												<div className="map-wrap">
													{((this.state.data && this.state.data.length) || this.state.countryLoaded) ?
														<WorldMap
															color="red"
															size="lg"
															styleFunction={() => {
																return {
																	fill: "red",
																	stroke: "red",
																	strokeWidth: 1,
																	fillOpacity: 1,
																	strokeOpacity: 0.2,
																}
															}}
															data={this.state.worldMap}
														></WorldMap> : null
													}
												</div>
											</div>
											<div className="col-md-4 pd_dash">
												<h6>Top Locations</h6>
												<div className="text-center mid-text">
													<LocationOnSharpIcon className="location"></LocationOnSharpIcon>
													<h5>{this.state.countryCount}</h5>
													<p>Clicks</p>
												</div>
												<Table className="top-countries mb-0">
													<tbody>
														{this.state.countryWiseClickRate.map((click, index) => {
															return <tr key={'click-' + index}>
																<td>{click.countryCode ? click.countryCode : 'Unknown'}</td>
																<td>{click.linkclicked}</td>
																<td className="w-50"><ProgressBar variant="success" now={((parseInt(click.linkclicked, 10) * 100) / totalClick)} /></td>
															</tr>
														})}
													</tbody>
												</Table>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-3 pd_dash right-dash ">
								<div className="card-wrap first">
									<img alt="no image" src={dashImg} className="img-fluid"></img>
									<div className="welcome-text">
										<h6>Active Attack Vector</h6>

										<div className="row">
											<div className="col-md-6">
												<p className={this.state["Phishing"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="blue dot"></FiberManualRecordSharpIcon>Phishing</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["Smishing"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="green dot"></FiberManualRecordSharpIcon>Smishing</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["Ransomware"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="yellow dot"></FiberManualRecordSharpIcon>Ransomware</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["Vishing"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="red dot"></FiberManualRecordSharpIcon>Vishing</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["WhatsApp"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="red dot"></FiberManualRecordSharpIcon>WhatsApp</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["Attachment"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="purplee dot"></FiberManualRecordSharpIcon>Attachment</p>
											</div>
											<div className="col-md-6">
												<p className={this.state["QRcode"] ? "" : "blur-attacks disabled-attack"}><FiberManualRecordSharpIcon className="green dot"></FiberManualRecordSharpIcon>QRcode</p>
											</div>

										</div>
									</div>
								</div>
								<div className="card-wrap second mt-2">
									<h6>TSAT Package</h6>
									<div className="bg-light r-bg">
										<div className="row m-0">
											<div className="col-9">
												<div className="d-flex package-text">
													<h5>{this.state.licence.targetCount ? this.nFormatter(this.state.licence.targetCount, 2) : 0}</h5>
													<h6>of {this.state.licence.userlimit ? this.nFormatter(this.state.licence.userlimit, 2) : 0}</h6>
												</div>
												<p>Employees</p>
											</div>
											<div className="col-3">
												<img alt="no image" src={badge} className="img-fluid"></img>
											</div>
										</div>
										<ProgressBar striped variant="primary" now={Math.ceil((parseInt(this.state.licence.targetCount, 10) * 100) / parseInt(this.state.licence.userlimit, 10))} />
										{this.state.billingType === "AppSumo" ?
											<div className="row m-0 expiry-block">
												<div className="col-md-6 pl-0"><h6>Pack Expiry</h6></div>
												<div className="col-md-6 pr-0 text-right"><span className="pink-text">LifeTime</span></div>

											</div> :
											<div className="row m-0 expiry-block">
												<div className="col-md-5 pl-0">
													<p>Time Left</p>
													<span className="pink-text">{new Date() < new Date(this.state.licence.expiredAt) ? timeInAgoFormat(new Date(this.state.licence.expiredAt)) : '0 Days'}</span>
												</div>
												<div className="col-md-7 pr-0 text-right">
													<p>Pack Expiry</p>
													<span className="pink-text">{getDate(this.state.licence.expiredAt)}</span>
												</div>
											</div>
										}
									</div>

									<h6 className='mt-4 mb-2'>Most Vulnerable Employees</h6>
									<Table className="vaulnerable-table mb-0">
										<tbody>
											{this.state.evs.map((e, index) => {
												let score = e.percent;
												return <tr key={'evs-' + index} style={{ cursor: 'pointer' }} onClick={eq => this.props.history.push('/evs/' + e.id)}>
													<td><div className="rbga-name">{e.target.firstname.charAt(0)}</div></td>
													<td className="td-ellipsis">{e.target.firstname}<p className="td-p-ellipsis">{getDate(e.createdAt)}</p></td>
													<td className="text-success">{(score >= 66) ? <p style={{ "color": "red" }}>{Math.trunc(score)}%</p> : (score >= 33) ? <p style={{ "color": "#F1B44C" }}>{Math.trunc(score)}%</p> : <p style={{ "color": "#82b8f5" }}>{Math.trunc(score)}%</p>}</td>
												</tr>
											})}
										</tbody>
									</Table>
								</div>
								<div className="card-wrap mt-2 last-block setHeightPie">
									<h6>High Risk Category
										<OverlayTrigger
											placement='top'
											overlay={<Tooltip id='top'>Campaign template category that poses the highest risk to your organization</Tooltip>}>
											<IoInformation className="tooltip-icon" style={{ 'marginTop': '-5px' }} ></IoInformation>
										</OverlayTrigger>
									</h6>
									<TopCategory
										categoryLabel={this.state.categoryLabel}
										categorySeries={this.state.categorySeries}
									></TopCategory>
								</div>
							</div>
						</div>
					}
					{this.state.loading && <Loader></Loader>}
				</div>
			</div>
		);
	}
}
