import React from 'react';
import { toast } from "react-toastify";
import {CKEditor} from 'ckeditor4-react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import { BsBoxArrowInRight } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import ImportEmail from '../import-email/import-email';
import Spinner from '../../../component/spinner/spinner';

export default class AttachmentTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            subject: '',
            loading: false,
            defaultSubject: true,
            tracking: true,
            loadHtml: false,
            attachments: [],
            emailContent: '',
            changeUrls: false,
            attachmentKey: null,
            loadingImport: false,
            htmlKey: 1,
            colorCode: "",
            updateHtml: "",
            attachmentFileName: "",
            htmlContent: (this.props.templateResponse)? this.props.templateResponse[this.props.currentIndex]: '',
            html: (this.props.templateResponse)? this.props.templateResponse[this.props.currentIndex]: '<html> <head> <title></title> </head> <body data-gr-ext-disabled="forever" data-gr-ext-installed="" data-new-gr-c-s-check-loaded="14.991.0"> <p>{{.tracker}}</p> </body> </html>',
        }
    }
    // componentDidMount = async () => {

    //     this.setState({ colorCode: this.props.backgroundHeader })
    //     if (this.props.backgroundHeader || this.props.logoPosition) {
    //         if (this.props.backgroundHeader === null) this.props.updateBackgroundHeader(this.props.defaultColor)
    //         if (this.props.logoPosition === null) this.props.updateLogoPosition("left")
    //         let patternHeader = new RegExp(/\.Aitemplate-header\s*{\s*background-color:\s*#[0-9a-fA-F]+;[^}]*text-align:\s*(center|left|right)/g);
    //         let patternFooter = new RegExp(/\.Aitemplate-footer\s*{\s*background-color:\s*#[0-9a-fA-F]+;/g);
    //         let str = this.state.htmlContent.replace(patternHeader, `.Aitemplate-header { \n background-color: ${this.props.backgroundHeader};\n padding: 20px;\n text-align: ${this.props.logoPosition}`);
    //         str = str.replace(patternFooter, `.Aitemplate-footer {\n  background-color: ${this.props.backgroundHeader};\n padding: 20px;\n`);
    //         console.log("This is the str: ", str);
    //         this.setState({ updateHtml: str })
    //         this.setState({html: str})
    //     }
    // }
    componentWillMount = () => {
        this.setState({ colorCode: this.props.backgroundHeader })
        if (this.props.backgroundHeader || this.props.logoPosition) {
            if (this.props.backgroundHeader === null) this.props.updateBackgroundHeader(this.props.defaultColor)
            if (this.props.logoPosition === null) this.props.updateLogoPosition("left")
            let patternHeader = new RegExp(/\.Aitemplate-header\s*{\s*background-color:\s*#[0-9a-fA-F]+;[^}]*text-align:\s*(center|left|right)/g);
            let patternFooter = new RegExp(/\.Aitemplate-footer\s*{\s*background-color:\s*#[0-9a-fA-F]+;/g);
            let str = this.state.htmlContent.replace(patternHeader, `.Aitemplate-header { \n background-color: ${this.props.backgroundHeader};\n padding: 20px;\n text-align: ${this.props.logoPosition}`);
            str = str.replace(patternFooter, `.Aitemplate-footer {\n  background-color: ${this.props.backgroundHeader};\n padding: 20px;\n`);
            console.log("This is the str: ", str);
            this.setState({ updateHtml: str })
            this.setState({html: str})
        }
        console.log("I am in the attachement")
        if (this.state.defaultSubject) {
            this.setState({ subject: this.props.subContent })
        }
        if (this.props.templateId) {
            axios.get('/api/emails/' + this.props.templateId, {})
                .then(res => {
                    this.setState({
                        loadHtml: true,
                        html: res.data.template.html,
                        subject: res.data.template.subject,
                        attachments: res.data.template.attachments,
                        tracking: res.data.template.html.search('{{.tracker}}') > -1,
                        attachmentFileName: res.data.template.htmlFileName
                    })
                })
                .catch(err => {
                    apiError(err)
                })
        } else this.setState({ loadHtml: true })
    }

    handleChangeSubject = (e) => {
        this.setState({ defaultSubject: false })
        const userSubject = e.target.value;
        this.setState({ subject: userSubject });
    }

    handleChange = (e) => {
        let html = e.editor.getData();
        let tracking = html.search('{{.tracker}}') > -1;
        this.setState({ html: html, tracking: tracking });
    }

    handleInputFileName = (e) => {
        this.setState({ attachmentFileName: e.target.value })
    }

    handleCreateTemplate = () => {
        if (this.state.html.length === 0) {
            toast.error("Please Enter HTML Content");
            return;
        } else if (this.state.subject.length === 0 || (this.state.subject).trim().length === 0) {
            toast.error("Please Enter Email Subject");
            return;
        }
        const data = {
            html: this.state.html,
            subject: this.state.subject,
            campaignTemplateId: this.props.campaignTemplateId,
            htmlFileName: this.state.attachmentFileName
        }
        this.setState({ loading: true })
        if (this.props.templateId) {
            axios.patch('/api/emails/' + this.props.templateId, data)
                .then(res => {
                    this.setState({ loading: false })
                    this.giveTemplateIdtoAttachment();
                    this.props.handleTabChange('next');
                })
                .catch(error => {
                    apiError(error)
                    this.setState({ loading: false })
                })
        } else {
            axios.post('/api/emails/', data)
                .then(res => {
                    this.setState({ loading: false })
                    this.props.handleTabChange('next');
                    this.giveTemplateIdtoAttachment();
                    this.props.handleTemplateId(res.data.template.id);
                    toast.success('Email Template Created Successfully')
                })
                .catch(error => {
                    apiError(error)
                    this.setState({ loading: false })
                })
        }
    }

    giveTemplateIdtoAttachment = () => {
        if (this.state.attachmentKey && this.state.attachmentKey.length) {
            setTimeout(() => {
                axios.post('/api/attachment/key', {
                    templateId: this.props.templateId,
                    attachmentKey: this.state.attachmentKey
                })
                    .then(res => { this.loadAttachment(); })
                    .catch(error => { })
            }, 1000);
        }
    }

    handleUploadAttachment = (e) => {
        let formData = new FormData();

        formData.append('files', e.target.files[0]);
        formData.append('templateId', this.props.templateId);

        axios.post('/api/attachment', formData, {
            headers: {
                "Content-type": "multipart/form-data",
            }
        })
            .then(r => {
                toast.success('Attachment Uploaded');
                this.loadAttachment()
            })
            .catch(error => apiError(error))
    }

    handleDeleteAttachment = (attachmentId) => {
        if(this.fileRef?.current?.value) {
            this.fileRef.current.value = "";
        }
        axios.delete('/api/attachment/' + attachmentId, {})
        .then(r => { 
            toast.success('Attachment Deleted'); 
            this.loadAttachment() 
        })
        .catch(error => apiError(error))
    }

    loadAttachment = () => {
        axios.get('/api/emails/' + this.props.templateId, {})
            .then(res => {
                this.setState({ attachments: res.data.template.attachments })
            })
            .catch(err => {
                apiError(err)
            })
    }

    addTrackingImage = (e) => {
        if (e.target.checked) {
            let html = this.state.html;
            if (html.indexOf(new RegExp('{{.tracker}}', "gi")) === -1) {
                html = html.replace('</body>', '<p>{{.tracker}}</p></body>');
            }
            this.setState({ html: html, tracking: true });
        } else {
            let html = this.state.html.replace(new RegExp('<p>{{.tracker}}</p>', "gi"), '');
            this.setState({ html: html, tracking: false });
        }
    }

    handleImportEmail = async () => {
        if (this.state.emailContent.length === 0) {
            toast.error("Please enter Original Email Content");
            return;
        }
        await this.setState({ loadingImport: true });
        axios.post('/api/import/mail', {
            raw: this.state.emailContent,
            changeUrls: this.state.changeUrls ? 1 : 0
        })
            .then(res => {
                this.setState({
                    loadingImport: false,
                    htmlKey: this.state.htmlKey + 1,
                    attachmentKey: res.data.attachmentKey,
                    html: res.data.html ? res.data.html : '',
                    updateHtml: res.data.html ? res.data.html : '',
                });
                this.handleImportEmailModal();
            })
            .catch(err => {
                apiError(err)
                this.setState({ loadingImport: false });
            })
    }

    handleImportEmailModal = () => {
        this.setState({ show: !this.state.show });
    }

    handleChangeUrl = () => {
        this.setState({ changeUrls: !this.state.changeUrls });
    }

    handleContent = (e) => {
        this.setState({
            emailContent: e.target.value
        })
    }

    render() {
        return (
            <>
                <div className="email-template-wrap">
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Create An Email Template</h6>
                                <p>Create email template</p>
                            </div>

                            <div className="col-md-6 text-right">
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Copy and paste any EML file to add it as a template</Tooltip>}>
                                    <Button variant="primary" type="button" onClick={this.handleImportEmailModal} className="mr-2"><BsBoxArrowInRight className='btn-icon' />Import From Email</Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>

                    <div className="email-template-wrap">
                        <Form className="row">
                            <Form.Group className="col-md-12">
                                <Form.Label>Email Subject</Form.Label>
                                <Form.Control type="text" value={this.state.subject} onChange={this.handleChangeSubject} placeholder="Email Subject" />
                            </Form.Group>
                        </Form>
                        <div className='setMarginCss'>
                            {this.state.loadHtml ?
                                <CKEditor
                                    editorUrl={`${process.env.REACT_APP_API_URL}/staticpra/ckeditor/ckeditor.js`}
                                    config={{
                                        fullPage: true,
                                        allowedContent: true
                                    }}
                                    key={this.state.htmlKey}
                                    initData={this.state.updateHtml ? this.state.updateHtml : this.state.html}
                                    data={this.state.updateHtml ? this.state.updateHtml : this.state.html}
                                    onChange={this.handleChange}
                                ></CKEditor> : ''
                            }
                        </div>

                        <div className='checkBoxWrap setMarginCss ml-1'>
                            <Form.Group className="mb-0" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Add Tracking Image" checked={this.state.tracking} onChange={this.addTrackingImage} />
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='top'>Track the user activity on the sent emails</Tooltip>}>
                                    <IoInformation className="tooltip-icon mt-1"></IoInformation>
                                </OverlayTrigger>
                            </Form.Group>
                        </div>

                        {this.props.templateId ?
                            <div>
                                <Form className="my-4 d-flex emailAttachment">
                                    <Form.Group>
                                        <Form.File id="exampleFormControlFile1" ref={this.fileRef} label="Email Attachments" onChange={this.handleUploadAttachment} />
                                    </Form.Group>
                                </Form>

                                <div className="table-wrap col-md-6 p-0">
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.attachments.map((attachment, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{attachment.filename}</td>
                                                        <td><DeleteOutlineTwoToneIcon style={{ cursor: "pointer" }} color='error' onClick={e => this.handleDeleteAttachment(attachment.id)}></DeleteOutlineTwoToneIcon></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div> :
                            <p style={{ 'fontSize': '12px', 'marginBottom': '2px', }}>Email Attachments part will be visible once you save this email template.</p>
                        }
                        <div className="">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{ position: "relative" }}>
                                <Form.Label>Attachment File Name</Form.Label>
                                <div className="attachmentFile">
                                    <Form.Control type="text" value={this.state.attachmentFileName} onChange={this.handleInputFileName} />
                                    <span>.html</span>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className='footer-wrapper justify-content-end d-flex'>
                        <Button className="closeBtn mr-2 " onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>

                <ImportEmail
                    show={this.state.show}
                    changeUrls={this.state.changeUrls}
                    handleContent={this.handleContent}
                    handleChangeUrl={this.handleChangeUrl}
                    emailContent={this.state.emailContent}
                    handleImportEmail={this.handleImportEmail}
                    handleImportEmailModal={this.handleImportEmailModal}
                ></ImportEmail>
                {this.state.loadingImport ? <Loader></Loader> : null}
            </>
        )
    }
}