import React, { Component } from 'react';

import icon from '../../assets/icon.png';
import user from '../../assets/user.png';
import sender from '../../assets/From.png';
import content from '../../assets/Content.png';
import subject from '../../assets/Subject.png';
import hyperlink from '../../assets/Hyperlink.png';
import FromArrow from '../../assets/From-arrow.png';
import ContentArrow from '../../assets/Content-arrow.png';
import SubjectArrow from '../../assets/Subject-arrow.png';
import HyperlinkArrow from '../../assets/Hyperlink-arrow.png';

import axios from '../../axios';
import { getDate } from '../../function';
import Loader from '../../component/loader/loader';

import './awareness.css';
export default class Actions extends Component {

	constructor(props){
		super(props);
		this.state = {
			loading: true,
			date: new Date(),
			subject: '',
			sender: '',
			path: '',
			logo: null,
			rid: this.useQuery().get('rid')
		}
		this.load();
	}

	load = () => {
		axios.get('/api/awareness', {
			params: {
				rid: this.state.rid
			}
		})
		.then(res => {
			this.setState({
				logo: res.data.logo,
				date: res.data.date,
				path: res.data.path,
				sender: res.data.sender,
				subject: res.data.subject
			})
		})
		.catch(err => {
			this.props.history.push("/awareness");
		})
	}

	useQuery = () => {
        return new URLSearchParams(window.location.search);
	}
	
	render() {
		return (
			<div id="main-body">
				{this.state.loading?
					<div>
						{this.state.logo? <img src={this.state.logo} className="logo-awareness" alt=""></img>: null}
						<h5 className="heading-type">You have been phished</h5>
						<p className="head-para">This was a Phishing Campaign run by your organization</p>
						<p className="mid-para">Check below - How To Identify Phishing Email ?</p>
						<div className="row mt-5">
							<div className="col-sm-3 left-side ">
								<div className="block">
									<img src={FromArrow} className="from-arrow" alt=""></img>
									<div className="content-block from-block">
										<h4><img src={sender} className="mail-icon" alt=""></img>From</h4>
										<p>Make sure the domain name is legitimate and the spelling is correct. The sender’s ID in a phishing emailusually imitates a legitimate sender with slight differences, which can be very easy to miss if you are not on the lookout.</p>
									</div>
									<img src={ContentArrow} className="content-arrow" alt=""></img>
									<div className="content-block contentBlock">
										<h4><img src={content} className="mail-icon" alt=""></img>Content</h4>
										<p>It is common for phishing emails to contain spelling and grammatical mistakes. Phishing emails also try to create a sense of urgency in the users to get them to make careless mistakes. </p>
									</div>
								</div>
							</div>
							<div className="col-sm-6 middle-side px-4">
								<div className="image-header">
									<h3 className="head">{this.state.subject}</h3>
									<div className="row mx-0">
										<div className="col-sm-1 fav-logo">
											<img src={user} className="img-fluid" alt=""></img>
										</div>
										<div className="col-sm-8 pl-0 d-flex align-items-center">
											<div className="block">
												<h4 className="mail-head">{this.state.sender}</h4>
												<p className="date w-100 ">{getDate(this.state.date)}</p>
												<p className="date">to me</p>
											</div>
										</div>
										<div className="col-sm-3 text-right">
											<img src={icon} className="img-fluid" alt=""></img>
										</div>
									</div>
									<div className="main-temp">
										<img src={this.state.path} className="w-100" alt=""></img>
									</div>
								</div>
							</div>
							<div className="col-sm-3 right-side">
								<div className="block">
									<img src={SubjectArrow} className="subject-arrow" alt=""></img>
									<div className="content-block">
										<h4><img src={subject} className="mail-icon" alt=""></img>Subject</h4>
										<p>An email’s subject line is usually the first clue to its fraudulence or legitimacy. It may not match the content in the email body. Also, the subject line of a phishing email generally creates a sense of urgency for tricking the users into opening it without thinking. </p>
									</div>
									<img src={HyperlinkArrow} className="hyperlink-arrow" alt=""></img>
									<div className="content-block hyperlink-block">
										<h4><img src={hyperlink} className="mail-icon" alt=""></img>Hyperlinks</h4>
										<p>Avoid clicking on any embedded links without making sure they are safe. Some links may even prompt you to download a malicious attachment. You can hover on the links to check their destination before opening them.</p>
									</div>
								</div>
							</div>
						</div>
					</div>: <Loader></Loader> 
				}
			</div>
		);
	}
}