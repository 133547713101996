import React, { Component } from "react";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import AddSharpIcon from '@material-ui/icons/AddSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { FormControl, Button, InputGroup, Table, Form, Dropdown } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import './iamroles.css';

export default class IamUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: [],
            search: ''
        }
        this.loadRoles();
    }

    nextPath = path => this.props.history.push(path);

    loadRoles = () => {
        axios.get('/api/roles', {
            params: {
                search: this.state.search
            }
        })
            .then(res => {
                this.setState({ roles: res.data.roles });
            })
            .catch(err => apiError(err));
    }

    deleteRoles = (roleId) => {
        swal({
            title: "Do you want to delete Role?",
            icon: "warning",
            buttons: ['No', 'Yes'],
        })
            .then(willSuccess => {
                if (willSuccess) {
                    axios.delete('/api/roles/' + roleId, {})
                        .then(res => {
                            this.loadRoles();
                            this.setState({ search: "" });
                            toast.success("Role Deleted Successfully");
                        })
                        .catch(err => apiError(err));
                }
            });
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Roles</h4>
                            </div>
                        </div>

                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">
                                <Button className="btn-primary" onClick={e => this.nextPath('/iam/roles/0')}><AddSharpIcon className="btn-icon"></AddSharpIcon>Create Roles</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="alignItemsCenter row">
                        <div className="col-md-3 p-0">
                            <div className="positionSearchIcon">
                                <InputGroup className="search-wrap">
                                    <FormControl type="text" placeholder="Search" value={this.state.search} onChange={e => this.setState({ search: e.target.value }, () => this.loadRoles())} className="inputSearch mr-sm-2 form-control" /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                                </InputGroup>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="rightFilters">

                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block">
                                    <Form.Control as="select" className="mr-2 btn">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentbar">
                    <div className="tableContainerWrapInner">
                        <Table>
                            <thead className="bg-light">
                                <tr>
                                    <th>ID</th>
                                    <th>Role Name</th>
                                    <th>Type</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.roles.map((role, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{role.name}</td>
                                        <td>{role.type}</td>
                                        <td>{role.description}</td>
                                        <td>
                                            <Dropdown
                                                className="action-dropdown dropdownIcon" ref={this.selectRef}>
                                                <Dropdown.Toggle className="btn-only" id="dropdown-basic">
                                                    <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={e => this.nextPath('/iam/roles/' + role.id)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={e => this.deleteRoles(role.id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}