

import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form, Table } from 'react-bootstrap';
import { PublicClientApplication } from "@azure/msal-browser";
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class Azure extends Component {
    constructor(props) {
        super(props);
        this.pca = null;
        this.state = {
            clientId: "",
            groupId: '',
            disabled: true,
            groupEnabled: false,
            Groups: [],
            loading: false,
            checkedGroups: {},
        };
    }

    componentDidMount = () => {
        this.loadAzure();
    }

    validate = () => {
        if (!this.state.clientId) {
            toast.error('Please Enter clientId');
            return null;
        }
        //if none of the group is selected then it should show the error toast to select atLeast one group
        return true;
    }

    loadAzure = () => {
        this.setState({ loading: true });
        axios.get('/api/company/getAzureSettings')
            .then(res => {
                if (res.data?.result?.clientId) {
                    this.setState({ clientId: res.data.result.clientId, groupEnabled: res.data.result.azureGroupEnabled });
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false });
            });
    }

    handleLogin = async () => {
        let clientId = this.state.clientId.trim()
        if(clientId.length === 0){
            toast.error('Please Enter ClientId');
            return;
        }
        const msalConfig = {
            auth: {
                clientId: this.state.clientId,
                authority: 'https://login.microsoftonline.com/common',
                redirectUri: window.location.origin
            }
        };

        const loginRequest = {
            scopes: ["User.Read", "Group.Read.All"]
        };

        if (!this.pca) {
            this.pca = new PublicClientApplication(msalConfig);
        }

        try {
            const loginResponse = await this.pca.loginPopup(loginRequest);
            if (loginResponse) {
                const account = this.pca.getAccountByHomeId(loginResponse.account.homeAccountId);
                const msalResponse = await this.pca.acquireTokenSilent({
                    ...loginRequest,
                    account
                });

                if (msalResponse) {
                    this.fetchGroups(msalResponse.accessToken);
                }
            }
        } catch (err) {
            apiError(err);
            this.setState({ loading: false });
        }
    }

    loadGroups = (groupArray) => {
        this.setState({ Groups: groupArray });
        axios.get('/api/company/getAzureSettings')
        .then(res => {
            if (res.data?.result?.clientId) {
                this.setState({ 
                    clientId: res.data.result.clientId, 
                    groupEnabled: res.data.result.azureGroupEnabled 
                });
            }
            let oldCheckedGroups = {};
            if (res.data?.result?.groups) {
                res.data.result.groups.forEach(group => {
                    oldCheckedGroups[group.azureGroupId] = true;
                });
            }
            this.setState({ loading: false });
            this.setState({ checkedGroups: oldCheckedGroups });
        })
        .catch(err => {
            apiError(err);
            this.setState({ loading: false });
        });
    }
    fetchGroups = (accessToken, link = 'https://graph.microsoft.com/v1.0/groups?$top=999', data = []) => {
        axios.get(link, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        })
            .then(res => {
                if (res.data && Array.isArray(res.data.value)) {
                    let groupArray = (res.data.value).map(group => {
                        return {
                            id: group.id,
                            groupName: group.displayName
                        }
                    })
                    data = data.concat(groupArray);
                    if(res.data["@odata.nextLink"]){
                        this.fetchGroups(accessToken, res.data["@odata.nextLink"], data)
                    } else {
                        this.loadGroups(data);
                    }
                } else {
                    this.loadGroups(data);
                }
            })
            .catch(err => {
                if(err.message) toast.error('Unable To Fetch Group From Azure');
                this.setState({ loading: false });
            });
    }

    handleAzure = () => {
        let selectedGroups = this.state.Groups.filter(group => this.state.checkedGroups[group.id]);
        axios.post('/api/company/setAzureSettings', {
            azureClientId: this.state.clientId,
            azureGroupEnabled: this.state.groupEnabled,
            groups: selectedGroups
        }).then(res => {
            toast.success(res.data.message);
        })
            .catch(err => {
                apiError(err);
            });
    }

    handleGroupCheckBox = (e, groupId, name) => {
        let newCheckedGroups = this.state.checkedGroups;
        newCheckedGroups[groupId] = e.target.checked;
        this.setState({ checkedGroups: newCheckedGroups });
    };

    enableOrDisbleAzureSetting = (status) => {
        axios.post('/api/company/enableOrDisbleAzureSetting', {
            status
        })
        .then(() => {
            this.setState({ groupEnabled: status })
        })
        .catch(err => {
            apiError(err);
        });
    }
    
    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Azure Settings</h6>
                    <p>Settings and integrations with Microsoft Azure services for cloud computing and storage.</p>
                </div>
                <div className="col-md-4 pl-0">
                    <Form>
                        <Form.Group className="mt-2 mb-0">
                            <Form.Check
                                type="switch"
                                id="custom-group-switch"
                                label="Enable Group ID"
                                checked={this.state.groupEnabled}
                                onChange={e => this.enableOrDisbleAzureSetting(e.target.checked)}
                            />
                        </Form.Group>
                        {this.state.groupEnabled?
                            <>
                                <Form.Group>
                                    <Form.Label>Client ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Client Id"
                                        value={this.state.clientId}
                                        onChange={e => this.setState({ clientId: e.target.value, disabled: false })}
                                    />
                                </Form.Group>
                                <Button variant="success" type="button" className="mb-2" disabled={!this.state.clientId.length} onClick={() => this.handleLogin()}>Fetch Groups</Button>
                                {this.state.Groups?.length > 0 && <>
                                    <Form.Label>Groups</Form.Label>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Checkbox</th>
                                                <th>Groups</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Groups?.map((group) => (
                                                <tr key={group.id}>
                                                    <td>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={(this.state.checkedGroups.hasOwnProperty(group.id) && this.state.checkedGroups[group.id])?true:false}
                                                            onChange={(e) => this.handleGroupCheckBox(e, group.id, group.groupName)}
                                                        />
                                                    </td>
                                                    <td>{group.groupName}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button variant="success" type="button" onClick={this.handleAzure}>Update</Button></>
                                }
                            </>: null
                        }
                    </Form>
                </div>
                {this.state.loading ? <Loader /> : null}
            </div>
        );
    }
}
