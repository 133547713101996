import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { PiFileText } from "react-icons/pi";
import { PiMagnifyingGlass } from "react-icons/pi";
import { FormControl, InputGroup, Card, Form, Button, Accordion } from 'react-bootstrap';

import axiosRequest from 'axios';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import CampaignTable from '../../../component/campaigns/campaign/campaign';

import './campaign.css';

var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;
export default class CampaignList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: '',
            count: 0,
            limit: 10,
            Expired: 7,
            search: '',
            activeId: null,
            campaigns: [],
            loading: true,
            activePage: 1,
            attackTypeId: '',
            attackTypes: [],
        }
    }

    checkValidToken = () => {
        axios.post('/api/auth/check-token', {})
            .then(res => {
                this.load();
                this.loadAttackType();
            })
            .catch(err => apiError(err))
    }

    componentDidMount = () => {
        this.checkValidToken();
    }

    handleActiveCampaign = (campaignId) => {
        if (this.state.activeId === campaignId) {
            this.setState({ activeId: '' })
        } else {
            this.setState({ activeId: campaignId })
        }
    }

    nextPath = path => this.props.history.push(path);

    load = async () => {
        // await this.setState({ loading: true })
        if (cancelRequest) cancelRequest();
        axios.get('/api/campaigns', {
            params: {
                limit: this.state.limit,
                search: this.state.search,
                page: this.state.activePage,
                attackTypeId: this.state.attackTypeId
            },
            cancelToken: new CancelToken(function executor(c) { cancelRequest = c })
        })
            .then(async (res) => {
                await this.setState({
                    activeId: '',
                    loading: false,
                    count: res.data.count,
                    campaigns: res.data.campaigns,
                    Expired: parseInt(res.data.Expired, 10),
                })
            })
            .catch(error => {
                if (!axiosRequest.isCancel(error)) {
                    this.setState({ campaigns: [], count: 0, loading: false })
                    apiError(error);
                }
            })
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
            .then(res => {
                this.setState({ attackTypes: res.data.attackTypes });
            })
            .catch(err => apiError(err))
    }

    render() {
        return (
            <div className="content-wrap">
                {/********************** Breadcrumbbar **********************/}
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Campaigns</h4>
                            </div>
                        </div>

                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">
                                <Button variant="secondary" onClick={e => this.props.history.push('/campaign/test-results')} ><PiFileText className="btn-icon"></PiFileText>Test Results</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/******************* Breadcrumbbar End *******************/}

                {/********************** Filter **********************/}
                <div className="filter-wrapper">
                    <div className="alignItemsCenter row">
                        <div className="col-md-3 p-0">
                            <div className="positionSearchIcon">
                                <InputGroup>
                                    <FormControl type="text" placeholder="Search" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value, activePage: 1 }, () => this.load())} className="inputSearch mr-sm-2 form-control" /><PiMagnifyingGlass className="search-icon" />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="col-sm-9 p-0">
                            <div className="rightFilters">
                                <Form.Group controlId="exampleForm.ControlSelect2">
                                    <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({ attackTypeId: e.target.value }, () => this.load())}>
                                        <option value=''>Attack Vector</option>
                                        {this.state.attackTypes.map((attack, index) => {
                                            return <option key={'attack-' + index} value={attack.id}>{attack.name}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block mr-2">
                                    <Form.Control as="select">
                                        <option value="10"style={{width:"200px"}} >10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>

                {/********************** End **********************/}

                <div className="contentbar campListGrids">
                    <div className="page-content">
                        <div className="row TableHead mx-0">
                            <div className="col-md-1 expandGrid">#</div>
                            <div className="col-md-3">Campaign</div>
                            <div className="col-md-2">Initiated at</div>
                            <div className="col-md-1">Users</div>
                            <div className="col-md-2">Type of Campaign</div>
                            <div className="col-md-2">Attack Vector</div>
                            <div className="col-md-1">Frequency</div>
                        </div>
                        <Accordion className="camp-list-accordion">
                            <Card>
                                {this.state.campaigns && this.state.campaigns.length ?
                                    this.state.campaigns.map((campaign, index) => {
                                        return <CampaignTable
                                            index={index + 1}
                                            campaign={campaign}
                                            nextPath={this.nextPath}
                                            key={'campaignlist-' + index}
                                            Expired={this.state.Expired}
                                            activeRow={campaign.id === this.state.activeId}
                                            handleActiveCampaign={this.handleActiveCampaign}
                                        ></CampaignTable>
                                    }) :
                                    <div className="mt-3 text-center">
                                        <h6>No campaigns</h6>
                                    </div>
                                }
                            </Card>
                        </Accordion>
                    </div>

                    <div className="pagination-Wrap">
                        {this.state.campaigns && this.state.campaigns.length ?
                            <Pagination
                                itemClass="page-item"
                                linkClass="page-link"
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.count}
                                onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.load())}
                            /> : null
                        }
                        {this.state['loading'] ? <Loader /> : null}
                    </div>
                </div>
            </div>
        );
    }
}