import { io } from 'socket.io-client';
const URL = process.env.REACT_APP_API_URL;

export const socket = io(URL, {
  	autoConnect: true,
	withCredentials: true,
  	transports: ['websocket']
});

export const SOCKET_EVENTS = {
    AI_TEMPLATE_DATA: "api:template:ai:response",
}
