import React, { Component } from "react";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import AddSharpIcon from '@material-ui/icons/AddSharp';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";
import { FormControl, Button, InputGroup, Table, Form, Dropdown } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import AddUserModal from '../../../component/iam/add-user/add-user';
import './iamuser.css';

export default class IamUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            show: false,
            search: '',
        }
        this.load();
    }

    handleModal = () => {
        this.setState({ show: !this.state.show })
    }

    nextPath = path => this.props.history.push(path);

    load = () => {
        axios.get('/api/iam-user/', {
            params: {
                search: this.state.search
            }
        })
            .then(res => {
                this.setState({ employees: res.data.employees })
            })
            .catch(err => apiError(err))
    }

    deleteUser = (userId) => {
        swal({
            title: "Do You Want To Delete User?",
            icon: "warning",
            buttons: ['No', 'Yes'],
        })
            .then(willSuccess => {
                if (willSuccess) {
                    axios.delete('/api/iam-user/delete/' + userId, {})
                        .then(res => {
                            toast.success("User Deleted Successfully");
                            this.load();
                        })
                        .catch(err => apiError(err))
                }
            });
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Users</h4>
                            </div>
                        </div>
                        <div className="col-md-8 flexFilterButtons">
                            <div className="btn-container float-right d-flex align-items-center">
                                <Button className="btn-primary" onClick={this.handleModal}><AddSharpIcon className="btn-icon"></AddSharpIcon> Add User</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="alignItemsCenter row">
                        <div className="col-md-3 p-0">
                            <div className="positionSearchIcon">
                                <InputGroup className="search-wrap">
                                    <FormControl type="text" placeholder="Search" value={this.state.search} onChange={e => this.setState({ search: e.target.value }, () => this.load())} className="inputSearch mr-sm-2 form-control" /><SearchSharpIcon className="search-icon"></SearchSharpIcon>
                                </InputGroup>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="rightFilters">

                                <Form.Group controlId="exampleForm.ControlSelect1" onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())} className="d-inline-block">
                                    <Form.Control as="select" className="mr-2 btn">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentbar">
                    {this.state.employees.length > 0 ?
                        <div className="tableContainerWrapInner">
                            <Table responsive className="mb-0">
                                <thead className="bg-light">
                                    <tr>
                                        <th>Id</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.employees.map((employee, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{employee.firstname}</td>
                                            <td>{employee.lastname ? employee.lastname : '---'}</td>
                                            <td>{employee.email}</td>
                                            <td>
                                            <Dropdown
                                                className="action-dropdown dropdownIcon" ref={this.selectRef}>
                                                <Dropdown.Toggle className="btn-only" id="dropdown-basic">
                                                    <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={e => this.nextPath('/iam/users/' + employee.id)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={e => this.deleteUser(employee.id)} >Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                             </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table></div> : <h6>No user found</h6>
                    }

                    <AddUserModal
                        load={this.load}
                        show={this.state.show}
                        handleModal={this.handleModal}
                    ></AddUserModal>
                </div>
            </div>
        );
    }
}