import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { PiArrowsOutThin,} from "react-icons/pi";

import axios from '../../../axios';
import apiError from '../../../error';
import './import-email-from-store.css';

export default class ImportEmailFromStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            templates: [],
            hasMore: true,
            disabled: true,
            selectedTemplateId: null,
        };
    }

    loadEmailTemplates = () => {
        const { page } = this.state;
        axios.post(`/api/store/list-emails`, { page })
            .then(response => response.data)
            .then(responseData => {
                if (responseData && responseData.response) {
                    this.setState(prevState => ({
                        templates: [...prevState.templates, ...responseData.response],
                        hasMore: responseData.response.length >= 10,
                        page: prevState.page + 1
                    }));
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch(apiError);
    }

    componentDidMount() {
        this.loadEmailTemplates();
    }

    loadHtml = (templateId) => {
        axios.get(`/api/store/fetch-email/${templateId}`)
            .then(response => response.data)
            .then(responseData => {
                if (responseData && responseData.response) {
                    const { html, subject, emailTracking, attachments } = responseData.response;
                    const attachmentNames = attachments.map(attachment => attachment.filename);
                    this.props.handleLoadSelectedStoreTemplateHtml(html, subject, emailTracking, attachmentNames, this.state.selectedTemplateId);
                    this.props.handleClose();
                } else {
                    toast.error('Template HTML not found');
                }
            })
            .catch(apiError);
    }

    handleCardClick = () => {
        this.loadHtml(this.state.selectedTemplateId);
    }

    render() {

        const { templates, hasMore, selectedTemplateId } = this.state;
        const { showImportModal, handleClose } = this.props;

        return (
            <Modal className="info-filter mainfooterModal emailRight right" style={{ overflowY: 'auto' }} show={showImportModal} onHide={handleClose} autoFocus keyboard>
                <Modal.Header closeButton>
                    <div>
                        <h5>Email Template</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadEmailTemplates}
                        hasMore={hasMore}
                        loader={<div className="loader" key={0}>Loading.....</div>}
                        useWindow={false}
                    >
                        <Row className='emailCard'>
                            {templates.map((template, index) => (
                                <div key={index} className='col-md-4 p-0'>
                                       <div className="img-hover-wrap templateView" 
                                            // onClick={e => {
                                            //     this.state.templateImg?
                                            //     this.setState({
                                            //         showThumbnail: true,
                                            //         currentSrc: this.state.templateImg
                                            //     }):
                                            //     toast.info('Email Template Image Not Available')
                                            // }}
                                      >
                                        <div className={`cardWrap ${this.state.selectedTemplateId === template.id? 'selectActive': ''}`}
                                            style={{ 
                                                position: 'relative', 
                                                cursor: 'pointer',
                                                boxShadow: selectedTemplateId === template.id ? '0 5px 10px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                                transform: selectedTemplateId === template.id ? 'scale(0.98)' : 'scale(1)',
                                                transition: 'transform 0.1s, box-shadow 0.1s'
                                            }} 
                                            onClick={() => this.setState({selectedTemplateId: template.id })}
                                        >
                                            <img crossOrigin="anonymous" alt="no image" src={template.thumbnail} />
                                            <div className='hover-btn'>
                                                <PiArrowsOutThin className="btn-icon" />
                                            </div>
                                            <div className='cardContent'>
                                                <h6>{template.name}</h6>
                                                <div className='cardfooterBox'>
                                                    <span className='badge badgeTwo'>Finance & Acc</span>
                                                    <span className='badge badgeOne'>Finance</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Row>
                    </InfiniteScroll>
                </Modal.Body>
                <div className="modalFooter" style={{paddingRight:"115px"}}>
                    <div className="row ">
                        <div className="col-md-12 text-right">
                            <Button onClick={handleClose} type="button" className="btn closeBtn">Cancel</Button>
                            <Button disabled={this.state.selectedTemplateId? false: true} className='btn btn-success ml-2' onClick={() => this.handleCardClick()}>Select Template</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
