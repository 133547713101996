import React, { Component } from 'react';
import swal from 'sweetalert';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { GoChecklist } from "react-icons/go";
import { PiArrowsOutThin } from "react-icons/pi";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import { Button, Form, Table, Image, Tabs, Tab } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import PreviewThumbnail from '../../preview/preview';
import Loader from '../../../component/loader/loader';
import TestEmails from '../../../component/test-campaign-email/test-campaign-email';

export default class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            page: null,
            game: null,
            user: null,
            emails: '',
            categoryId: '',
            loading: false,
            template: null,
            category: null,
            language: null,
            subject: null,
            training: null,
            attackType: null,
            showModal: false,
            landing_url: null,
            campaignName: null,
            from_address: null,
            simulationType: null,
            showTestModal: false,
            refreshLoading: false,
            credential_harvesting: false,

            templateName: null,
            brodcastName: null,

            imageURL: '',
            courseName: '',
            previewURL: '',
            lmsCourses: [],
            courseIds: [],
            myWindow: null,
            templateImg: '',
        }
    }

    componentDidMount = () => {
        this.handleRefresh();
    }

    handleRefresh = () => {
        if (this.props.campaignTemplateId) {
            this.setState({ refreshLoading: true })
            axios.get('/api/campaign/template/preview/' + this.props.campaignTemplateId, {})
                .then(res => {
                    var campaignTemplate = res.data.campaign_template;
                    this.setState({
                        loading: false,
                        user: res.data.user,
                        refreshLoading: false,
                        pageImg: res.data.pagePath,
                        game: campaignTemplate.game,
                        type: campaignTemplate.type,
                        templateImg: res.data.templatePath,
                        campaignName: campaignTemplate.name,
                        training: campaignTemplate.courseId,
                        awarenessPath: res.data.awarenessPath,
                        credential_harvesting: campaignTemplate.credential_harvesting,
                        from_address: campaignTemplate.sender ? campaignTemplate.sender.from_address : null,

                        subject: campaignTemplate.template ? campaignTemplate.template.subject : null,
                        emailTracking: campaignTemplate.template ? campaignTemplate.template.emailTracking : null,

                        templateName: campaignTemplate.template ? campaignTemplate.template.templateName : null,
                        brodcastName: campaignTemplate.template ? campaignTemplate.template.brodcastName : null,

                        category: campaignTemplate.category ? campaignTemplate.category.name : null,
                        categoryId: campaignTemplate.category ? campaignTemplate.category.id : null,
                        language: campaignTemplate.language ? campaignTemplate.language.name : null,
                        attackType: campaignTemplate.attack_type ? campaignTemplate.attack_type.name : null,
                        simulationType: campaignTemplate.simulation_type ? campaignTemplate.simulation_type.name : {},

                        bluePage: campaignTemplate.page ? campaignTemplate.page.bluePage : null,
                        landing_url: campaignTemplate.page ? campaignTemplate.page.landing_url : null,
                        capture_passwords: campaignTemplate.page ? campaignTemplate.page.capture_passwords : null,
                        capture_credentials: campaignTemplate.page ? campaignTemplate.page.capture_credentials : null,
                        redirect_url: campaignTemplate && campaignTemplate.credential_harvesting && campaignTemplate.page ? campaignTemplate.page.redirect_url : res.data.awarenessUrl,

                        firstname: '',
                        lastname: '',
                    })
                    if (campaignTemplate.courseId) {
                        var courseIds = [];
                        for (let i = 0; i < campaignTemplate.courses.length; i++) {
                            courseIds.push(campaignTemplate.courses[i].courseId)
                        }
                        this.setState({ courseIds: courseIds }, () => {
                            this.loadCourseLMS();
                        })
                    }
                })
                .catch(error => {
                    this.setState({ refreshLoading: false })
                    apiError(error)
                })
        }
    }

    shareCampaignTempalte = () => {
        swal({
            title: "Do you want to share this template?",
            icon: "info",
            buttons: ['Not Now!', 'Yes'],
        })
            .then(willSuccess => {
                if (willSuccess) {
                    if (this.props.campaignTemplateId) {
                        axios.post('/api/campaign/template/share/' + this.props.campaignTemplateId, {})
                            .then(res => {
                                toast.success("Campaign Tempate Share Successfully");
                            })
                            .catch(err => {
                                apiError();
                            })
                    }
                }
            })
    }

    handleFinish = () => {
        swal({
            timer: 1000,
            buttons: false,
            icon: "success",
            text: '---',
            title: "Campaign Created Successfully",
        })
        setTimeout(() => { this.props.nextPath('/templates') }, 500)
    }

    showHandleThumbnail = (type) => {
        let previewSrc = '';
        if (type === 'template') {
            previewSrc = this.state['templateImg'];
        } else if (type === 'page') {
            previewSrc = this.state['pageImg'];
        } else if (type === 'awareness') {
            previewSrc = this.state['awarenessPath'];
        }
        this.setState({ showModal: !this.state.showModal, previewSrc: previewSrc, previewType: type });
    }

    launchCampaignTemplate = () => {
        let url = '/templates/launch/' + this.state.simulationType + '/' + this.state.attackType + '/' + this.state.credential_harvesting;
        let search = '?type=' + this.state.type + '&category=' + this.state.categoryId + '&ctID=' + this.props.campaignTemplateId;
        this.props.nextPath(url + search);
    }

    showHandleTestEmail = () => {
        this.setState({ showTestModal: !this.state.showTestModal });
    }

    testCampaign = (targetDetails) => {
        if (this.props.campaignTemplateId && this.props.campaignTemplateId !== 0 && this.props.campaignTemplateId !== '0') {
            this.setState({ loading: true })
            axios.post('/api/simulation/test', {
                targetDetails,
                campaignTemplateId: this.props.campaignTemplateId,
            })
                .then(res => {
                    this.showHandleTestEmail();
                    this.setState({ loading: false });
                    toast.success("Test campaign run successfully");
                    setTimeout(() => { window.open('/campaign/test-results/?attackTypeId=' + this.state.attackType) }, 1500);
                })
                .catch(error => {
                    this.setState({ loading: false })
                    apiError(error);
                })
        }
        else toast.error("Invalid Campaign Template");
    }
    loadCourseLMS = async () => {
        if (this.state.courseIds && this.state.courseIds.length) {
            await this.setState({ loading: true });
            axios.get('/api/lms/course-detail', {
                params: {
                    courseIds: this.state.courseIds
                }
            })
                .then(res => {
                    var courseName = {};
                    for (let i = 0; i < res.data.courses.length; i++) {
                        courseName[res.data.courses[i].id] = res.data.courses[i].name
                    }
                    this.setState({
                        loading: false,
                        courseName: courseName,
                        imageURL: res.data.imageURL,
                        lmsCourses: res.data.courses,
                        previewURL: res.data.previewURL,
                    });
                })
                .catch(err => {
                    this.setState({ loading: false });
                    apiError(err)
                })
        }
    }

    handleLMS = async (courseId) => {
        document.body.style.cursor = "wait";
        axios.get('/api/lms', {
            params: {
                url: `${this.state.previewURL}${courseId}?r=Sw4s56g`
            }
        })
            .then(res => {
                document.body.style.cursor = "";
                window.open(res.data.url, '_blank');
            })
            .catch(err => {
                apiError(err);
                document.body.style.cursor = "";
            });
    }

    render() {
        return (
            <>
                <div className="tabContent-block email-template-wrap previewSection" id="preview">

                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-md-3 tab-right-header">
                                <h6>Preview</h6>
                                <p>Preview Template</p>
                            </div>
                            <div className="col-md-9 text-right">
                                <div className="btn-container justify-content-end d-flex align-items-center">
                                    {this.state.attackType === "Smishing" || this.state.attackType === "WhatsApp" || this.state.attackType === "Vishing" ?
                                        <div className="col-md-9 text-right pr-0">
                                            <Button className="commonrefreshBtn mr-2" onClick={this.handleRefresh}><CachedSharpIcon className="btn-icon" /></Button>
                                            <Button variant="primary" className="text-right mr-2" onClick={this.launchCampaignTemplate}><AddSharpIcon className="btn-icon"></AddSharpIcon> Create Campaign </Button>
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                                                <Button variant="primary" type="button" onClick={this.showHandleTestEmail} ><GoChecklist className="btn-icon"></GoChecklist>Test Campaign Template Message</Button>
                                            </OverlayTrigger>
                                        </div> :
                                        <div className="col-md-9 text-right pr-0">
                                            <div className="btn-container justify-content-end d-flex align-items-center">
                                                <Button className="commonrefreshBtn mr-2" onClick={this.handleRefresh}><CachedSharpIcon className="btn-icon" /></Button>
                                                <Button variant="primary" className="text-right mr-2" onClick={this.launchCampaignTemplate}><AddSharpIcon className="btn-icon"></AddSharpIcon> Create Campaign </Button>
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='top'>Overall view of campaign template</Tooltip>}>
                                                    <Button variant="primary" type="button" onClick={this.showHandleTestEmail} ><GoChecklist className="btn-icon"></GoChecklist> Test Campaign Template Email</Button>
                                                </OverlayTrigger>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='templateDetailsWrapper'>
                        <div className='row'>
                            <div className="col-md-6 pl-0">
                                <div className='templateList'>
                                    <Table>
                                        <tbody>
                                            {this.state.campaignName ?
                                                <tr>
                                                    <td className='keyHeading'>Template Name:</td>
                                                    <td className='valueHeading'>{this.state.campaignName}</td>
                                                </tr> : ""
                                            }

                                            {this.state.category ?
                                                <tr>
                                                    <td className='keyHeading'>Template Category: </td>
                                                    <td className='valueHeading'><span className="badgeYn p-badge">{this.state.category}</span></td>
                                                </tr> : ""
                                            }

                                            {this.state.from_address && this.state.attackType !== 'WhatsApp' ?
                                                <tr>
                                                    <td className='keyHeading'>From :</td>
                                                    <td className='valueHeading'>{this.state.from_address}</td>
                                                </tr> : ""
                                            }

                                        </tbody>
                                    </Table>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='templateList'>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className='keyHeading'>Language :</td>
                                                <td className='valueHeading'>{this.state.language}</td>
                                            </tr>

                                            <tr>
                                                <td className='keyHeading'>Type of Simulation :</td>
                                                <td className='valueHeading'>{this.state.simulationType}</td>
                                            </tr>

                                            <tr>
                                                <td className='keyHeading'>Attack Vector :</td>
                                                <td className='valueHeading'>{this.state.attackType}</td>
                                            </tr>
                                            <tr>
                                                {(this.state.attackType !== 'Vishing' && this.state.attackType !== 'Training + Quiz') ?
                                                    <>
                                                        <td className='keyHeading'>Credential Harvesting : </td>
                                                        <td className='valueHeading'>{this.state.credential_harvesting ? <p className="badgeYn yes-badge">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                    </> : ''
                                                }
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.state.attackType !== 'Vishing' && this.state.attackType !== 'Training + Quiz') ?
                        <div className='tabTamplate' id="tab-wrapper">
                            <Tabs activeKey={this.state.tab} onSelect={(tab) => this.setState({ tab: tab })}>
                                <Tab eventKey="simulation" title="simulation">
                                    <div className="row">
                                        <div className="col-md-4 pl-0">
                                            <div className='contHeading'>
                                                <h6>{this.state.attackType === "WhatsApp" ? <Form.Label>Message Template  </Form.Label> : <Form.Label>Email Template  </Form.Label>}</h6>
                                            </div>
                                            {this.state.attackType === 'WhatsApp' ?
                                                <>
                                                    <div className="bg-light p-3 mb-3">
                                                        <h6>Template Name</h6>
                                                        <p className="mb-0">{this.state.templateName}</p>
                                                    </div>
                                                    <div className="bg-light p-3 mb-3">
                                                        <h6>Brodcast Name</h6>
                                                        <p className="mb-0">{this.state.brodcastName}</p>
                                                    </div>
                                                </> :

                                                <div className="img-hover-wrap templateView">
                                                    <div className='cardImg'>
                                                        <img src={this.state.templateImg} alt="template" className="img-fluid img"></img>
                                                        <div className='hover-btn'>
                                                            <PiArrowsOutThin className="btn-icon" onClick={e => this.showHandleThumbnail('template')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {this.state.credential_harvesting ?
                                            <div className="col-md-4">
                                                <div className='contHeading'>
                                                    <h6>Landing Page</h6>
                                                </div>
                                                <div className="img-hover-wrap templateView">
                                                    <div className='cardImg'>
                                                        <img src={this.state.pageImg} alt="template" className="img-fluid img"></img>
                                                        <div className='hover-btn'>
                                                            <PiArrowsOutThin onClick={e => this.showHandleThumbnail('page')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                        <div className="col-md-4">
                                            <div className='contHeading'>
                                                <h6>Awareness Page</h6>
                                            </div>

                                            <div className="img-hover-wrap templateView">
                                                <div className='cardImg'>
                                                    <img src={this.state.awarenessPath} alt="template" className="img-fluid img"></img>
                                                    <div className='hover-btn'>
                                                        <PiArrowsOutThin onClick={e => {
                                                            this.showHandleThumbnail('awareness')
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='url-wrapper'>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <div className='contHeading'>
                                                    <h6>Subject </h6>
                                                    <p className="mb-0">{this.state.subject}</p>
                                                </div>

                                                <div className='contHeading'>
                                                    <h6>Attacker Profile </h6>
                                                    <p className="mb-0">Sender ID : {this.state.from_address}</p>
                                                </div>
                                            </div>
                                            {this.state.credential_harvesting ?
                                                <div className="col-md-4">
                                                    <div className='contHeading'>
                                                        <h6>URL</h6>
                                                        <p>{!this.state.landing_url ? "No URL Found" : this.state.landing_url}</p>
                                                    </div>

                                                    <Table>
                                                        <tbody>
                                                            <tr>
                                                                <td className='keyHeading'>Capture Credential</td>
                                                                <td className='valueHeading'> {this.state.capture_credentials ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='keyHeading'>Capture Password </td>
                                                                <td className='valueHeading'>{this.state.capture_passwords ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='keyHeading'>Auto-Instant Awareness </td>
                                                                <td className='valueHeading'> {this.state.bluePage ? <p className="badgeYn yes-badges">Yes</p> : <p className="badgeYn no-badges">No</p>}</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </div> : null
                                            }
                                            <div className='col-md-4'>
                                                <div className='contHeading'>
                                                    <h6>URL </h6>
                                                    <p className="mb-0">{this.state.redirect_url}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="training" title="training">
                                    <div className='contHeading'>
                                        <h6>Training </h6>
                                    </div>
                                    <div className="tableContainerWrapInner">
                                        <Table className="training-table" borderless>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Course</th>
                                                    <th>Date</th>
                                                    <th>LECTURES</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.lmsCourses.map((course, index) => {
                                                    return <tr key={index} className="setcheckBox">
                                                        <td>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="default-1"
                                                                className="checkBox"
                                                            />
                                                        </td>
                                                        <td>
                                                            <div className="onlyForPinter">
                                                                <div className="flexTableData">
                                                                    <div className="companyLogoIcon">
                                                                        <Image src={this.state.imageURL + course.logo} alt="no image" className="training-img"></Image>
                                                                    </div>

                                                                    <div>
                                                                        <p>{course.name}</p>
                                                                        <p>{course.courseType}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{getDate(course.createdAt)}</td>
                                                        <td>{course.totalLecture}</td>

                                                        <td>{course.status}</td>
                                                        <td><VisibilitySharpIcon className="btn-icon training-preview-icon" onClick={e => this.handleLMS(course.id)} ></VisibilitySharpIcon></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>

                                    </div>
                                </Tab>
                            </Tabs>
                        </div> : null
                    }
                </div>
                <div className="row mt-2">
                    <div className='footer-wrapper justify-content-end d-flex'>
                        <Button variant="primary" type="button" className="text-white mr-2" onClick={this.shareCampaignTempalte}>Share</Button>
                        <Button variant="success" type="button" className="text-white" onClick={this.handleFinish}>Finish</Button>
                    </div>
                </div>
                <PreviewThumbnail
                    src={this.state.previewSrc}
                    showModal={this.state.showModal}
                    attackType={this.state.attackType}
                    previewType={this.state.previewType}
                    showHandle={this.showHandleThumbnail}
                    html={this.state.selectedTeplate && this.state.selectedTeplate.template ? this.state.selectedTeplate.template.html : ''}
                ></PreviewThumbnail>

                <TestEmails
                    attackType={this.state.attackType}
                    emails={this.state.emails}
                    firstname={this.state.firstname}
                    lastname={this.state.lastname}
                    nextPath={this.props.nextPath}
                    testCampaign={this.testCampaign}
                    showModal={this.state.showTestModal}
                    showHandle={this.showHandleTestEmail}
                    handleEmails={(e) => this.setState({ emails: e.target.value })}
                    handleFirstname={(e) => this.setState({ firstname: e.target.value })}
                    handleLastname={(e) => this.setState({ lastname: e.target.value })}
                ></TestEmails>
                {this.state['loading'] ? <Loader /> : ''}
            </>
        )
    }
}