import React, { Component } from "react";
import ApexCharts from 'apexcharts'

export default class PhishRateNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chart: null
		}
	}

	componentDidMount = () => {
		this.load();
	}

	load = () => {
		var options = {
			series: [{
				name: 'Industry Risk',
				data: this.props.phishRate? this.props.phishRate: []
			}, {
				name: 'Current Risk',
				data: this.props.currentRate? this.props.currentRate: []
			}],
			chart: {
				height: 280,
				type: 'area',
				toolbar: {
					show: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			xaxis: {
				type: 'datetime',
				categories: this.props.date? this.props.date: []
			},
			legend: {
				show:true,
				position: 'top',
			  },
			tooltip: {
				x: {
					format: 'dd/MM/yy HH:mm'
				},
			},
		};
		var chart = new ApexCharts(document.querySelector("#phish-rate"), options);
		if(chart) chart.render();
		this.setState({ chart: chart });
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.currentRate !== prevProps.currentRate){
			if(this.state.chart) this.state.chart.destroy();
			this.load();
		}
	}

	render() {
		return (
			<div id="phish-rate"></div>
		);
	}
}