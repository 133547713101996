import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { Modal, Form, Button, Table } from 'react-bootstrap';

import './test-campaign-email.css';
import 'react-phone-input-2/lib/style.css';

export default class Preview extends Component {
    constructor() {
        super();
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            targetDetails: [],
            phoneList: []
        }
    }

    addTargetDetails = () => {
        if(this.props.attackType === "Phishing" || this.props.attackType === "Ransomware" || this.props.attackType === "Attachment" || this.props.attackType === "QRcode"){
            if(!this.state.firstname.length){
                toast.error('Please Enter FirstName')
                return ;
            } else if(!this.state.email.length){
                toast.error('Please Enter Email Id')
                return ;
            }
        } else {
            if(this.state.phone.length == 0 || this.state.phone === '91'){
                toast.error('Please Enter Phone No.')
                return ;
            }
        }
        let targetDetails = this.state.targetDetails;
        targetDetails.push({ 
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: (this.props.attackType === "Phishing" || this.props.attackType === "Ransomware" || this.props.attackType === "Attachment" || this.props.attackType === "QRcode") ? this.state.email: this.state.phone,
            phone: this.state.phone,
        })
        this.setState({ targetDetails, firstname: '', lastname: '', email: '', phone: '91' })
    }

    deleteTargetDetails = (index) => {
        let targetDetails = this.state.targetDetails;
        targetDetails.splice(index, 1);
        this.setState({ targetDetails })
    }

    sendTestEmail = () => {
        if(!this.state.targetDetails.length){
            toast.error('Please Add AtLeast One Email Id or Phone No.')
            return ;
        }
        this.props.testCampaign(this.state.targetDetails);
    }

    render() {

        const attackType = this.props.attackType;

        return (
            <Modal size="lg" show={this.props.showModal} onHide={this.props.showHandle}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h5>Test Template</h5>
                        <p>Test your template before running the campaign</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="addUserDetail">
                        <h6 className='mt-0'>{(attackType === "Phishing" || attackType === "Ransomware" || attackType === "Attachment" || attackType === "QRcode")? "Add Emails": "Add Phone No."}</h6>
                    </div>
                    {(attackType === "Phishing" || attackType === "Ransomware" || attackType === "Attachment" || attackType === "QRcode") ?
                        <div>
                            <div className='live-form d-flex  w-100 mb-3'>
                                <div className=" d-flex flex-column" style={{ flex: 3.15 }}>
                                    <Form.Control type="text" name="firstname" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value})} placeholder="Firstname(Required)" />
                                </div>
                                <div className="mx-2 d-flex flex-column" style={{ flex: 3.15 }}>
                                    <Form.Control type="text" name="lastname" value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value})} placeholder="Lastname(Optional)" />
                                </div>
                                <div className="d-flex flex-column" style={{ flex: 5 }}>
                                    <Form.Control type="email" placeholder="Email-Id(Required)" name="email" value={this.state.email}  onChange={e => this.setState({ email: e.target.value})} />
                                </div>
                                <div className='d-flex justify-content-center align-items-start' style={{ flex: 1.3 }} >
                                    <Button variant="outline-secondary" id="add-btn" onClick={this.addTargetDetails} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Add'} >
                                        <AddIcon style={{ color: "darkcyan", fontSize: '28px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div> :
                        <div>
                            <div className='live-form d-flex  w-100 mb-3'>
                                <div className="col-sm-6 pl-0">
                                    <Form.Label>Please enter phone number</Form.Label>
                                    <PhoneInput
                                        country={'in'}
                                        inputProps={{
                                            name: 'Phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        value={this.state.phone}
                                        isValid={(value, country) => {
                                            if (value.match(/12345/)) {
                                                return 'Invalid value: ' + value + ', ' + country.name;
                                            } else if (value.match(/1234/)) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        }}
                                        onChange={phone => {
                                            this.setState({ phone })
                                        }}
                                    ></PhoneInput>
                                </div>
                                <div style={{marginTop: '30px' }} >
                                    <Button variant="outline-secondary" id="add-btn" onClick={this.addTargetDetails} style={{ color: 'white', borderColor: 'white', padding: 0 }} title={'Add'} >
                                        <AddIcon style={{ color: "darkcyan", fontSize: '28px' }} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="addUserDetail">
                        <h6 className='mt-0'>{(attackType === "Phishing" || attackType === "Ransomware" || attackType === "Attachment" || attackType === "QRcode")? "Users Email List": "Users Phone No. List"}</h6>
                    </div>
                    <div className="tableContainerWrapInner">
                        {(attackType === "Phishing" || attackType === "Ransomware" || attackType === "Attachment" || attackType === "QRcode")?
                            <Table responsive>
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.targetDetails.map((target, index) => {
                                        return <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{target.firstname} </td>
                                            <td>{target.lastname? target.lastname: '---'}</td>
                                            <td>{target.email}</td>
                                            <td onClick={() => this.deleteTargetDetails(index)} style={{ color: 'red', cursor: 'pointer'}}><Delete></Delete></td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>: 
                            <Table responsive>
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Phone No.</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.targetDetails.map((target, index) => {
                                        return <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>+{target.phone}</td>
                                            <td onClick={() => this.deleteTargetDetails(index)} style={{ color: 'red', cursor: 'pointer'}}><Delete></Delete></td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ padding: '0.55rem' }}>
                    <Button className='btn closeBtn' onClick={this.props.showHandle}>Close</Button>
                    <Button className='btn btn-success' disabled={this.state.targetDetails.length>0? false: true} onClick={this.sendTestEmail}>Send</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
