import { toast } from "react-toastify";
import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import Pagination from "react-js-pagination";
import AddIcon from '@material-ui/icons/Add';
import { LiaSyncAltSolid } from "react-icons/lia";
import CancelIcon from "@material-ui/icons/Cancel";
import Accordion from 'react-bootstrap/Accordion';
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { Button, Form, Table, Image, FormControl, InputGroup } from "react-bootstrap";

import axios from "../../../axios";
import apiError from "../../../error";
import { getDate } from "../../../function";
import Spinner from "../../../component/spinner/spinner";
import ConfiguredModal from "../../launch-modal/configured-modal";

import "./training.css";
import ReplyModal from "../../campaigns/results/reply-modal";
export default class Training extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lmsURL: "",
			imageURL: "",
			previewURL: "",
			lmsCourses: [],
			loading: false,
			courseName: {},
			courseLoader: false,
			selectedCourses: [],
			threatcopCourses: [],
			limit: 10,
			search: "",
			activePage: 1,
			currentCourseId: "",
			showModal: false,
			courseId: null,
			options: {},
			isCheckForConfiguration: false,
			currentOption: {},
			/* course template */
			templates: [],
			html: null,
			subject: null, 
			showPreview: false,
			sendCourseTemplateId: this.props.sendCourseTemplateId,
			courseCompletionTemplateId: this.props.courseCompletionTemplateId,
			courseQuizFailReminderTemplateId: this.props.courseQuizFailReminderTemplateId,
			sendQuizTemplateId: this.props.sendQuizTemplateId,
		};
	}

	componentWillMount = () => {
		this.loadCourseTemplate();
		this.loadCourseThreatcop();
	};

	loadCourseThreatcop = async () => {
		await this.setState({ loading: true });
		axios
			.get("/api/lms/course-list-threatcop", {
				params: {
					campaignTemplateId: this.props.campaignTemplateId,
				},
			})
			.then((res) => {
				var selectedCourses = [];
				var options = {};
				for (let i = 0; i < res.data.courses.length; i++) {
					selectedCourses.push(res.data.courses[i].courseId);
					options[res.data.courses[i].courseId] = {
						isLectureSwitching: res.data.courses[i].isLectureSwitching,
						isVideoSeeking: res.data.courses[i].isVideoSeeking,
						isQuizBeforeVideo: res.data.courses[i].isQuizBeforeVideo,
						isQuizFailReminder: res.data.courses[i].isQuizFailReminder,
						isUserCorrectAnswer: res.data.courses[i].isUserCorrectAnswer,
						isVideoSpeedControls: res.data.courses[i].isVideoSpeedControls,
						quizPassingPercentage: res.data.courses[i].quizPassingPercentage,
					};
				}

				this.setState({ threatcopCourses: res.data.courses, selectedCourses: selectedCourses, loading: false, options: options }, () => {
					this.loadCourseLMS();
				});
			})
			.catch((err) => {
				this.setState({ loading: false });
				apiError(err);
			});
	};

	loadCourseLMS = async (status = false) => {
		await this.setState({ courseLoader: true });
		axios
			.get("/api/lms/course-list", {
				params: {
					limit: this.state.limit,
					search: this.state.search,
					page: this.state.activePage,

					toDate: new Date(),
					fromDate: new Date(new Date().setFullYear(new Date().getFullYear() - 3)),
				},
			})
			.then((res) => {
				var courseName = { ...this.state.courseName };
				for (let i = 0; i < res.data.courses.length; i++) {
					courseName[res.data.courses[i].id] = {
						name: res.data.courses[i].name,
						logo: res.data.courses[i].logo,
					};
				}
				this.setState({
					courseLoader: false,
					count: res.data.count,
					courseName: courseName,
					lmsURL: res.data.lmsURL,
					imageURL: res.data.imageURL,
					lmsCourses: res.data.courses,
					previewURL: res.data.previewURL,
				});
				if (status) {
					toast.success("Course sync successfully");
				}
			})
			.catch((err) => {
				this.setState({ courseLoader: false });
				apiError(err);
			});
	};

	handleCourseCreate = async () => {
		if (!this.state.selectedCourses || this.state.selectedCourses.length === 0) {
			toast.error("Please select course first");
		} else {
			await this.setState({ loading: true });
			let selectedOptions = {};
			this.state.selectedCourses.forEach((courseId) => {
				if (this.state.options[courseId]) {
					selectedOptions[courseId] = this.state.options[parseInt(courseId)];
				} else {
					selectedOptions[courseId] = {
						isLectureSwitching: false,
						isVideoSeeking: false,
						isQuizBeforeVideo: false,
						isQuizFailReminder: true,
						isUserCorrectAnswer: false,
						isVideoSpeedControls: false,
						quizPassingPercentage: 10,
					};
				}
			});
			// adding the course options with the keys
			axios
				.post("/api/lms/course-create", {
					selectedOptions: selectedOptions,
					campaignTemplateId: this.props.campaignTemplateId,

					sendCourseTemplateId: this.state.sendCourseTemplateId,
					courseCompletionTemplateId: this.state.courseCompletionTemplateId,
					courseQuizFailReminderTemplateId: this.state.courseQuizFailReminderTemplateId,
					sendQuizTemplateId: this.state.sendQuizTemplateId,
				})
				.then((res) => {
					this.props.handleTraining(1);
					this.setState({ loading: false });
					this.props.handleTabChange("next");
				})
				.catch((err) => {
					this.setState({ loading: false });
					apiError(err);
				});
		}
	};

	handleCourseRemove = (courseId) => {
		var selectedCourses = this.state.selectedCourses;
		const index = selectedCourses.indexOf(courseId);
		if (index > -1) {
			selectedCourses.splice(index, 1);
			this.setState({ selectedCourses: selectedCourses });
		}
	};

	handleCourseAdd = (courseId) => {
		var selectedCourses = this.state.selectedCourses;
		const index = selectedCourses.indexOf(courseId);
		if (index === -1) {
			selectedCourses.push(courseId);
			this.setState({ selectedCourses });
		} else {
			delete selectedCourses[index];
			this.setState({ selectedCourses });
		}
	};

	showHandle = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	passToModal = (courseId, options) => {
		this.setState({ showModal: !this.state.showModal });
		this.setState({ courseId });
		let tempOption = {};
		let optionIds = Object.keys(options).map((key) => parseInt(key));
		if (optionIds.includes(courseId)) {
			tempOption = options[courseId];
		} else {
			tempOption = {
				isLectureSwitching: false,
				isVideoSeeking: false,
				isQuizBeforeVideo: false,
				isQuizFailReminder: true,
				isUserCorrectAnswer: false,
				isVideoSpeedControls: false,
				quizPassingPercentage: 10,
			};
		}
		this.setState({ currentOption: tempOption });
	};

	setOptions = (courseId, settings) => {

		this.setState({ currentCourseId: courseId });

		const checkConfig = settings.isCheckConfigure;
		this.setState({ isCheckForConfiguration: checkConfig });
		let tempObj = {};
		tempObj[courseId] = settings;

		const newOptions = { ...this.state.options, ...tempObj };

		this.setState({
			options: newOptions,
		});
	};

	handleLMS = async (url) => {
		axios
			.get("/api/lms", {
				params: { url: url },
			})
			.then((res) => {
				window.open(res.data.url, "_blank");
			})
			.catch((err) => {
				apiError(err);
			});
	};

	loadCourseTemplate = async () => {
		axios.get("/api/lms/template-list-threatcop", {})
			.then((res) => {
				this.setState({ templates: res.data.templates })
			})
			.catch((err) => {
				apiError(err);
			});
	};
	loadCourseTemplatePreview = async (lmsTemplateType) => {
		
		let id = lmsTemplateType === 'course' ? this.state.sendCourseTemplateId :
			lmsTemplateType === 'completion' ? this.state.courseCompletionTemplateId :
			this.state.courseQuizFailReminderTemplateId;

		axios.get("/api/lms/template-preview-threatcop", {
			params: {
				id: id ? id : 0,
				templateType: lmsTemplateType
			}
		})
			.then((res) => {
				this.setState({ 
					showPreview: true,
					html: res.data.html, 
					subject: res.data.subject, 
				})
			})
			.catch((err) => {
				apiError(err);
			});
	};

	render() {
		return (
			<>
				<div className="email-template-wrap">
					<div className='rightSectionHeader'>
						<div className="row justify-content-between d-flex align-items-center">
							<div className="col-md-6 tab-right-header">
								<h6>Training</h6>
								<p>Create Training</p>
							</div>
							<div className="col-md-6 text-right">
								<Button type="button" className="darkBtn" onClick={() => this.loadCourseLMS(true)}>
									<Spinner loading={this.state.courseLoader}></Spinner><LiaSyncAltSolid className="btn-icon" />Sync Course
								</Button>
								<Button type="button" className="btn-primary ml-2" onClick={() => this.handleLMS(this.state.lmsURL)}>
									<AddIcon className="btn-icon" /> Create Course
								</Button>
							</div>
						</div>
					</div>

					<div className="campListGrids attackerAccordion">
						<Accordion>
							<Card>
								<Accordion.Toggle as={Card.Header} eventKey="0" className=" tableGrid">
									<div className="row align-items-center ">
										<div className="col-md-1 expandGrid mr-2"><ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon></div>
										Configure Email Templates
									</div>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<div className="emTempWrapper">
										<div className="row align-items-center">
											<div className="col-md-7">
												<div className="contentbar">
													<div className="tableContainerWrapInner">
														<Table responsive className="mb-0">
															<thead className="bg-light">
																<tr>
																	<th>Template</th>
																	<th>Select Template</th>
																	<th className="thCenter">Preview</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		Send Course
																	</td>
																	<td>
																		<Form.Group className='mb-0'>
																			<Form.Control as="select"
																				value={this.state.sendCourseTemplateId}
																				onChange={e =>
																					this.setState({
																						html: null, 
																						subject: null, 
																						sendCourseTemplateId: e.target.value
																					})
																				}
																			>
																				{this.state.templates?.course?.map((temp, key) => {
																					if (temp) return <option key={'corse' + key} value={temp.id}>{temp.name}</option>
																				})}
																			</Form.Control>
																		</Form.Group>
																	</td>
																	<td className="tdCenter setlinkColor" onClick={() => this.loadCourseTemplatePreview('course')}><a href="#">View</a></td>
																</tr>
																<tr>
																	<td>Course Completion</td>
																	<td>
																		<Form.Group className='mb-0'>
																			<Form.Control as="select"
																				value={this.state.courseCompletionTemplateId}
																				onChange={e =>
																					this.setState({
																						html: null, 
																						subject: null, 
																						courseCompletionTemplateId: e.target.value
																					})
																				}
																			>
																				{this.state.templates?.completion?.map((temp, key) => {
																					if (temp) return <option key={'con' + key} value={temp.id}>{temp.name}</option>
																				})}
																			</Form.Control>
																		</Form.Group>
																	</td>
																	<td className="tdCenter setlinkColor" onClick={() => this.loadCourseTemplatePreview('completion')}><a href="#">View</a></td>
																</tr>
																<tr>
																	<td>Course Quiz Fail Reminder</td>
																	<td>
																		<Form.Group className='mb-0'>
																			<Form.Control as="select"
																				value={this.state.courseQuizFailReminderTemplateId}
																				onChange={e =>
																					this.setState({
																						html: null, 
																						subject: null, 
																						courseQuizFailReminderTemplateId: e.target.value
																					})
																				}
																			>
																				{this.state.templates?.quiz_fail?.map((temp, key) => {
																					if (temp) return <option key={'con' + key} value={temp.id}>{temp.name}</option>
																				})}
																			</Form.Control>
																		</Form.Group>
																	</td>
																	<td className="tdCenter setlinkColor" onClick={() => this.loadCourseTemplatePreview('quiz_fail')} ><a href="#">View</a></td>
																</tr>
															</tbody>
														</Table>
													</div>
												</div>
											</div>
											<div className="col-md-5">
												<div className="warningCard">
													<h5><b>Note:</b> You can choose from the custom templates you've created in the TLMS portal or use the default templates provided. </h5>
												</div>
											</div>
										</div>
									</div>
								</Accordion.Collapse>
							</Card>
						</Accordion>

					</div>

					<div className="traningNumberb coursedFlex my-3">
						{this.state.selectedCourses.map((courseId, index) => {
							let isConfigure = false;
							if (this.state.options[courseId]) {
								isConfigure = true;
							}
							return (
								<div className="courseBadge" key={index}>
									<div className="courseImg">
										<Image src={this.state.imageURL + this.state.courseName[courseId]?.logo ?? ''} alt="no image" height="45px" width="45px"></Image>
									</div>
									<div>{this.state.courseName[courseId]?.name ?? ''}
										<CancelIcon className="training-icon" onClick={(e) => this.handleCourseRemove(courseId)}></CancelIcon>{" "}
										<p className="setTxt" onClick={() => this.passToModal(courseId, this.state.options)}>
											{isConfigure ? <span>Configured</span> : <span>Configure</span>}
										</p>
									</div>
								</div>
							);
						})}
					</div>

					<div className="filter-wrapper trainingSearch">
						<div className="row align-items-center mini-head">
							<div className="col-md-6 d-flex align-items-center pl-0">
								<div className="positionSearchIcon">
									<InputGroup className="search-wrap input-group w-70">
										<FormControl
											type="text"
											placeholder="Search"
											value={this.state.search}
											onChange={(e) => this.setState({ search: e.target.value, activePage: 1 }, () => this.loadCourseLMS())}
											className="inputSearch mr-sm-2 form-control form-control"
										/>
										<SearchSharpIcon className="search-icon"></SearchSharpIcon>
									</InputGroup>
								</div>
							</div>

							<div className="col-md-6 pr-0">
								<div className="rightFilters">
									<Form.Group
										controlId="exampleForm.ControlSelect1"
										onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.loadCourseLMS())}
										className="d-inline-block "
									>
										<Form.Control as="select">
											<option>Show</option>
											<option value="10">10</option>
											<option value="25">25</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</Form.Control>
									</Form.Group>
								</div>

							</div>
						</div>
					</div>


					{this.state.lmsCourses ? (
						<div className="tableContainerWrapInner mt-2">
							<Table className="training-table" borderless>
								<thead>
									<tr>
										<th>#</th>
										<th>Name</th>
										<th>Created on</th>
										<th>Lectures</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{this.state.lmsCourses.map((course, index) => {
										return (
											<tr key={index} className="setcheckBox">
												<td>
													<Form.Group controlId="formBasicCheckbox">
														<Form.Check
															checked={this.state.selectedCourses.indexOf(course.id) > -1}
															type="checkbox"
															label=""
															onChange={(e) => this.handleCourseAdd(course.id)}
														/>
													</Form.Group>
												</td>
												<td className="onlyForPinter">
													<div className="flexTableData">
														<div className="companyLogoIcon">
															<Image src={this.state.imageURL + course.logo} alt="no image" height="80px" width="80px"></Image>
														</div>

														<div>
															<h6>{course.name}</h6>
														</div>
													</div>
												</td>
												<td>
													<div className="timeBox">
														<p>{getDate(course.createdAt)}</p>
													</div>
												</td>
												<td>{course.totalLecture}</td>
												<td>
													<p
														className="previewBtn"
														onClick={(e) => this.handleLMS(`${this.state.previewURL}${course.id}?r=Sw4s56g`)}
													>View</p>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
							<div className="pagination-Wrap">
								<Pagination
									itemClass="page-item"
									linkClass="page-link"
									activePage={this.state.activePage}
									itemsCountPerPage={this.state.limit}
									totalItemsCount={this.state.count}
									onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.loadCourseLMS())}
								/>
							</div>
						</div>
					) : null}
					<ConfiguredModal
						showModal={this.state.showModal}
						showHandle={this.showHandle}
						courseId={this.state.courseId}
						options={this.state.currentOption}
						setOptions={this.setOptions}
					/>
					<ReplyModal
						buffer={this.state.html}
						show={this.state.showPreview}
						handleClose={(e) => this.setState({ showPreview: false })}
					></ReplyModal>
				</div>
				
				<div className="row mt-2">
					<div className="footer-wrapper justify-content-end d-flex">
						<Button className="btn closeBtn mr-2" onClick={(e) => this.props.handleTabChange("prev")}>
							<ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous
						</Button>
						<Button className="btn btn-success" disabled={this.state.loading} type="button" onClick={this.handleCourseCreate}>
							<Spinner loading={this.state.loading}></Spinner>
							<ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next
						</Button>
					</div>
				</div>
			</>
		);
	}
}
