import React, { Component } from "react";
import Tooltip from 'react-bootstrap/Tooltip';
import { Accordion, Card } from 'react-bootstrap'
import { ProgressBar, Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import {
    PiEnvelopeSimpleDuotone, PiEnvelopeOpenDuotone, PiPasswordDuotone,
    PiFlagPennantDuotone, PiChatCenteredDotsDuotone, PiCursorClickDuotone, PiPhoneOutgoingDuotone,
    PiPhoneCallDuotone, PiPaperclipHorizontalDuotone, PiQrCodeDuotone, PiDesktopDuotone
} from "react-icons/pi";

import { getDate } from '../../../function';
import './campaign.css'

export default class CampaignTable extends Component {

    days_between = (date1, date2, Expired) => {
        const ONE_DAY = 1000 * 60 * 60 * 24;
        const differenceMs = Math.abs(date1 - date2);
        var day = Math.round(differenceMs / ONE_DAY);
        day = day ? day : 1;
        day = day > 7 ? 100 : day * 100 / Expired;
        day = day > 100 ? 100 : day;
        return Math.ceil(day);
    }

    handlePage = (campaignId, simulationId) => {
        this.props.nextPath('/campaign/' + campaignId + '/' + simulationId)
    }

    render() {

        const attackType = this.props.campaign.attack_type.name;
        const simulations = this.props.campaign.simulations ? this.props.campaign.simulations : [];
        var template = this.props.campaign.assign_templates ? this.props.campaign.assign_templates[0] : null;
        template = template && template.campaign_template && template.campaign_template.simulation_type ? template.campaign_template.simulation_type.name : '---';

        return (
            <>
                <Accordion.Toggle as={Card.Header} eventKey={this.props.index} className={this.props.activeRow ? 'active tableGrid' : 'tableGrid'} onClick={e => this.props.handleActiveCampaign(this.props.campaign.id)}>
                    <div className="row align-items-center ">
                        <div className="col-md-1 expandGrid"><ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon></div>
                        <div className="col-md-3 text-ellipsis">{this.props.campaign.name}</div>
                        <div className="col-md-2">{getDate(this.props.campaign.createdAt)}</div>
                        <div className="col-md-1 ">{this.props.campaign.total}</div>
                        <div className="col-md-2">{template}</div>
                        <div className="col-md-2">{attackType}</div>
                        <div className="col-md-1">{this.props.campaign.frequency ? this.props.campaign.frequency : '---'}</div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.index} style={{ padding: '10px 55px', background: '#f6f6f6', marginTop: '-14px' }}>
                    <Card.Body>
                        <div id="table-resp" className="tableData">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Scheduled At</th>
                                        <th>Activity Tracking</th>
                                        <th>Progress</th>
                                        <th className="th-Center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {simulations && simulations.map((simulation, index) => {
                                        var day = this.days_between(new Date(), new Date(simulation.scheduledAt), this.props.Expired);
                                        return <tr key={'campaigndetails-' + index} >
                                            <td>{this.props.campaign.name + ' (' + (index + 1) + ')'}</td>
                                            <td>{getDate(simulation.scheduledAt)}</td>
                                            <td>
                                                {simulation.status ?
                                                    (attackType === "Phishing" || attackType === "Ransomware" || attackType === "QRcode" || attackType === "Attachment") ?
                                                        <ul className="attack_info">
                                                            {simulation.status.map((status, index) => {
                                                                return <div key={`status-${index}`}>
                                                                    {status['message'] === "Email Sent" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top'>Email Sent</Tooltip>}>
                                                                            <div className="li-box"><PiEnvelopeSimpleDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "Email Opened" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top'>Email Opened</Tooltip>}>
                                                                            <div className="li-box"><PiEnvelopeOpenDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "Link Clicked" ?
                                                                        attackType === "QRcode" ?
                                                                            <OverlayTrigger
                                                                                placement='top'
                                                                                overlay={<Tooltip id='top'>QRcode Scanned</Tooltip>}>
                                                                                <div className="li-box"><PiQrCodeDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                            </OverlayTrigger> :
                                                                            <OverlayTrigger
                                                                                placement='top'
                                                                                overlay={<Tooltip id='top'>Link Clicked</Tooltip>}>
                                                                                <div className="li-box"><PiCursorClickDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                            </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "Data Submitted" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top'>Data Submitted</Tooltip>}>
                                                                            <div className="li-box"><PiPasswordDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "System Hacks" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top'>System Hacks</Tooltip>}>
                                                                            <div className="li-box"><PiDesktopDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "Email Reported" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top' style={{ 'marginLeft': '-7px' }}>Email Reported</Tooltip>}>
                                                                            <div className="li-box"><PiFlagPennantDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                    {status['message'] === "Attachment Opened" ?
                                                                        <OverlayTrigger
                                                                            placement='top'
                                                                            overlay={<Tooltip id='top'>Attachment Opened</Tooltip>}>
                                                                            <div className="li-box"><PiPaperclipHorizontalDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                        </OverlayTrigger> : null
                                                                    }
                                                                </div>
                                                            })}
                                                        </ul> :
                                                        (attackType === "Smishing") ?
                                                            <ul className="attack_info_sms">
                                                                {simulation.status.map((status, index) => {
                                                                    return <div key={`status-${index}`}>
                                                                        {status['message'] === "SMS Sent" ?
                                                                            <OverlayTrigger
                                                                                placement='top'
                                                                                overlay={<Tooltip id='top'>SMS Sent</Tooltip>}>
                                                                                <div className="li-box"><PiChatCenteredDotsDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                            </OverlayTrigger> : null
                                                                        }
                                                                        {status['message'] === "Link Clicked" ?
                                                                            <OverlayTrigger
                                                                                placement='top'
                                                                                overlay={<Tooltip id='top'>Link Clicked</Tooltip>}>
                                                                                <div className="li-box"><PiCursorClickDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                            </OverlayTrigger> : null
                                                                        }
                                                                        {status['message'] === "Data Submitted" ?
                                                                            <OverlayTrigger
                                                                                placement='top'
                                                                                overlay={<Tooltip id='top'>Data Submitted</Tooltip>}>
                                                                                <div className="li-box"><PiPasswordDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                            </OverlayTrigger> : null
                                                                        }

                                                                    </div>
                                                                })}
                                                            </ul> :
                                                            (attackType === "Vishing") ?
                                                                <ul className="attack_info_call">
                                                                    {simulation.status.map((status, index) => {
                                                                        return <div key={`status-${index}`}>
                                                                            {status['message'] === "Call Initiated" ?
                                                                                <OverlayTrigger
                                                                                    placement='top'
                                                                                    overlay={<Tooltip id='top'>Call Initiated</Tooltip>}>
                                                                                    <div className="li-box"><PiPhoneOutgoingDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                </OverlayTrigger> : null
                                                                            }
                                                                            {status['message'] === "Call Picked" ?
                                                                                <OverlayTrigger
                                                                                    placement='top'
                                                                                    overlay={<Tooltip id='top'>Call Picked</Tooltip>}>
                                                                                    <div className="li-box"><PiPhoneCallDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                </OverlayTrigger> : null
                                                                            }
                                                                            {status['message'] === "Data Submitted" ?
                                                                                <OverlayTrigger
                                                                                    placement='top'
                                                                                    overlay={<Tooltip id='top'>Data Submitted</Tooltip>}>
                                                                                    <div className="li-box"><PiPasswordDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                </OverlayTrigger> : null
                                                                            }

                                                                        </div>
                                                                    })}
                                                                </ul> :

                                                                (attackType === "WhatsApp") ?
                                                                    <ul className="attack_info_sms">
                                                                        {simulation.status.map((status, index) => {
                                                                            return <div key={`status-${index}`}>
                                                                                {status['message'] === "Message Sent" ?
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip id='top'>SMS Sent</Tooltip>}>
                                                                                        <div className="li-box"><PiChatCenteredDotsDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                    </OverlayTrigger> : null
                                                                                }
                                                                                {status['message'] === "Link Clicked" ?
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip id='top'>Link Clicked</Tooltip>}>
                                                                                        <div className="li-box"><PiCursorClickDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                    </OverlayTrigger> : null
                                                                                }
                                                                                {status['message'] === "Data Submitted" ?
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip id='top'>Data Submitted</Tooltip>}>
                                                                                        <div className="li-box"><PiPasswordDuotone className="img-fluid proccess-img" /><li key={simulation.id + '-' + index}>{status['count']? status['count']: 0}</li></div>
                                                                                    </OverlayTrigger> : null
                                                                                }
                                                                            </div>
                                                                        })}
                                                                    </ul> : null
                                                    : null
                                                }
                                            </td>
                                            {!simulation.isScheduled ?
                                                <td>
                                                    {simulation.completedAt ?
                                                        <ProgressBar variant="success" animated now={100} label='Completed'></ProgressBar> :
                                                        <ProgressBar animated now={day} label={`${day}%`}></ProgressBar>
                                                    }
                                                </td> :
                                                <td><ProgressBar variant="warning" animated now={100} label='Scheduled'></ProgressBar></td>
                                            }
                                            <td className="td-center" onClick={e => this.handlePage(this.props.campaign.id, simulation.id, simulation.isScheduled)}>
                                                <a href="#">View</a>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </>
        );
    }
}
