import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Card, Image, Button, Modal, Form } from 'react-bootstrap';

import axios from '../../axios';
import apiError from '../../error';
import Loader from "../../component/loader/loader";

import ThreatcopLogo from '../../assets/threatcop-logo.png';
import AccountDelete from '../../assets/Account-Delete.jpg';

import './delete-account.css';

export default class DeleteAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            message: '',
            loading: false,
            deleteSuccess: false,
            checkValidPage: false,
            key: this.props.match.params.key
        }
    }

    componentDidMount = () => {
        this.checkValidPage();
    }
    
    deleteProcessing = () => {
        if(this.state.message.length > 20){
            this.setState({ loading: true })
            axios.post('/api/user/deleteProcessing', {
                key: this.state.key,
                message: this.state.message
            })
            .then(r => {
                this.handleModal();
                this.setState({ loading: false, deleteSuccess: true })
                toast.success('Your Account Has Been Deleted')
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
        } else {
            toast.error('Your Message Length At Least 20 Character')
        }
    }

    checkValidPage = () => {
        axios.post('/api/user/checkValidPage', {
            key: this.state.key,
        })
        .then(res => {
            if(res.data.status){
                this.setState({ checkValidPage: true })
            } else {
                window.location = "/404"
            }
        })
        .catch(err => {
            window.location = "/404"
        })
    }

    handleModal = () => {
        this.setState({ show: false })
    }

    render() {
        return (
            <div>
                {this.state.checkValidPage? 
                    <div className="content-wrap" id="delete-account">
                        <Card>
                            <Card.Body>
                                <div className="row text-center">
                                    <div className="col-sm-10 mx-auto">
                                        <div className="col-md-12">
                                            <Image className="mini-img" src={ThreatcopLogo}></Image>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="main-image">
                                                <Image src={AccountDelete} alt="No Image"></Image>
                                            </div>
                                        </div>
                                        {this.state.deleteSuccess?
                                            <div>
                                                <CheckCircleIcon fontSize="large" className="delete-icon-color"></CheckCircleIcon>
                                                <h5 className="heading_delete">Your Account has been deleted</h5>
                                            </div>:
                                            <div className="col-sm-12">
                                                <div className="content_img">
                                                    <h5 className="heading_delete">Delete Your account</h5>
                                                    <p>On deleting your TSAT account, all your account-related data will be lost and cannot be retrieved again. If you still wish to continue, click on the button below.</p>
                                                    <Button variant="danger" className="w-50" onClick={e => this.setState({ show: true })}>Delete Account</Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Modal show={this.state.show} className="chooseModal" onHide={this.handleModal} aria-labelledby="example-custom-modal-styling-title">
                            <Modal.Header closeButton>
                                <Modal.Title id="example-custom-modal-styling-title">
                                    <h6 className="mb-0">Message</h6>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your message why you want to delete Account."  value={this.state.message} onChange={e => this.setState({message: e.target.value})}/>
                                    <p className="delete-label">message length at least 20 character</p>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="mr-2" onClick={this.handleModal}> Close </Button>
                                <Button variant="primary" onClick={this.deleteProcessing}>Delete Account</Button>
                            </Modal.Footer>
                        </Modal>
                        {this.state.loading && <Loader/>}
                    </div>: <Loader/>
                }
            </div>
        );
    }
}