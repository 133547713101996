import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            baseDN: '',
            ssl: 'http',
            username: '',
            loading: false,
            disable: true,
            disabled: true,
            password: '*****',
            savePassword: false
        }
    }

    componentDidMount = () => {
        this.getAd();
    }

    validateAD = () => {

        if (this.state.ssl.length === 0) {
            toast.error('Please Choose Protocol');
            return false;
        } else if (this.state.url.length === 0) {
            toast.error('Please Enter URL');
            return false;
        } else if (this.state.baseDN.length === 0) {
            toast.error('Enter Domain Name');
            return false;
        }

        if (this.state.username.length === 0) {
            toast.error('Enter Username');
            return false;
        }

        return {
            ssl: this.state.ssl,
            url: this.state.url,
            baseDN: this.state.baseDN,
            username: this.state.username,
            savePassword: this.state.savePassword,
            password: this.state.password !== "*****" ? this.state.password : null,
        };
    }

    handleAd = () => {
        var data = this.validateAD();
        if (data) {
            axios.post('/api/meta/ad', data)
                .then(res => {
                    toast.success("AD Details Updated successfully");
                })
                .catch(err => {
                    apiError(err);
                })
        }
    }

    getAd = () => {
        axios.get('/api/meta/ad', {})
            .then(res => {
                if (res.data && res.data.data && res.data.data.value) {
                    var value = JSON.parse(res.data.data.value);
                    this.setState({
                        ssl: value.ssl,
                        url: value.url,
                        password: "*****",
                        baseDN: value.baseDN,
                        username: value.username,
                        savePassword: value.savePassword
                    })
                }
            })
            .catch(err => {
                apiError(err);
            })
    }

    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>AD Credentials</h6>
                    <p>Credentials for Active Directory integration, used for user authentication and directory services. </p>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <div className='profile-form'>
                            <Form>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Form.Group>
                                                    <Form.Label>URL</Form.Label>
                                                    <div className="row">
                                                        <div className="col-md-6 pr-1">
                                                            <Form.Control value={this.props.ssl} onChange={e => this.setState({ ssl: e.target.value, disabled: false })} as="select">
                                                                <option value='http://'>http://</option>
                                                                <option value='https://'>https://</option>
                                                            </Form.Control>
                                                        </div>
                                                        <div className="col-md-6 pl-1 pr-1">
                                                            <Form.Control type="text" autoComplete="new-password" placeholder="URL" value={this.state.url} onChange={e => this.setState({ url: e.target.value, disabled: false })} />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                            </div>

                                            <div className="col-md-6 align-items-center pl-0 pl-1">
                                                <Form.Group>
                                                    <Form.Label>Base Domain Name </Form.Label>
                                                    <Form.Control type="text" autoComplete="new-password" placeholder="Domain name" value={this.state.baseDN} onChange={e => this.setState({ baseDN: e.target.value, disabled: false })} />
                                                </Form.Group>
                                            </div>

                                            <div className="col-md-6 align-items-center pr-1" >
                                                <Form.Group>
                                                    <Form.Label>Username</Form.Label>
                                                    <Form.Control type="text" autoComplete="new-password" placeholder="username" value={this.state.username} onChange={e => this.setState({ username: e.target.value, disabled: false })} />
                                                </Form.Group>
                                            </div>

                                            <div className="col-md-6 align-items-center pl-1">
                                                <Form.Group>
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" autoComplete="new-password" placeholder="*****" value={this.state.password} onChange={e => this.setState({ password: e.target.value, disabled: false })} />
                                                </Form.Group>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <Form.Check type="switch" id="custom-switch-ad-creds" label="Save Password" checked={this.state.savePassword} onChange={e => this.setState({ savePassword: !this.state.savePassword, disabled: false })} />
                                            </div>
                                            <div className="col-md-2">
                                                <Button variant="success" type="button" onClick={this.handleAd} disabled={this.state.disabled}>Update</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}