import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { Card, Breadcrumb, Table } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import Loader from "../../../component/loader/loader";

export default class AuditLogs extends Component {
    constructor(props){
        super(props);
        this.state = {
            count: 0,
            limit: 100,
            auditLogs: [],
            activePage: 1,
            loading: true
        }
    }

    componentDidMount = () => {
        this.loadLogs();
    }

    loadLogs = async() => {
        await this.setState({ loading: true });
        axios.get('/api/audit-log', {
            params: {
                limit: this.state.limit,
                page: this.state.activePage
            }
        })
        .then(async res => {
            var ips = {};
            for(let i=0; i<res.data.auditLogs.length; i++){
                if(res.data.auditLogs[i] && res.data.auditLogs[i].ip){
                    if(ips[res.data.auditLogs[i].ip]){
                        res.data.auditLogs[i]['location'] = ips[res.data.auditLogs[i].ip] && ips[res.data.auditLogs[i].ip].city? ips[res.data.auditLogs[i].ip].city: '---';
                    } else {
                        let ipDetail = await this.loadLocations(res.data.auditLogs[i].ip).then(r => r)
                        ips[res.data.auditLogs[i].ip] = ipDetail;
                        res.data.auditLogs[i]['location'] = ipDetail && ipDetail.city? ipDetail.city: '---';
                    }
                }
            }
            this.setState({ count: res.data.count, auditLogs: res.data.auditLogs, loading: false })
        })
        .catch(err => {
            apiError(err)
            this.setState({ loading: false })
        });
    }

    loadLocations = async (ip) => {
        if(navigator.onLine && ip && ip !== "::1"){
            try {
                const res = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=d7d910f638be4ab2b93f7ce3f8d05597&ip=${ip}`, {
                    headers: {
                        "access-control-allow-origin" : "*",
                        "Content-type": "application/json; charset=UTF-8"
                    }
                });
                return res.data;
            } catch (err) {
                return await new Promise((resolve) => { resolve({}); });
            }
        } else {
            return new Promise((resolve) => {resolve({})})
        }
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                            <h4 className="page-title">Audit Logs</h4>
                            </div>
                        </div> 
                    </div>
                </div>

                <div className="contentbar tableContainerWrap appTable">
                    <div className="tableContainerWrapInner">
                        <div className="page-content">
                            <Table responsive>
                                <thead className="bg-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Text</th>
                                        <th>Date</th>
                                        <th>Old Data</th>
                                        <th>New Data</th>
                                        <th>IP</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.auditLogs.map((log, index) => {
                                        var username = (log.text).split('by');
                                        return <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{log.type}</td>
                                            <td>{username[0]} by <span style={{color: 'green'}}>{username[1]}</span></td>
                                            <td>{getDate(log.createdAt)}</td>
                                            <td>{log.oldData? log.oldData: '---'}</td>
                                            <td>{log.newData? log.newData: '---'}</td>
                                            <td>{log.ip}</td>
                                            <td>{log.location}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
               

                <div className="pagination-Wrap">
                    <div className="row">
                        <div className="col-md-6">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.count}
                            onChange={(pageNumber) => this.setState({activePage: pageNumber}, ()=> this.loadLogs())}
                        />
                        {this.state.loading && <Loader></Loader>}
                        </div>
                      
                    </div>
                   
                </div>
                  
            </div>
        );
    }
}