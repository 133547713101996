import React, { Component } from "react";
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { InputGroup, FormControl, Button, Table, Spinner } from 'react-bootstrap';

import axios from '../../axios';
import apiError from "../../error";
import HackRecordList from '../../component/hackrecord/hack-component';

import './hackrecord.css'

export default class HackRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: false,
            hackrecord: [],
            isResult: false,
        }
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    records = async () => {
        if (this.state.email.length === 0 || !this.validateEmail(this.state.email)) {
            toast.error("Please Enter Valid Email Id")
            this.setState({ isResult: false })
            return;
        }
        await this.setState({ loading: true, hackrecord: [] });
        axios.post('/api/report/hackrecords', {
            email: this.state.email
        })
            .then(res => {
                this.setState({
                    isResult: true,
                    loading: false,
                    hackrecord: res.data.results,
                })
                setTimeout(() => {
                    for (let i = 0; i < this.state.hackrecord.length; i++) {
                        document.getElementById(`record${i}`).insertAdjacentHTML("beforeEnd", this.state.hackrecord[i].Description);
                    }
                }, 0)
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false, hackrecord: [] })
            })
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Hack Record

                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Check if an employee's corporate email ID has ever been compromised</Tooltip>}>
                                        <IoInformation className="tooltip-icon" style={{ 'marginTop': '-1px' }} ></IoInformation>
                                    </OverlayTrigger>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contentbar">

                    <div className="hackWrapper">
                        <div className="hackBox">
                            <div className="cardHeding">
                                <h6>Hack Records</h6>
                                <p> Enter your email id below and check your hack record status !</p>
                            </div>
                            <InputGroup>
                                <FormControl className="" type="email" onChange={(e) => this.setState({ email: e.target.value })} onKeyPress={e => { if (e.key === 'Enter') { this.records() } }} placeholder="Enter email" value={this.state.email} />
                                <InputGroup.Append>
                                    <Button className="btn_search" disabled={this.state.loading} onClick={() => this.records()}>{this.state.loading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" /> : <SearchSharpIcon />}</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            {this.state.hackrecord && this.state.hackrecord.length ? null : <h5>{this.state.isResult ? "No hack record found" : ''}</h5>}
                        </div>
                    </div>

                    <div className="tableContainerWrapInner">
                        <Table responsive>
                            <thead className="bg-light">
                                <tr>
                                    <th>Domain name
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Domain over which your personal data has been compromised </Tooltip>}>
                                            <IoInformation className="tooltip-icon"></IoInformation>
                                        </OverlayTrigger>
                                    </th>
                                    <th>Breach Date
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>Data on which your personal data has been compromised</Tooltip>}>
                                            <IoInformation className="tooltip-icon"></IoInformation>
                                        </OverlayTrigger>

                                    </th>
                                    <th>Description
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip id='top'>A detailed explanation of Breach</Tooltip>}>
                                            <IoInformation className="tooltip-icon"></IoInformation>
                                        </OverlayTrigger>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.hackrecord.map((e, index) => {
                                    return <HackRecordList key={index} hackrecord={e} id={`record${index}`}></HackRecordList>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}