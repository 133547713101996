import React, { Component } from "react";
import swal from 'sweetalert';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import { Button, Badge, Form, Table, Dropdown } from 'react-bootstrap';

import TXTModal from './txt-modal';
import axios from '../../../axios';
import apiError from '../../../error';
import { getDate } from '../../../function';
import Spinner from '../../../component/spinner/spinner';

export default class Domain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: {},
            domains: [],
            domainId: 0,
            domainName: '',
            loading: false,
            txtModal: false,
        }
    }

    componentDidMount = () => {
        this.loadDomain();
    }

    loadDomain = () => {
        axios.get('/api/domain', {})
            .then(res => {
                this.setState({ domains: res.data.domains });
            })
            .catch(err => {
                apiError(err);
            })
    }

    handleCreateDomain = async () => {
        if (this.state.domainName.length === 0) {
            toast.error("Please Enter Domain Name");
            return;
        } else {
            await this.setState({ loading: true })
            axios.post('/api/domain', {
                domainName: this.state.domainName
            })
                .then(res => {
                    this.setState({ loading: false })
                    toast.success("Domain Added Successfully")
                    this.setState({ domainName: '' });
                    this.loadDomain();
                })
                .catch(err => {
                    this.setState({ loading: false })
                    apiError(err);
                })
        }
    }

    handleDeleteDomain = (domainId) => {
        swal({
            title: "Do You Want To Delete This Domain?",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
        })
            .then(willSuccess => {
                if (willSuccess) {
                    axios.delete('/api/domain/' + domainId, {})
                        .then(res => {
                            toast.success("Domain Deleted Successfully");
                            this.loadDomain();
                        })
                        .catch(err => {
                            apiError(err);
                        })
                }
            });

    }

    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Domain</h6>
                    <p>Configuration and management of internet domains associated with the organization. </p>
                </div>

                <div className="col-md-4 pl-0">
                    <div className='profile-form'>
                        <Form>
                            <Form.Group>
                                <Form.Label>Domain Name
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Approved Domain by the user for simulation</Tooltip>}>
                                        <IoInformation className="tooltip-icon"></IoInformation>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="d-flex">
                                    <Form.Control type="text" placeholder="Domain Name" value={this.state.domainName} onChange={e => this.setState({ domainName: e.target.value })} />
                                    <Button variant="success" className="ml-2 w-25" type="button" disabled={this.state.loading} onClick={this.handleCreateDomain}><Spinner loading={this.state.loading}></Spinner>Add</Button>
                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>

                <div className="tableContainerWrapInner">
                    <Table responsive>
                        <thead className="bg-light">
                            <tr>
                                <th>Domain Name</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.domains.map((domain, index) => {
                                return <tr key={'domain-' + index}>
                                    <td>{domain.name}</td>
                                    <td>{(getDate(domain.createdAt))}</td>
                                    <td><Badge pill className={domain.isVerify ? "alert-success" : "badge-soft-warning"}>{domain.isVerify ? "Verified" : "Pending"}</Badge></td>
                                    <td>
                                        <Dropdown
                                            className="action-dropdown dropdownIcon"
                                            ref={this.selectRef}
                                        >
                                            <Dropdown.Toggle
                                                className="btn-only"
                                                id="dropdown-basic"
                                            >
                                                <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    {!domain.isVerify && (
                                                        <span onClick={() => this.setState({ txtModal: true, domain: domain })}>
                                                            Verify
                                                        </span>
                                                    )}
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => this.handleDeleteDomain(domain.id)}>
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>
                <TXTModal
                    txtModal={this.state.txtModal}
                    txtHandle={e => this.setState({ txtModal: false })}
                    domain={this.state.domain}
                    loadDomain={this.loadDomain}
                ></TXTModal>
            </div>
        );
    }
}