import React, { Component } from 'react';
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import Spinner from '../../../component/spinner/spinner';

export default class TemplatesSMS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: '',
            loading: false,
            isUpdate: false,
            prevBody: '',
        }
        this.load();
    }

    load = () => {
        if (this.props.templateId) {
            axios.get('/api/sms/' + this.props.templateId, {})
                .then(res => {
                    this.setState({ body: res.data.sms.html })
                    this.setState({ prevBody: res.data.sms.html })
                })
                .catch(err => {
                    apiError(err)
                })
        }
    }

    handleCreateTemplate = () => {
        const { body } = this.state;
        if (!body || body.length === 0) {
            toast.error('Please enter message body');
            return;
        }
        const data = {
            body: body,
            campaignTemplateId: this.props.campaignTemplateId
        }
        this.setState({ loading: true })
        if (this.props.templateId) {
            axios.patch('/api/sms/' + this.props.templateId, data)
                .then(res => {
                    this.setState({ loading: false })
                    if (this.state.prevBody !== this.state.body) {
                        this.setState({ isUpdate: true });
                    }
                    this.props.handleTabChange('next');
                    if (this.state.isUpdate) {
                        toast.success('SMS template updated successfully')
                        this.setState({ isUpdate: false });
                    }
                    else toast.success('SMS template created successfully')
                })
                .catch(error => {
                    apiError(error);
                    this.setState({ loading: false });
                })
        } else {
            axios.post('/api/sms/', data)
                .then(res => {
                    this.setState({ loading: false });
                    this.props.handleTabChange('next');
                    this.props.handleTemplateId(res.data.template.id);
                    toast.success('SMS template created successfully')
                })
                .catch(error => {
                    apiError(error);
                    this.setState({ loading: false });
                })
        }
    }

    render() {
        return (
            <>
                <div className="email-template-wrap">
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Create SMS Template</h6>
                                <p>Create SMS Template</p>
                            </div>

                        </div>
                    </div>
                    <Form.Group className='sms-input' controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Message Body</Form.Label>
                        <Form.Control size='medium' as="textarea" multiline={true} rows={10} value={this.state.body} onChange={e => this.setState({ body: e.target.value })} />
                    </Form.Group>
                </div>
                <div className="row mt-2">
                    <div className='footer-wrapper justify-content-end d-flex'>
                        <Button className="closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateTemplate}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>
            </>
        )
    }
}