import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";

class CampaignTemplateFilter extends Component {

	render() {
		return (
			<Modal className="info-filter detail-modal mainfooterModal right filterModal" show={this.props.show} onHide={this.props.showHandle} autoFocus keyboard>
				<Modal.Header closeButton className='sidebarSm'>
					<Modal.Title id="example-custom-modal-styling-title">
						<h5 className="mb-0">Filters</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="rightFilters">
						<Form.Group controlId="templatetype" className="mr-2">
							<label>Template Type</label>
							<Form.Control as="select" value={this.props.type} onChange={e => this.props.handleTemplateType(e.target.value)}>
								<option value="All Templates">All Templates</option>
								<option value="My Templates">Custom Templates</option>
								<option value="System Templates">System Templates</option>
								<option value="Community Templates">Community Templates</option>
							</Form.Control>
						</Form.Group>

						<Form.Group controlId="language" className="mr-2">
							<label>Language</label>
							<Form.Control as="select" value={this.props.languageId} onChange={e => this.props.handleLanguage(parseInt(e.target.value, 10))}>
								<option value="0">Select Language</option>
								{this.props.languages.map((lang, index) => {
									return <option key={`language-${index}`} value={lang.id}>{lang.name}</option>
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId="attacktype" className="mr-2">
							<label>Attack Type</label>
							<Form.Control as="select" value={this.props.attackTypeId} onChange={e => this.props.handleAttackType(parseInt(e.target.value, 10))}>
								<option value="0">Select Attack Type</option>
								{this.props.attackTypes.map((attack, index) => {
									return <option key={`attacktype-${index}`} value={attack.id}>{attack.name}</option>
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group className="mr-2">
							<label>Category</label>
							<MultiSelect
								className="multi-select borderRemove"
								clearSearch="Clear Search"
								options={this.props.categories}
								value={this.props.categoryId}
								onChange={this.props.handleCategory}
								overrideStrings={{ selectSomeItems: "Select categories" }}
							/>
						</Form.Group>
					</div>
				</Modal.Body>

				<div className="modalFooter">
					<div className="row ">
						<div className="col-md-12 text-right">
						
							<Button type="button" className="btn closeBtn" onClick={this.props.showHandle}>Cancel</Button>
							<Button className='btn btn-success ml-2' onClick={this.props.showHandle} > Apply</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default CampaignTemplateFilter;
