import QRCode from 'qrcode';
import React, { Component } from "react";
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import './mobile-modal.css';

export default class MobileModal extends Component {
    state = {
        otp: '',
        loading: false
    };

    componentDidMount = () => {
        this.loadDetail();
    };

    loadDetail = () => {
        axios.get('/api/user/getQr')
            .then(res => {
                QRCode.toCanvas(document.getElementById('canvas'), res.data.uri, (error) => {
                    if (error) console.error(error);
                });
            })
            .catch(err => {
                this.apiError(err);
            });
    };

    handleDetail = () => {
        const { otp } = this.state;

        if (!otp) {
            toast.error("Please enter OTP first");
            return;
        }

        axios.post('/api/user/postQr', { otp })
            .then(() => {
                toast.success("You have successfully set up 2FA");
                this.props.handleQrCahnge();
            })
            .catch(err => {
                apiError(err);
            });
    };

    render() {
        const { otp, loading } = this.state;
        const { show, handleClose } = this.props;

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <div>
					<h5>Mobile Authentication</h5>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="page-content" id="tab-wrapper">
                        <div className="authencation_mobile_verification">
                            <div className='authenticationWrap'>
                              <h6 className="auth_ver_mobile">Authentication steps:</h6>
                                <p>1. Install an authenticator app on your mobile device</p>
                                <p>2. Scan the following QR code in your authenticator app</p>

                                <div>
                                    <canvas id="canvas"></canvas>
                                </div>
                            
                                <div className="form-group">
                                    <label>3. Enter the code from your authenticator app below</label>
                                    <input type="text" className="form-control w-30" value={otp} onChange={e => this.setState({ otp: e.target.value })} placeholder="Enter your code" />
                                </div>
                            </div>
                               
                            <Button type="button" className="btn btn-success" onClick={this.handleDetail}>Enable</Button>
                        </div>
                    </div>
                    {loading && <Loader />}
                </Modal.Body>
            </Modal>
        );
    }
}
