import React, {Component} from 'react';
import { toast } from "react-toastify";
import { Modal, Form, Button } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';

export default class DmiModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domainId: this.props.domainId,
            clientId: this.props.config? this.props.config.clientId: '',
            secretId: this.props.config? this.props.config.secretId: '',
            tanentId: this.props.config? this.props.config.tanentId: '',
        }
    }

    updateDMI = () => {
        axios.post('/api/dmi', {
            domainId: this.state.domainId,
            clientId: this.state.clientId,
            secretId: this.state.secretId,
            tanentId: this.state.tanentId,
        })
            .then(res => {
                toast.success('Details Updated Successfully')
                this.props.handleClose();
                this.props.loadDomain();
            })
            .catch(err => {
                apiError(err);
            })
    }

    render() {
        console.log(this.props.config, this.state);
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                      <Modal.Title id="example-custom-modal-styling-title">
                         <h5>Configure DMI</h5>
                         <p>Add your DMI configuration details</p>
                     </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                     <Form>
                        <Form.Group>
                            <Form.Label>Client Id</Form.Label>
                            <Form.Control value={this.state.clientId} onChange={e => this.setState({ clientId: e.target.value })} type="text" placeholder="Enter Client Id" />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Secret Id</Form.Label>
                            <Form.Control value={this.state.secretId} onChange={e => this.setState({ secretId: e.target.value })} type="text" placeholder="Enter Secret Id" />
                        </Form.Group>
                            
                        <Form.Group>
                        <Form.Label>Tenent Id</Form.Label>
                            <Form.Control value={this.state.tanentId} onChange={e => this.setState({ tanentId: e.target.value })} type="text" placeholder="Enter Tenent Id" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn closeBtn' onClick={this.props.handleClose}>Close</Button>
                    <Button className='btn btn-success' onClick={this.updateDMI}>Save</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}