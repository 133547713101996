import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import { IoInformation } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

import axios from "../../../axios";
import apiError from "../../../error";
import Spinner from "../../spinner/spinner";

export default class CampaignName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			languages: [],
		};
		this.loadCategory();
		this.loadLanguage();
	}

	loadCategory = () => {
		axios.get("/api/campaign/template/category", {})
			.then((res) => {
				this.setState({ categories: res.data.categories });
			})
			.catch((err) => apiError(err));
	};

	loadLanguage = () => {
		axios
			.get("/api/campaign/template/language", {})
			.then((res) => {
				this.setState({ languages: res.data.languages });
			})
			.catch((err) => apiError(err));
	};

	render() {
		return (<Form>
			<div className="cmpaigntemplateBox">

				<div className="borderDashed">
					<div className="row ">
						<div className="col-md-4">
							<Form.Group>
								<Form.Label>Template Name<span className="red-star">*</span></Form.Label>
								<Form.Control
									type="text"
									value={this.props.templateName}
									onChange={this.props.handleCampaignName}
									placeholder="Enter Template Name"
								/>
							</Form.Group>
						</div>
						<div className="col-md-4">
							<Form.Group>
								<Form.Label>
									Category<span className="red-star">*</span>
									<OverlayTrigger placement="top" overlay={<Tooltip id="top">These are the categories of attack templates</Tooltip>}>
										<IoInformation className="tooltip-icon"></IoInformation>
									</OverlayTrigger>
								</Form.Label>
								<Form.Control value={this.props.categoryId} onChange={this.props.handleCategoryId} as="select">
									<option value="0">Select Template Category</option>
									{this.state.categories.map((category, index) => {
										return (
											<option key={index} value={category.id}>
												{category.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</div>
						<div className="col-md-4">
							<Form.Group>
								<Form.Label>
									Language<span className="red-star">*</span>
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="top">This will be only for labelling purpose/This will label your attack template</Tooltip>}
									>
										<IoInformation className="tooltip-icon"></IoInformation>
									</OverlayTrigger>
								</Form.Label>
								<Form.Control value={this.props.languageId} onChange={this.props.handleLanguageId} as="select">
									<option value="0">Select Language</option>
									{this.state.languages.map((language, index) => {
										return (
											<option key={index} value={language.id}>
												{language.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</div>
					</div>
				</div>

				<div className="borderDashed">
					<div className="row">
						<div className="col-md-4">
							<Form.Group>
								<Form.Label>
									Type of Simulation<span className="red-star">*</span>
									<OverlayTrigger placement="top" overlay={<Tooltip id="top">These are the categories of simulation</Tooltip>}>
										<IoInformation className="tooltip-icon"></IoInformation>
									</OverlayTrigger>
								</Form.Label>
								<Form.Control
									as="select"
									disabled={this.props.disableState}
									value={JSON.stringify({ id: this.props.simulationTypeId, name: this.props.simulationTypeName })}
									onChange={this.props.handleSimulationType}
								>
									<option value={JSON.stringify({ id: -1, name: "" })}>Select Simulation Type</option>
									{this.props.simulation_type.map((type, index) => {
										return (
											<option key={index} disabled={!type.active} value={JSON.stringify({ id: type.id, name: type.name })}>
												{type.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</div>
						<div className="col-md-4">
							<Form.Group>
								<Form.Label>
									Attack Vector<span className="red-star">*</span>
									<OverlayTrigger placement="top" overlay={<Tooltip id="top">Different type of cyber Attack</Tooltip>}>
										<IoInformation className="tooltip-icon"></IoInformation>
									</OverlayTrigger>
								</Form.Label>
								<Form.Control
									as="select"
									disabled={this.props.disableState}
									value={JSON.stringify({ id: this.props.attackTypeId, name: this.props.attackTypeName })}
									onChange={this.props.handleAttackType}
								>
									<option value={JSON.stringify({ id: -1, name: "" })}>Select Attack Type</option>
									{this.props.attack_type.map((vector, index) => {
										return (
											<option key={index} value={JSON.stringify({ id: vector.attack_type.id, name: vector.attack_type.name })}>
												{vector.attack_type.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</div>
					</div>
				</div>
				{   this.props.attackTypeName &&
					this.props.attackTypeName.length &&
					this.props.attackTypeName !== "Vishing" &&
					this.props.attackTypeName !== "Training + Quiz" &&
					this.props.attackTypeName !== "Ransomware" && 
					this.props.attackTypeName !== "Attachment" ? 
						<div className="col-md-4 pl-0">
							<div className="checkBoxWrap setMarginCss">
								<Form.Group className="mt-3 d-flex" controlId="formBasicCheckbox">
									<Form.Check type="checkbox" label="Credential Harvesting" checked={this.props.credential_harvesting} onChange={this.props.handleHarvesting} />
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="top">Redirection of the user to a landing page luring them to give away their credentials.</Tooltip>}
									>
										<IoInformation
											className="tooltip-icon"
											style={{ position: "absolute", left: "166px !important", bottom: "4px", zIndex: "10" }}
										></IoInformation>
									</OverlayTrigger>

								</Form.Group>
							</div>
						</div>
					: null
				}
			</div>
			<div className="capFooter text-right">
				<Button
					className="btn btn-success ml-2"
					variant="success"
					disabled={this.props.campaignNameLoading || !this.props.showNextTab}
					type="button"
					onClick={this.props.handleCreateCampaignTemplate}
				>
					{this.props.campaignNameLoading? 
						<Spinner loading={true}>{" "}</Spinner>:
						<ArrowForwardOutlinedIcon className="btn-icon"></ArrowForwardOutlinedIcon>
					}
					Go To Next
				</Button>
			</div>
		</Form>
		);
	}
}
