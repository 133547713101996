import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IoInformation } from "react-icons/io5";

export default class ImportSite extends Component{
    
    render(){
        return (
            <Modal show={this.props.show} onHide={() => this.props.handleImportSiteModal()} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title style={{display:"flex" , alignItems:"center"}}> 
                    <h5>Import Site</h5>
                     
                     </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Enter Webpage URL</Form.Label>
                    <Form.Control type="text" value={this.props.importUrl} onChange={this.props.handleContent} placeholder="Please enter url"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleImportSiteModal}>Close</Button>
                    <Button variant="primary" onClick={this.props.handleImportSite}>Import</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}