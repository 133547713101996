import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';
import DoneAllSharpIcon from '@material-ui/icons/DoneAllSharp';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import TestSMSModal from './testwhatsappsms';
import apiError from '../../../error';
import Loader from '../../loader/loader';
import Spinner from '../../spinner/spinner';

export default class AttackProfileWhatsApp extends Component{
    constructor(props){
        super(props);
        this.state = {
            apiEndPoint: '',
            accessToken: '',
            gateways: [],
            gatewayId: 0,
            loading: false,
            gatewayNameIds: {},
            disableState: false,
            phone: '',
            show: false,
        }
        this.load();
    }

    loadGatway = () => {
        axios.get('/api/whatsapp-gateway', {})
        .then(res => {
            this.setState({ gateways: res.data.gateway })
            let gatewayNameIds = {}
            for(var i=0; i<res.data.gateway.length; i++){
                gatewayNameIds[res.data.gateway[i].id] = res.data.gateway[i].name;
            }
            this.setState({ gatewayNameIds: gatewayNameIds })
        })
        .catch(error => apiError(error))
    }

    load = () => {
        if(this.props.senderId){
            axios.get('/api/senders/'+this.props.senderId, {})
            .then(res => {
                if(res.data.sender){
                    this.setState({ 
                        disableState: true,
                        apiEndPoint: res.data.sender.host,
                        accessToken: res.data.sender.username,
                        gatewayId: res.data.sender.whatsAppGatewayId,
                    }, () => {
                        this.loadGatway();
                    })
                }
            })
            .catch(error => apiError(error))
        } else {
            this.loadGatway();
        }
    }

    validateSenderWati = () => {
        if(!this.state.gatewayId || this.state.gatewayId.length === 0){
            toast.error("Please Select SMS Gatway");
            return false;
        }
        if(!this.state.accessToken || this.state.accessToken.length === 0){
            toast.error("Please Enter Access Token");
            return false;
        }
        if(!this.state.apiEndPoint || this.state.apiEndPoint.length === 0){
            toast.error("Please Enter Api End Point");
            return false;
        }
        
        return {
            accessToken: this.state.accessToken,
            gatewayId: this.state.gatewayId,
            apiEndPoint: this.state.apiEndPoint,
            campaignTemplateId: this.props.campaignTemplateId
        }
    }

    handleCreateSender = () => {
        if(this.state.gatewayNameIds[this.state.gatewayId] === "Wati"){
            const data = this.validateSenderWati();
            if(data){
                this.setState({ loading: true })
                if(this.props.senderId){
                    axios.patch('/api/senders/whatsapp/Wati/'+this.props.senderId, data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }else {
                    axios.post('/api/senders/whatsapp/Wati', data )
                    .then(res => {
                        this.setState({ loading: false })
                        this.props.handleTabChange('next');
                        this.props.handleSenderId(res.data.sender.id);
                        toast.success('Attack Profile Created Successfully') 
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
                }
            }
        }
    }

    sendTestSMS = () => {
        const data = this.validateSenderWati();
        if(data){
            if(!this.state.phone || this.state.phone.length === 0){
                toast.error("Please Enter Phone no.");
                return false;
            } else {
                data['phone'] = this.state.phone;
                this.setState({ loading: true });
                axios.post('/api/whatsapp-gateway', data)
                .then(res => {
                    this.setState({ loading: false, show: false });
                    toast.success("SMS Send Successfully.");
                })
                .catch(error => {
                    this.setState({ loading: false })
                    apiError(error)
                })
            }
        }
    }

    render(){
        return (
            <div className="tabContent-block">
                <div className="row justify-content-between d-flex align-items-center mb-3">
                    <div className="col-md-6">
                        <h6>Attack Profile</h6>
                        <p>Create Attack Profile</p>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex float-right">
                            <Button variant="warning" type="button" onClick={e => this.setState({ show: true })}> <DoneAllSharpIcon className="btn-icon mr-2 ml-2"></DoneAllSharpIcon> Send Test SMS </Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h6 className="sub-head">WhatsApp Profile</h6>
                        <Form.Control as="select" disabled={this.state.disableState} value={this.state.gatewayId} onChange={e => this.setState({gatewayId: e.target.value})} className="mt-2 mb-2">
                            <option value='0'>Select WhatsApp Gateway</option>
                            {this.state.gateways.map((gatway, index) => {
                                return <option key={index} value={gatway.id}>{gatway.name}</option>
                            })}
                        </Form.Control>
                        {this.state.gatewayNameIds[this.state.gatewayId] === "Wati"?
                            <div>
                                <Form.Group>
                                    <Form.Label>Access Token</Form.Label>
                                    <Form.Control as="textarea" rows={4} placeholder="please enter access token" value={this.state.accessToken} onChange={e => this.setState({accessToken: e.target.value})} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>API Endpoint</Form.Label>
                                    <Form.Control type="text" placeholder="please enter api token" value={this.state.apiEndPoint} onChange={e => this.setState({apiEndPoint: e.target.value})}/>
                                </Form.Group>
                            </div>: null
                        }
                    </div>
                </div>
                <div className="col-md-12 text-right">
                    <Button variant="secondary" className="mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                    <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateSender}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading?"btn-icon d-none": "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                </div>
                <TestSMSModal
                    show={this.state.show}
                    phone={this.state.phone}
                    loading={this.state.loading}
                    sendTestSMS={this.sendTestSMS}
                    handlePhone={e => this.setState({ phone: e.target.value })}
                    handleSMSModal={e => this.setState({ show: !this.state.show })}
                ></TestSMSModal>
                {this.state.loading? <Loader></Loader>: null}
            </div>
        )
    }
}