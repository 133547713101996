import React, { Component } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

export default class TestSMSModal extends Component{
    
    render(){
        return (
            <Modal show={this.props.show} onHide={this.props.handleEmailModal} autoFocus keyboard>
                <Modal.Header>
                    <Modal.Title> Send Test SMS </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Please Enter Phone Number</Form.Label>
                    <PhoneInput
                        country={'in'}
                        inputProps={{
                            name: 'email',
                            required: true,
                            autoFocus: true
                        }}
                        value={this.props.emails}
                        isValid={(value, country) => {
                            if (value.match(/12345/)) {
                                return 'Invalid value: ' + value + ', ' + country.name;
                            } else if (value.match(/1234/)) {
                                return false;
                            } else {
                                return true;
                            }
                        }}
                        onChange={phone => this.props.handlePhone({target: {value: phone}})}
                    ></PhoneInput>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleSMSModal}>Close</Button>
                    <Button variant="primary" disabled={this.props.loading} onClick={this.props.sendTestSMS}> { this.props.loading? <Spinner as="span" className="mr-2" animation="border" size="sm" role="status" aria-hidden="true" />: null } Send</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}