import React, { Component } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Card, Form, Button } from 'react-bootstrap';
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';

import GroupChart from '../../../component/reports/breach-incidents/groupResponse-chart';
import ResponseChart from '../../../component/reports/breach-incidents/campaignResponse-chart';
import FirstBreachResponse from '../../../component/reports/breach-incidents/first-breach-reponse';
import CategoryReponseChart from '../../../component/reports/breach-incidents/categoryResponse-chart';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';
import { getDateWithoutTime } from '../../../function';
import ReportModal from '../../../component/reports/report-filter/report-filter';

import './breach-incedent.css';
export default class CampaignAnalytics extends Component {
    constructor(props) {
        super(props);
        this.printReport = this.printReport.bind(this);
        this.reportRef = React.createRef();
        this.state = {
            breachTime: 0,
            loading: false,
            attackTypes: [],
            incidentTime: 0,
            attackTypeId: 1,
            firstResponce: {},
            campaignResponce: {},
            categoryResponce: {},
            reportModal: false,

            endDate: new Date(),
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),

            groupResponce: {},

            campaigns: [],
            campaignIds: [],
            selectedCampaignIds: [],

            locations: [],
            location: '',
        }
        this.loadAttackType();
    }

    secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? (h < 10 ? "0" : "") + h + " : " : "00 : ";
        var mDisplay = m > 0 ? (m < 10 ? "0" : "") + m + " : " : "00 : ";
        var sDisplay = s > 0 ? (s < 10 ? "0" : "") + s + "" : "00";
        return hDisplay + mDisplay + sDisplay;
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
            .then(res => {
                if (res.data.attackTypes && res.data.attackTypes.length) {
                    let attacktypeName = {};
                    for (let i = 0; i < res.data.attackTypes.length; i++) {
                        attacktypeName[res.data.attackTypes[i].id] = res.data.attackTypes[i].name
                    }
                    this.setState({
                        attacktypeName: attacktypeName,
                        attackTypes: res.data.attackTypes,
                        attackTypeId: res.data.attackTypes[0].id,
                    }, () => {
                        this.loadCampaigns();
                    });
                }
            })
            .catch(err => apiError(err))
    }

    handleRefresh = () => {
        this.loadLocation();
        this.loadBreachTime();
        this.loadIncidentTime();
        this.groupResponceBreachIncident();
        this.categoryResponceBreachIncident();
        this.campaignResponceBreachIncident();
        this.firstResponceBreachIncident();
    }

    loadBreachTime = () => {
        axios.post('/api/pdfreport/breach', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                this.setState({ breachTime: parseInt(res.data.breachTime, 10) ? parseInt(res.data.breachTime, 10) : 0 })
            })
            .catch(err => apiError(err))
    }

    loadIncidentTime = () => {
        axios.post('/api/pdfreport/incident', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                this.setState({ incidentTime: parseInt(res.data.incidentTime, 10) ? parseInt(res.data.incidentTime, 10) : 0 })
            })
            .catch(err => {
                apiError(err);
                this.setState({ incidentTime: 0 })
            })
    }

    categoryResponceBreachIncident = () => {
        axios.post('/api/pdfreport/categoryResponceBreachIncident', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let categoryResponce = {}, breachTime = [], incidentTime = [], category = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let result = res.data.results[i];
                    category.push(result.campaign_template.category.name);
                    let bt = parseInt(result.breachTime, 10)
                    breachTime.push(bt ? bt : 0);
                    let it = parseInt(result.incidentTime, 10);
                    incidentTime.push(it ? it : 0);
                }
                categoryResponce['category'] = category;
                categoryResponce['breachTime'] = breachTime;
                categoryResponce['incidentTime'] = incidentTime;
                this.setState({ categoryResponce: categoryResponce });
            })
            .catch(err => apiError(err))
    }

    campaignResponceBreachIncident = () => {
        axios.post('/api/pdfreport/campaignResponceBreachIncident', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let campaign = [], campaignResponce = {}, breachTime = [], incidentTime = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let result = res.data.results[i];
                    campaign.push(result.simulation.campaign.name);
                    let bt = parseInt(result.breachTime, 10)
                    breachTime.push(bt ? bt : 0);
                    let it = parseInt(result.incidentTime, 10);
                    incidentTime.push(it ? it : 0);
                }
                campaignResponce['campaign'] = campaign;
                campaignResponce['breachTime'] = breachTime;
                campaignResponce['incidentTime'] = incidentTime;

                this.setState({ campaignResponce: campaignResponce });
            })
            .catch(err => apiError(err))
    }

    groupResponceBreachIncident = () => {
        axios.post('/api/pdfreport/groupResponceBreachIncident', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let groupResponce = {}, breachTime = [], incidentTime = [], group = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let result = res.data.results[i];
                    group.push(result.group.name);
                    let bt = parseInt(result.breachTime, 10)
                    breachTime.push(bt ? bt : 0);
                    let it = parseInt(result.incidentTime, 10);
                    incidentTime.push(it ? it : 0);
                }
                groupResponce['group'] = group;
                groupResponce['breachTime'] = breachTime;
                groupResponce['incidentTime'] = incidentTime;
                this.setState({ groupResponce: groupResponce });
            })
            .catch(err => apiError(err))
    }

    firstResponceBreachIncident = () => {
        axios.post('/api/pdfreport/firstResponceBreachIncident', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let firstResponce = {}, breachTime = [], incidentTime = [], name = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let result = res.data.results[i];

                    name.push(result.simulation.campaign.name);
                    let bt = parseInt(result.breachTime, 10) ? parseInt(result.breachTime, 10) : 0;
                    let it = parseInt(result.incidentTime, 10) ? parseInt(result.incidentTime, 10) : 0;

                    breachTime.push(bt);
                    incidentTime.push(it);
                }
                firstResponce['name'] = name;
                firstResponce['breachTime'] = breachTime;
                firstResponce['incidentTime'] = incidentTime;
                this.setState({ firstResponce: firstResponce });
            })
            .catch(err => apiError(err))
    }

    loadCampaigns = async () => {
        await this.setState({ loading: true })
        axios.get('/api/campaigns/list', {
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                attackTypeId: this.state.attackTypeId
            }
        })
            .then(res => {
                var campaigns = [], selectedCampaignIds = [];
                for (var i = 0; i < res.data.campaigns.length; i++) {
                    campaigns.push({
                        value: res.data.campaigns[i].id,
                        label: res.data.campaigns[i].name
                    })
                    selectedCampaignIds.push(res.data.campaigns[i].id)
                }
                this.setState({
                    loading: false,
                    campaigns: campaigns,
                    campaignIds: campaigns,
                    selectedCampaignIds: selectedCampaignIds,
                }, () => {
                    this.handleRefresh();
                });
            })
            .catch(err => {
                apiError(err);
                this.setState({ campaigns: [], loading: false, selectedCampaignIds: [] })
            })
    }

    loadLocation = async () => {
        axios.post('/api/pdfreport/location', {
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                this.setState({ locations: res.data.results });
            })
            .catch(err => {
                apiError(err);
            })
    }

    handleSelect = (startDate, endDate) => {
        this.setState({
            campaignIds: [],
            endDate: endDate,
            startDate: startDate,
            selectedCampaignIds: [],
        }, () => this.loadCampaigns())
    }

    handleCampaigns = (e) => {
        let selectedCampaignIds = [];
        for (let i = 0; i < e.length; i++) {
            selectedCampaignIds.push(e[i].value)
        }
        this.setState({ selectedCampaignIds: selectedCampaignIds, campaignIds: e })
    }

    downloadReport = async () => {
        const input = document.getElementById("report");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            this.downloadBase64File(imgData, 'report.png');
        })
    }

    downloadBase64File = (base64Data, fileName) => {
        const downloadLink = document.createElement("a");
        const linkSource = base64Data;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    printReport = () => {
        html2canvas(this.reportRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            let imageHeight = this.reportRef.current?.clientHeight;
            let imageWidth = this.reportRef.current?.clientWidth;
            let ratio = imageHeight / imageWidth;

            let orientation = 'portrait';

            if (ratio < 1) {
                orientation = 'landscape';
            }

            const pdf = new jsPDF({
                orientation: orientation,
                unit: 'pt',
                format: [canvas.width + 40, canvas.height + 40],
            });

            pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
            pdf.save(`Breach-Incident.pdf`);
        }).catch(err => {
            apiError(err);
        });
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex  justify-content-between">
                                <h4 className="page-title">Breach Time & Incident Reports</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <div className="row bg-light py-2 align-items-center">
                                <div className="col-md-3">
                                    <h6 className="tab-head mb-0">Breach Time / Incident Reports</h6>
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mr-2 mb-0">
                                            <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({ attackTypeId: parseInt(e.target.value, 10) }, () => this.loadCampaigns())}>
                                                {this.state.attackTypes.map((attacktype, index) => {
                                                    return <option key={index} value={attacktype.id}>{attacktype.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button variant="primary" className="mr-2" onClick={this.printReport}>Download Report</Button>
                                        <Button variant="dark" onClick={() => this.setState({ reportModal: true })}><FilterListSharpIcon className="btn-icon" />Filter</Button>
                                    </div>
                                </div>
                            </div>
                            <div id="report" ref={this.reportRef}>
                                <div className="row graph-row">
                                    <div className="col-md-4 right-border d-flex align-items-center justify-content-center">
                                        <div className="bg-light breach-wrap">
                                            <div>
                                                <h6>Average Breach Time</h6>
                                                <h5>{this.secondsToHms(this.state.breachTime)} Hrs</h5>
                                                <p>{`${getDateWithoutTime(this.state.startDate)} to ${getDateWithoutTime(this.state.endDate)}`}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 right-border d-flex align-items-center justify-content-center">
                                        <div className="bg-light breach-wrap">
                                            <div>
                                                <h6>Incident Report Time</h6>
                                                <h5>{this.secondsToHms(this.state.incidentTime)} Hrs</h5>
                                                <p>{`${getDateWithoutTime(this.state.startDate)} to ${getDateWithoutTime(this.state.endDate)}`}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <h6 className="mb-3">Campaign Breach Chart</h6>
                                        <ResponseChart campaignResponce={this.state.campaignResponce} ></ResponseChart>
                                    </div>
                                </div>
                                <div className="row  m-0">
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Category Response Chart</h6>
                                        <CategoryReponseChart categoryResponce={this.state.categoryResponce} ></CategoryReponseChart>
                                    </div>
                                    <div className="col-md-4 right-border">
                                        <h6 className="mb-3">Group Response Chart</h6>
                                        <GroupChart groupResponce={this.state.groupResponce}></GroupChart>
                                    </div>
                                    <div className="col-md-4">
                                        <h6 className="mb-3">First Breach Response Time</h6>
                                        <FirstBreachResponse firstResponce={this.state.firstResponce}></FirstBreachResponse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <ReportModal
                    /* for date range picker */
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleSelect={this.handleSelect}
                    /* for attack type */
                    attackTypes={this.state.attackTypes}
                    attackTypeId={this.state.attackTypeId}
                    attacktypeName={this.state.attacktypeName}
                    handleAttackType={e => this.setState({ attackTypeId: parseInt(e.target.value, 10) }, () => this.loadCampaigns())}
                    /* for campaigns */
                    campaigns={this.state.campaigns}
                    selectCampaigns={this.state.campaignIds}
                    handleCampaigns={this.handleCampaigns}
                    /* for modal */
                    reportModal={this.state.reportModal}
                    reportHandle={e => this.setState({ reportModal: false })}
                    /* for credencial harvesting */
                    credentialHarvesting={this.state.credentialHarvesting}
                    handleHarvesting={e => this.setState({ credentialHarvesting: e.target.value })}
                    /* for location */
                    location={this.state.location}
                    locations={this.state.locations}
                    handleLocation={e => this.setState({ location: e.target.value })}
                    /* clear filtes */
                    clearFilters={e => this.setState({
                        campaignIds: [],
                        reportModal: false,
                        endDate: new Date(),
                        selectedCampaignIds: [],
                        credentialHarvesting: 'NA',
                        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                    }, () => this.loadCampaigns())}
                    applyFilter={e => this.setState({ reportModal: false }, () => this.handleRefresh())}
                ></ReportModal>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}