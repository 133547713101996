import React, { Component } from "react";
import ApexCharts from 'apexcharts'
export default class TopCategory extends Component {
	constructor(props){
		super(props)
		this.state = {
			chart: null,
		}
	}

	componentDidMount = () => {
		this.load();
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.categorySeries !== prevProps.categorySeries){
			this.state.chart.destroy();
			this.load();
		}
	}

	load = () => {
		let options = {
			series: this.props.categorySeries? this.props.categorySeries: [],
			labels: this.props.categoryLabel? this.props.categoryLabel: [],
			chart: {
				type: 'donut',
				height: 300,
			},
			responsive: [{
				breakpoint: 450,
				options: {
					chart: {
						width: 150
					},
					legend: {
						position: 'bottom'
					}
				}
			}],
			dataLabels: {
				enabled: false
			},
			legend: {
				position: 'bottom',
				horizontalAlign: 'center',
			}
		}
		var chart = new ApexCharts(document.querySelector("#top-category"), options);
		chart.render();
		this.setState({ chart: chart });
	}

	render() {
		return (
			<div id="top-category"></div>
		);
	}
}