import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Switch, Route, Redirect } from 'react-router-dom';

import IAM from './iam';
import EVS from './evs';
import LMS from './lms/lms';
import NoPath from './nopath';
import Reports from './reports';
import Settings from './settings';
import Guide from './guide/guide';
import Campaigns from './campaigns';
import Header from './header/header';
import Department from './department';
import LandingURL from './landing-url';
import Actions from './actions/actions';
import Billing from './billing/billing';
import Emails from './emails/email-list';
import SideBar from './sidebar/side-bar';
import Calendar from './calender/calender';
import Templates from './campaign-template';
import Dashboard from './dashboard/dashboard';
import Logs from './iam/audit-logs/audit-logs';
import HackRecord from './hackrecord/hack-record';
import CreateClient from './create-client/create-client';
import CustomDasdboard from './custom-dashboard/custom-dashboard';

import './index.css';
import axios from '../axios';
import apiError from '../error';
import { getCookie, appType } from '../function';

export default class App extends Component {
	constructor(props){
		super(props);
		this.state = {
			show: true,
			appType: appType(),
			userAgent: getCookie('userAgent'),
			userType: getCookie('userType'),
			isDashboard: localStorage.getItem('isDashboard') || false,
		}
	}

	componentDidMount() {

		var loadScript = function (script) {
			var tag = document.createElement('script');
			tag.async = false;
			var scriptText = document.createTextNode(script)
			tag.appendChild(scriptText);
			var body = document.getElementsByTagName('body')[0];
			body.appendChild(tag);
		}
		if(navigator.onLine && this.state.appType === "SaaS"){
			loadScript(`window.ZohoHCAsap=window.ZohoHCAsap||function(a,b){ZohoHCAsap[a]=b;};(function(){var d=document;var s=d.createElement("script");s.type="text/javascript";s.defer=true;s.src="https://desk.zoho.com/portal/api/web/inapp/219327000005213009?orgId=653592556";d.getElementsByTagName("head")[0].appendChild(s);})();`);
			loadScript(`window.ZohoHCAsapSettings = { ticketsSettings: { preFillFields: { "email": { defaultValue: localStorage['email'] }, "contactId": { defaultValue: localStorage['firstname'] + ' ' + localStorage['lastname'] } } }, }`);
			loadScript(`window._mfq = window._mfq || []; (function() { var mf = document.createElement("script"); mf.type = "text/javascript"; mf.defer = true; mf.src = "//cdn.mouseflow.com/projects/975fb8f2-fe4b-4e37-83f4-5883edfd61f5.js"; document.getElementsByTagName("head")[0].appendChild(mf); })();`);
		}

		var loadLanguageScript = function (src) {
			var tag = document.createElement('script');
			tag.async = false;
			tag.src = src;
			var body = document.getElementsByTagName('body')[0];
			body.appendChild(tag);
		}
		if(navigator.onLine){
			loadLanguageScript(`https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2`);
			loadScript(`function googleTranslateElementInit2() {new google.translate.TranslateElement({pageLanguage: 'en',autoDisplay: false}, 'google_translate_element2');}`);
		}
	}

	nextPath = path => this.props.history.push(path);

	sidebarShow = () => {
		this.setState({show: !this.state.show});
	}

	handleLMS = async() => {
        document.body.style.cursor = "wait";
        axios.get('/api/lms', {})
        .then(res => {
            document.body.style.cursor = "";
			window.open(res.data.url, '_blank');
        })
        .catch(err => {
            apiError(err);
            document.body.style.cursor = "";
        });
	}
	
	handleCourseAnalytics = () => {
		document.body.style.cursor = "wait";
        axios.get('/api/lms', {
			params: {
                url: `/analytic/list`
            }
		})
        .then(res => {
            document.body.style.cursor = "";
            window.open(res.data.url, '_blank')
        })
        .catch(err => {
            apiError(err);
            document.body.style.cursor = "";
        });
	}

	useQuery = () => {
        return new URLSearchParams(window.location.search);
    }

	render() {	
		let activeClass = 'dashboard', activeSubClass = '', isDashboard = getCookie('isDashboard');
		if(this.props.location && this.props.location.pathname && this.props.location.pathname.length > 1){
			activeClass = (this.props.location.pathname).split('/')[1];
			activeSubClass = this.props.location.pathname && (this.props.location.pathname).split('/')[2] ? (this.props.location.pathname).split('/')[2]: this.useQuery().get('tab')? this.useQuery().get('tab'): null;
		}
		return (
			<div className="containerbar">
				<SideBar activeClass={activeClass} show={this.state.show} activeSubClass={activeSubClass} sidebar={this.state.show} sideBarFun={this.sidebarShow} nextPath={ this.nextPath } handleLMS={ this.handleLMS } handleCourseAnalytics={this.handleCourseAnalytics} />
				<div className={this.state['show']? "main-content": "main-content expand"}>
					<Header nextPath={ this.nextPath }/>
					<Switch>
						<Route path={['/', '/dashboard', '/home']} exact component={ isDashboard || this.state.isDashboard? Dashboard: Guide }/>
						<Route path='/campaign' component={ Campaigns }/>
						<Route path='/evs' component={ EVS }/>
						<Route path='/iam' component={ this.state.userType === 'root' ? IAM: NoPath }/>
						<Route path='/lms' component={ LMS }/>
						<Route path='/reports' component={ Reports }/>
						<Route path='/actions' component={ Actions }/>
						<Route path='/audit-logs' component={ Logs }/>
						<Route path='/settings' component={ Settings }/>
						<Route path='/department' component={ Department }/>
						<Route path='/hackrecord' component={ HackRecord }/>
						<Route path='/templates' component={ Templates }/>
						<Route path='/guide' exact component = { Guide } />
						<Route path='/calendar' exact component = { Calendar } />
						<Route path='/billing' exact component = { Billing } />
						<Route path='/landing-url' component = { LandingURL } />
						<Route path='/custom-dashboard' component = { CustomDasdboard } />
						{this.state.appType === "SaaS" && <Route path='/9wHG2RMWfaR0WO/jkasdfnjksdankjsdn' exact component={ this.state.userAgent? Emails: NoPath }/> }
						{this.state.appType === "SaaS" && <Route path='/9LKSJFBSJKLDFO/jasdfasdfjksdkGDFc' exact component={ this.state.userAgent? CreateClient: NoPath }/> }
						
						<Route render={() => (<Redirect to="/invalid" />)}/>
					</Switch>
					<ToastContainer position="top-right" autoClose={5000} />
				</div>
			</div>
		);
	}
}