import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import ChevronRightSharpIcon from "@material-ui/icons/ChevronRightSharp";
import ArrowForwardSharpIcon from "@material-ui/icons/ArrowForwardSharp";
import { FormControl, InputGroup, Card, Form, Accordion, Button } from "react-bootstrap";

import axiosRequest from "axios";
import axios from "../../../axios";
import apiError from "../../../error";
import { getDate } from "../../../function";
import Loader from "../../../component/loader/loader";
import CampaignPreview from "../../../component/create-template/campaign-preview/campaign-preview";
import "./test-result.css";

var cancelRequest = undefined, CancelToken = axiosRequest.CancelToken;
export default class CampaignList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			limit: 10,
			search: "",
			results: [],
			loading: true,
			activePage: 1,
			resultId: null,
			attackTypes: [],
			attackTypeId: 0,
			campaignTemplateId: 0,
			attacktypeName: this.useQuery().get("attackTypeId") ? this.useQuery().get("attackTypeId") : "",

			showPreview: false,
			campaignType: null,
		};
		this.loadAttackType();
	}

	useQuery = () => {
		return new URLSearchParams(window.location.search);
	};

	loadAttackType = () => {
		axios
			.get("/api/attacktypes", {})
			.then((res) => {
				if (res.data.attackTypes && res.data.attackTypes.length) {
					let attackTypeId = res.data.attackTypes[0].id;
					let attacktypeName = res.data.attackTypes[0].name;
					for (let i = 0; i < res.data.attackTypes.length; i++) {
						if (this.state.attacktypeName === res.data.attackTypes[i].name) {
							attackTypeId = res.data.attackTypes[i].id;
							attacktypeName = res.data.attackTypes[i].name;
						}
					}
					this.setState({
						attackTypeId: attackTypeId,
						attacktypeName: attacktypeName,
						attackTypes: res.data.attackTypes,
					},
						() => {
							this.load();
						}
					);
				}
			})
			.catch((err) => apiError(err));
	};

	load = async () => {
		// await this.setState({ loading: true });
		if (cancelRequest) cancelRequest();
		axios
			.get("/api/results/test", {
				params: {
					limit: this.state.limit,
					search: this.state.search,
					page: this.state.activePage,
					attackTypeId: this.state.attackTypeId,
				},
				cancelToken: new CancelToken(function executor(c) {
					cancelRequest = c;
				}),
			})
			.then(async (res) => {
				this.setState({
					loading: false,
					count: res.data.count,
					results: res.data.results,
				});
			})
			.catch((error) => {
				if (!axiosRequest.isCancel(error)) {
					this.setState({ campaigns: [], count: 0, loading: false });
					apiError(error);
				}
			});
	};

	handleActiveResult = (resultId) => {
		if (resultId) {
			if (this.state.resultId === resultId) {
				this.setState({ resultId: null });
			} else {
				this.setState({ resultId: resultId });
			}
		}
	};

	onButtonClick = (eventId) => {
		document.getElementById(eventId).click();
	};

	handleAttackType = (e) => {
		var attackTypes = this.state.attackTypes;
		let attackTypeId = attackTypes[0].id;
		let attacktypeName = attackTypes[0].name;
		for (let i = 0; i < attackTypes.length; i++) {
			if (parseInt(e.target.value, 10) === attackTypes[i].id) {
				attackTypeId = attackTypes[i].id;
				attacktypeName = attackTypes[i].name;
			}
		}
		this.setState({
			attackTypeId,
			attacktypeName
		}, () => {
			this.load();
		});
	};

	render() {
		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-center justify-content-between">
								<h4 className="page-title"><a href="" onClick={(e) => this.props.history.push("/campaign")} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon" /></a>Test Results</h4>
							</div>
						</div>
						<div className="col-md-8"> </div>
					</div>
				</div>
				<div className="filter-wrapper">
					<div className="alignItemsCenter row">
						<div className="col-md-3 p-0">
							<div className="positionSearchIcon">
								<InputGroup className="search-wrap">
									<FormControl type="text" placeholder="Search" value={this.state.search}
										onChange={(e) => this.setState({ search: e.target.value, activePage: 1 }, () => this.load())}
										className="inputSearch mr-sm-2 form-control"
									/>
									<SearchSharpIcon className="search-icon"></SearchSharpIcon>
								</InputGroup>
							</div>
						</div>

						<div className="col-sm-9 p-0">
							<div className="rightFilters">
								<Button onClick={e => this.setState({ loading: true },() => this.load())} className="btn refreshBtn"> <CachedSharpIcon className="btn-icon" />Refresh</Button>
								<Form.Group controlId="exampleForm.ControlSelect1" className="mr-2">
									<Form.Control as="select" value={this.state.attackTypeId} onChange={this.handleAttackType}>
										{this.state.attackTypes.map((attacktype, index) => {
											return <option key={index} value={attacktype.id}> {attacktype.name} </option>
										})}
									</Form.Control>
								</Form.Group>

								<Form.Group
									controlId="exampleForm.ControlSelect1"
									onChange={(e) => this.setState({ limit: parseInt(e.target.value, 10), activePage: 1 }, () => this.load())}
									className="d-inline-block mr-2">
									<Form.Control as="select">
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</Form.Control>
								</Form.Group>
							</div>
						</div>
					</div>
				</div>

				<div className="contentbar campListGrids">
					<div className="page-content" id="test-result">
						<div className="row TableHead mx-0">
							<div className="col-md-1 expandGrid">#</div>
							<div className="col-md-3 ">
								{this.state.attacktypeName === "Phishing" ||
									this.state.attacktypeName === "Ransomware" ||
									this.state.attacktypeName === "Attachment" ||
									this.state.attacktypeName === "QRcode"
									? "Email"
									: "Phone"}
							</div>
							<div className="col-md-3">CreatedAt</div>
							<div className="col-md-2">Status</div>
							<div className="col-md-2">Template</div>
							<div className="col-md-1">Action</div>
						</div>
						<Accordion className="test-result-wrap">
							{this.state.results && this.state.results.length ? (
								this.state.results.map((res, index) => {
									const { result, events } = res;
									return (
										<Card key={index}>
											<Accordion.Toggle
												eventKey={index.toString()}
												className={this.state.resultId === result.id ? "active f-toggle" : "f-toggle"}
												onClick={(e) => this.handleActiveResult(result.id)}
											>
												<div className="row">
													<div className="col-md-1 expandGrid">
														<ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon>
													</div>
													<div className="col-md-3">
														{this.state.attacktypeName === "Phishing" ||
															this.state.attacktypeName === "Ransomware" ||
															this.state.attacktypeName === "Attachment" ||
															this.state.attacktypeName === "QRcode"
															? result.email : result.email.startsWith("+") ? result.email : "+" + result.email}
													</div>
													<div className="col-md-3">{getDate(result.createdAt)}</div>
													{result.status === "Email Sent" ||
														result.status === "SMS Sent" ||
														result.status === "Message Sent" ||
														result.status === "Call Initiated" ||
														result.status === "Sending" ||
														result.status === "Message Sent" ? (
														<div className="col-md-2 ">
															<span className="badge badge-pill badge-soft-primary">{result.status}</span>
														</div>
													) : null}
													{result.status === "Email Opened" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-success">{result.status}</span>
														</div>
													) : null}
													{result.status === "Link Clicked" || result.status === "Call Picked" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-warning">{result.status}</span>
														</div>
													) : null}
													{result.status === "Data Submitted" || result.status === "Error" || result.status === "System Hacked" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-danger">{result.status}</span>
														</div>
													) : null}
													{result.status === "Attachment Opened" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-danger">{result.status}</span>
														</div>
													) : null}
													{result.status === "Email Reported" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-primary">{result.status}</span>
														</div>
													) : null}
													{result.status === "Replied" ? (
														<div className="col-md-2">
															<span className="badge badge-pill badge-soft-success">{result.status}</span>
														</div>
													) : null}
													<div className="col-md-2">
														<Link to={`#`} onClick={() => this.setState({ campaignTemplateId: result.campaignTemplateId, showPreview: true })}> View </Link>
													</div>
													<div className="col-md-1">
														<Link to={`#`}> View </Link>
													</div>
												</div>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={index.toString()} style={{ padding: '10px 55px', marginTop: '-14px' }}>
												<Card.Body>
													<div className="row testAccordian">
														<div className="col-md-12 activity-list">
															<h6 className="pt-3 mb-0 activity-head">Activity Timeline</h6>
															{events && events.length
																? events.map((event, index2) => {
																	var details =
																		event.message === "Error" && event.details ? JSON.parse(JSON.stringify(event.details)) : event.details;
																	if (event.message === "Attachment Opened") {
																		details = JSON.parse(event.details).info
																	}
																	return (
																		<div className="activities-history mt-4 p-0 col-md-10" key={"event-" + index2}>
																			<div
																				className={
																					events.length - 1 === index2 ? "activities-history-list last-child" : "activities-history-list"
																				}
																			>
																				<div className="activities-history-item">
																					<div className="row align-items-center">
																						<h6 className="col-md-3">
																							Activity {index2 + 1} <ArrowForwardSharpIcon className="arrow"></ArrowForwardSharpIcon>
																						</h6>
																						{event.message === "Email Sent" ||
																							event.message === "SMS Sent" ||
																							event.message === "Call Initiated" ||
																							event.message === "Sending" ||
																							event.message === "Sending" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-primary">{event.message}</span>
																							</div>
																						) : null}
																						{event.message === "Email Opened" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-success">{event.message}</span>
																							</div>
																						) : null}
																						{event.message === "Link Clicked" || event.message === "Call Picked" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-warning">{event.message}</span>
																							</div>
																						) : null}
																						{event.message === "Data Submitted" ||
																							event.message === "Error" ||
																							event.message === "System Hacked" ||
																							event.message === "Attachment Opened" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-danger">{event.message}</span>
																							</div>
																						) : null}
																						{event.message === "Email Reported" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-primary">{event.message}</span>
																							</div>
																						) : null}
																						{event.message === "Replied" ? (
																							<div className="col-md-2">
																								<span className="badge badge-pill badge-soft-success">{event.message}</span>
																							</div>
																						) : null}
																						<h6 className="mb-0 col-md-5 d-flex align-items-center">
																							{getDate(event.createdAt)}
																							{details &&
																								Object.keys(details).length &&
																								(event.message === "Error" ||
																									event.message === "Data Submitted" ||
																									event.message === "System Hacked" ||
																									event.message === "Attachment Opened" ||
																									event.message === "Replied") ? (
																								<Button variant="success" className="ml-2" onClick={(e) => this.onButtonClick(event.id)}>
																									View Details
																								</Button>
																							) : null}
																						</h6>
																						{details &&
																							Object.keys(details).length &&
																							(event.message === "Error" ||
																								event.message === "Data Submitted" ||
																								event.message === "System Hacked" ||
																								event.message === "Attachment Opened" ||
																								event.message === "Replied") ? (
																							<div>
																								<Accordion className="accordion2">
																									<Card>
																										<Accordion.Toggle
																											eventKey={index + ":" + index2}
																											className="d-none"
																											id={event.id}
																										></Accordion.Toggle>
																										<Accordion.Collapse eventKey={index + ":" + index2}>
																											<Card.Body>
																												{Object.keys(details).map((detail, index3) => {
																													return detail !== "Error" ? (
																														<div className="row" key={"details-" + index3}>
																															<p className="col-sm-3">{detail}</p>
																															<p className="col-sm-9">{details[detail]}</p>
																														</div>
																													) : null;
																												})}
																											</Card.Body>
																										</Accordion.Collapse>
																									</Card>
																								</Accordion>
																							</div>
																						) : null}
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																})
															: null}
														</div>
													</div>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									);
								})
							) : (
								<h6 className="mt-3 ml-3"> Test result not found. </h6>
							)}
						</Accordion>
					</div>

					<div className="pagination-Wrap">
						{this.state.results && this.state.results.length ? 
							<Pagination
								itemClass="page-item"
								linkClass="page-link"
								activePage={this.state.activePage}
								itemsCountPerPage={this.state.limit}
								totalItemsCount={this.state.count}
								onChange={(pageNumber) => this.setState({ activePage: pageNumber }, () => this.load())}
							/>: null
						}
						{ this.state["loading"] ? <Loader /> : null }
					</div>
				</div>
				{ this.state.showPreview? 
					<CampaignPreview
						load={() => {}}
						fromCampaign={true}
						nextPath={() => {}}
						show={this.state.showPreview}
						campaignType={this.state.campaignType}
						campaignTemplateId={this.state.campaignTemplateId}
						handleModal={() => this.setState({ showPreview: false })}
					></CampaignPreview>: ''
				}
			</div>
		);
	}
}   