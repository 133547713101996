import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import MobileModal from './moblie-modal';
import emailIcon from "../../../assets/emailicon.png";
import smartphone from "../../../assets/smartphone.png";

export default class Password extends Component {
    state = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        disabled: true,
        isEmail2FAEnabled: false,
        isMobile2FAEnabled: false,
        showModal: false
    };

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile = () => {
        this.setState({ loading: true })
        axios.get('/api/user', {})
            .then(res => {
                this.setState({
                    isEmail2FAEnabled: res.data.user.twoFactor && res.data.user.otpType === 'email' ? true : null,
                    isMobile2FAEnabled: res.data.user.twoFactor && res.data.user.otpType === 'app' ? true : null,
                })
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false });
            })
    }

    handlePassword = () => {
        const { oldPassword, newPassword, confirmPassword } = this.state;

        if (!oldPassword) {
            toast.error("Current Password is Required");
            return;
        }
        if (!newPassword) {
            toast.error("New Password is Required");
            return;
        }
        if (newPassword !== confirmPassword) {
            toast.error("Password And Confirm Password Do Not Match");
            return;
        }
        if (newPassword === oldPassword) {
            toast.error("New Password Can't Be The Same As Current Password");
            return;
        }

        axios.patch('/api/auth/changePassword', {
            password: oldPassword,
            newPassword,
            confirmPassword
        })
            .then(() => {
                this.setState({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    disabled: true
                });
                toast.success("Password Updated Successfully");
                window.location = '/logout';
            })
            .catch(err => {
                apiError(err);
            });
    };

    handleEmail2FA = (event) => {
        const isEmail2FAEnabled = event.target.checked;

        this.setState({ isEmail2FAEnabled, isMobile2FAEnabled: false });

        axios.patch('/api/user/two-fa', { twoFactor: isEmail2FAEnabled })
            .then(() => {
                toast.success(`Two Factor ${isEmail2FAEnabled ? 'Enabled' : 'Disabled'} Successfully`);
                this.setState({ showModal: false });
            })
            .catch(err => {
                apiError(err);
            });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, isMobile2FAEnabled: false });
    };

    handleMobileModal = (e) => {
        if(e.target.checked) this.setState({ showModal: true });
        else {
            axios.patch('/api/user/patchQr', { })
            .then(() => {
                toast.success("You have successfully Disabled 2FA");
                this.setState({ isEmail2FAEnabled: false, isMobile2FAEnabled: false });
            })
            .catch(err => {
                apiError(err);
            });
        }
    };

    handleInputChange = (field, value) => {
        this.setState({ [field]: value, disabled: false });
    };

    handleQrCahnge = () => {
        this.setState({ showModal: false, isEmail2FAEnabled: false, isMobile2FAEnabled: true });
    }

    render() {
        const { oldPassword, newPassword, confirmPassword, disabled, showModal } = this.state;

        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>Change Password</h6>
                    <p>Functionality for users to update their account password for enhanced security.</p>
                </div>

                <div className="noteSection">
                    <p><b>Note:</b> Password has to be between 8 to 15 characters which contain at least one numeric digit, one uppercase, one lowercase letter and one special character($@!%*#?&)</p>
                </div>

                <div className="col-md-4 pl-0">
                    <div className='profile-form'>
                        <Form>
                            <Form.Group>
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control type="password" placeholder="Current Password" value={oldPassword} onChange={e => this.handleInputChange('oldPassword', e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" placeholder="New Password" value={newPassword} onChange={e => this.handleInputChange('newPassword', e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={e => this.handleInputChange('confirmPassword', e.target.value)} />
                            </Form.Group>
                            <Button variant="success" type="button" className="btn btn-success mr-2" disabled={disabled} onClick={this.handlePassword}>Update</Button>
                        </Form>
                    </div>
                </div>

                <div className='profile-form'>
                    <div className="tabHeading">
                        <h6>Two Factor Authentication Status</h6>
                        <p>2FA enhances account security by preventing unauthorized access.</p>
                    </div>
                    <div className='profile-form'>
                        <div className="col-md-7 pl-0 mt-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="authenticCard">
                                        <div className="dFlex">
                                            <div className="leftIcon">
                                                <img src={emailIcon} alt="template" className="img-fluid" />
                                            </div>
                                            <div className="rightContent">
                                                <h6>Email Authentication
                                                    <span className={`switchBages ${this.state.isEmail2FAEnabled ? 'enableBages' : 'disable'}`}>
                                                        {this.state.isEmail2FAEnabled ? 'Enabled' : 'Disabled'}
                                                    </span>
                                                </h6>
                                                <p>Enable 2FA: Users receive email code for secure sign-in</p>
                                            </div>
                                        </div>
                                        <div className="enableToggle">
                                            <div className="toggleBtn">
                                                <Form.Switch type="switch" id="custom-switch22" label="Enable" value={this.state.isEmail2FAEnabled} checked={this.state.isEmail2FAEnabled} onChange={this.handleEmail2FA} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="authenticCard">
                                        <div className="dFlex">
                                            <div className="leftIcon">
                                                <img src={smartphone} alt="template" className="img-fluid"></img>
                                            </div>
                                            <div className="rightContent">
                                                <h6>
                                                    Mobile Authentication
                                                    <span className={`switchBages ${this.state.isMobile2FAEnabled ? 'enableBages' : 'disable'}`}>
                                                        {this.state.isMobile2FAEnabled ? 'Enabled' : 'Disabled'}
                                                    </span>
                                                </h6>
                                                <p>Enable 2FA: Users receive SMS codes for secure sign-in
                                                </p>
                                            </div>
                                        </div>
                                        <div className="enableToggle">
                                            <div className="toggleBtn">
                                                <Form.Switch type="switch" id="custom-switch21" label='Enable' value={this.state.isMobile2FAEnabled} checked={this.state.isMobile2FAEnabled} onChange={this.handleMobileModal} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <MobileModal    
                        show={showModal} 
                        handleClose={this.handleCloseModal}
                        handleQrCahnge={this.handleQrCahnge}
                    />
                )}
            </div>
        );
    }
}
