import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';

import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../loader/loader';

export default class IMAP extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            host: '',
            port: 993,
            loading: false,
            disabled: true,
        }
    }

    componentDidMount = () => {
        this.loadIMAP();
    }
    
    validate = () => {
        if(!this.state.host.length){
            toast.error('Please enter Host');
            return null
        } 
        if(!this.state.port){
            toast.error('Please enter Port');
            return null
        } 
        if(!this.state.username.length){
            toast.error('Please enter Username');
            return null
        } 
        if(!this.state.password.length){
            toast.error('Please enter Password');
            return null
        } 
        return true;
    }

    loadIMAP = () => {
        axios.get('/api/setting/imap')
        .then(res => {
            this.setState({
                host: res.data.host,
                port: res.data.port,
                username: res.data.username
            });
        })
        .catch(err => {
            apiError(err);
        })
    }


    handleImap = () => {
        var data = this.validate();
        if(data){
            this.setState({ loading: true })
            axios.post('/api/setting/imap', {
                port: this.state.port,
                host: this.state.host,
                username: this.state.username,
                password: this.state.password.length === 0 ? null: this.state.password
            })
            .then(res => {
                this.setState({ loading: false })
                toast.success("IMAP Settings updated successfully");
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false })
            })
        }
    }


    render() {
        return (
            <div className="tab-wrap">
                 <div className='tabHeading'>
                    <h6>IMAP</h6>
                    <p>Settings for Internet Message Access Protocol, allowing email retrieval and storage from mail servers.</p>
                </div> 

                    <div className="col-md-4 pl-0">
                        <div className='profile-form'>
                            <Form>   
                                <Form.Group>
                                    <Form.Label>Host</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Host" 
                                        value={this.state.host} 
                                        onChange={e => this.setState({host: e.target.value, disabled: false})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Port</Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        placeholder="Port"
                                        value={this.state.port} 
                                        onChange={e => this.setState({port: e.target.value, disabled: false})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>UserName</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Username"
                                        value={this.state.username} 
                                        onChange={e => this.setState({username: e.target.value, disabled: false})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password"
                                        placeholder="Password"
                                        value={this.state.password} 
                                        onChange={e => this.setState({password: e.target.value, disabled: false})}
                                    />
                                </Form.Group>
                                <Button variant="success" type="button" onClick={this.handleImap} disabled={this.state.disabled} >Check Imap Connection & Save</Button>         
                            </Form>
                        </div>
                    </div>
               
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}