import React, { Component } from "react";
import swal from "sweetalert";
import ModalVideo from "react-modal-video";
import Tooltip from "@material-ui/core/Tooltip";
import LayersIcon from "@material-ui/icons/Layers";
import { Navbar, Button, Dropdown } from "react-bootstrap";
import { TfiHelp, TfiBook, TfiSettings } from "react-icons/tfi";
import { PiMegaphone} from "react-icons/pi";
import AccessibilityNewSharpIcon from "@material-ui/icons/AccessibilityNewSharp";
import PowerSettingsNewSharpIcon from "@material-ui/icons/PowerSettingsNewSharp";
import SupervisorAccountSharpIcon from "@material-ui/icons/SupervisorAccountSharp";

import axios from '../../axios';
import apiError from '../../error';
import { getCookie } from "../../function";
import HeaderRightImg from "../../assets/logoright.png";
import Language from "../../component/language/language";
import LaunchModal from "../../component/launch-modal/launch-modal";
import SupportModal from "../../component/support-modal/support-modal";

import "./header.css";
import "react-modal-video/css/modal-video.min.css";

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoId: 0,
			isOpen: false,
			showModal: false,
			supportModal: false,
			videoIds: ["BzJHCCEY6FA"],
			userType: getCookie("userType"),
			billingType: getCookie("billingType"),
		}
	}

	openModal = (videoId) => {
		this.setState({ isOpen: true, videoId: videoId });
	};

	showHandle = () => {
		this.setState({ showModal: !this.state.showModal });
	};
	supportHandle = () => {
		this.setState({ supportModal: !this.state.supportModal });
	};

	handleLogout = () => {
		swal({
			title: "Are you sure you want to logout?",
			icon: "info",
			buttons: ["Not Now!", "Yes"],
		})
			.then((willSuccess) => {
				if (willSuccess) {
					this.props.nextPath("/logout");
				}
			});
	};

	handlePartner = () => {
		window.open("https://threatcop.com/partnership-overview");
	};

	handleChangeLog = () => {
		window.open("/change-log");
	};

	handleLMS = async () => {
		document.body.style.cursor = "wait";
		axios.get('/api/lms', {})
			.then(res => {
				document.body.style.cursor = "";
				window.open(res.data.url, '_blank');
			})
			.catch(err => {
				apiError(err);
				document.body.style.cursor = "";
			});
	}

	render() {
		return (
			<div>
				<Navbar className="bg-light justify-content-between header" fixed="top">
					<Button
						variant="primary"
						onClick={() =>
							this.setState({ showModal: !this.state.showModal })
						}
					>
						<PiMegaphone className="btn-icon"></PiMegaphone>
						<span className="btn-text">Create Campaign</span>
					</Button>
					<div
						className={
							getCookie("logo")
								? "d-flex align-items-center remove-translate"
								: "d-flex align-items-center remove-translate header1"
						}
					>
						<div className="MidLanguage">
							<Language></Language>
						</div>
						<div className="Midinfobar">
							<Tooltip title="Guide" placement="bottom">
								<Button
									className="round-btn"
									onClick={() => this.props.nextPath("/product-guide")}
								>
									<TfiBook></TfiBook>
								</Button>
							</Tooltip>
						</div>
						<div className="Midinfobar">
							<Tooltip title="Help" placement="bottom">
								<Button
									className="round-btn"
									onClick={() =>
										this.setState({ supportModal: !this.state.supportModal })
									}
								>
									<TfiHelp></TfiHelp>
								</Button>
							</Tooltip>
						</div>
						<div className="Midinfobar">
							<Tooltip title="Settings" placement="bottom">
								<Button
									className="round-btn"
									onClick={() => this.props.nextPath("/settings")}>
									<TfiSettings></TfiSettings>
								</Button>
							</Tooltip>
						</div>

						<Dropdown className="user-dropdown LmsRightBtn d-inline-block">
							<Tooltip title="Switch Product" placement="bottom">
								<Dropdown.Toggle id="dropdown-basic" className="comp-dropdown">
									<img
										src={HeaderRightImg}
										alt=""
										className="leftcompImg"
									></img>
									<span className="LmsLogobtnCont">TSAT</span>
								</Dropdown.Toggle>
							</Tooltip>

							<Dropdown.Menu className="RightLogin">
								<Dropdown.Header>Switch Product</Dropdown.Header>
								<Dropdown.Divider />

								<Dropdown.Item onClick={this.handleLMS}>
									<img src={HeaderRightImg} className="btn-icon" alt="abc"></img>TLMS
								</Dropdown.Item>

								<Dropdown.Divider />
							</Dropdown.Menu>
						</Dropdown>

						<Dropdown className="user-dropdown d-inline-block">
							<Dropdown.Toggle id="dropdown-basic" className="comp-dropdown">
								{getCookie("logo") ? (
									<>
										<img
											src={getCookie("logo")}
											alt=""
											className="comp-logo"
										></img>



										<div className="user-icon">
											<span className="mx-auto">
												{getCookie("firstname")[0]}
											</span>
										</div>
									</>
								) : (
									<div className="user-icon">
										<span className="mx-auto">
											{getCookie("firstname") ? getCookie("firstname")[0] : " "}
										</span>
									</div>
								)}
							</Dropdown.Toggle>
							<Dropdown.Menu className="RightLogin">
								<Tooltip title={getCookie("firstname")} placement="bottom">
									<Dropdown.Header>Hi, {getCookie("firstname").substring(0, 10)}</Dropdown.Header>
								</Tooltip>
								<Dropdown.Divider />
								{this.state.billingType === "Threatcop" &&
									this.state.userType === "root" ? (
									<>
										<Dropdown.Divider />
										<Dropdown.Item
											onClick={() => this.props.nextPath("/iam/users")}
										>
											<AccessibilityNewSharpIcon className="btn-icon"></AccessibilityNewSharpIcon>
											IAM
										</Dropdown.Item>
									</>
								) : null}
								<Dropdown.Divider />
								<Dropdown.Item onClick={this.handlePartner}>
									<SupervisorAccountSharpIcon className="btn-icon"></SupervisorAccountSharpIcon>
									Partner with us
								</Dropdown.Item>
								<Dropdown.Divider />

								<Dropdown.Item
									onClick={() => this.props.nextPath("/audit-logs")}
								>
									<LayersIcon className="btn-icon"></LayersIcon>Audit Logs
								</Dropdown.Item>
								<Dropdown.Divider />

								<Dropdown.Item onClick={this.handleChangeLog}>
									<LayersIcon className="btn-icon"></LayersIcon>Change Logs
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item onClick={this.handleLogout}>
									<PowerSettingsNewSharpIcon className="btn-icon"></PowerSettingsNewSharpIcon>
									Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Navbar>
				<LaunchModal
					showModal={this.state.showModal}
					showHandle={this.showHandle}
					nextPath={this.props.nextPath}
				></LaunchModal>
				<SupportModal
					supportModal={this.state.supportModal}
					supportHandle={this.supportHandle}
					openModal={this.openModal}
				></SupportModal>
				<React.Fragment>
					<ModalVideo
						channel="youtube"
						isOpen={this.state.isOpen}
						videoId={this.state.videoIds[this.state.videoId]}
						onClose={() => this.setState({ isOpen: false })}
					/>
				</React.Fragment>
			</div>
		);
	}
}
