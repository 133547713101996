import React, { Component } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Modal, Form, Button } from 'react-bootstrap';

export default class SubDomain extends Component {
    render() {
        const landingUrl = this.props.landingUrl;
        return (
            <Modal show={this.props.showModal} onHide={this.props.hideModal} className="commonModal">
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <h5>Add Subdomain</h5>
                        <p>Add your subdomain</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6'>
                            <Form.Label>Subdomain</Form.Label>
                            <Form.Control type="text" placeholder="Enter Subdomain" value={this.props.subDomain} onChange={this.props.handleSubDomain} />
                        </div>
                        <div className='col-md-6'>
                            <Form.Label>Domain</Form.Label>
                            <Form.Group controlId="exampleForm.ControlSelect1" onChange={e => this.props.handleLandingUrlId(e.target.value)}>
                                <Form.Control as="select">
                                    <option value={0}>Select Domain</option>
                                    {landingUrl.map((url, index) => {
                                        return <option key={`options-${index}`} value={url.id}>{url.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn closeBtn' onClick={this.props.hideModal}>Close</Button>
                    <Button className='btn btn-success' onClick={this.props.handleSubmit}>Create</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}