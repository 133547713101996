import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";

export default class ImportEmail extends Component{
    
    render(){
        return (
            <Modal size='lg' show={this.props.show} onHide={() => this.props.handleImportEmailModal()} autoFocus keyboard>

                <Modal.Header closeButton>
						<Modal.Title id="example-custom-modal-styling-title">
							<h5>Import Email</h5>
						</Modal.Title>
					</Modal.Header>
                <Modal.Body>
                    <Form.Label>Original Email Content
                    <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='top'>Import original email content from an existing email, including the links and the attachments</Tooltip>}>
                            <IoInformation className="tooltip-icon"></IoInformation>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control as="textarea" rows={6} value={this.props.emailContent} onChange={this.props.handleContent} placeholder="Paste Original Email Content Here" />
                    <Form.Group controlId="formBasicCheckbox1" className="mt-3 d-flex checkBoxWrap">
                        <Form.Check type="checkbox" defaultChecked={this.props.changeUrls} onChange={this.props.handleChangeUrl} label="Change Links to Point to Landing Page"/>
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip id='top'>Users will be redirected to your personalized landing page if the links are clicked</Tooltip>}>
                            <IoInformation className="tooltip-icon mt-1" ></IoInformation>
                        </OverlayTrigger>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleImportEmailModal}>Close</Button>
                    <Button variant="primary" onClick={this.props.handleImportEmail}>Save</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}