import React, { Component } from 'react';
import { toast } from "react-toastify";
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import { IoInformation } from "react-icons/io5";
import Accordion from 'react-bootstrap/Accordion';
import { Button, Form, Table } from 'react-bootstrap';
import AddSharpIcon from "@material-ui/icons/AddSharp";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { TfiSettings } from "react-icons/tfi";
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import axios from '../../../axios';
import apiError from '../../../error';
import TestEmailModal from './testemail';
import { getCookie } from '../../../function';
import Spinner from '../../../component/spinner/spinner';

export default class AttackProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            host: '',
            data: {},
            email: '',
            show: false,
            username: '',
            loading: false,
            isLoaded: false,
            password: '*****',
            allDisabled: false,
            billingType: getCookie('billingType'),

            domains: [],
            senderName: '',
            domainName: '',
            senderEmailName: '',

            fakeCC: [],
            fakeCCEmail: '',

            headerKey: '',
            headerValue: '',
            headers: [],
            isDMI: false
        }
    }

    componentDidMount = () => {
        this.load();
        this.loadDomain();
        this.loadDMISetting();
    }

    load = () => {
        if (this.props.senderId && this.props.senderId > 0) {
            axios.get('/api/senders/' + this.props.senderId, {})
                .then(res => {
                    let domainName = '', senderEmailName = '', senderName = '', fakeCC = [], headers = [];
                    try {
                        if (res.data.sender.from_address && res.data.sender.from_address.split("@")[1]) {
                            senderEmailName = res.data.sender.from_address.split("@")[0];
                            domainName = res.data.sender.from_address.split("@")[1];
                            if (domainName.indexOf('>') > -1) {
                                domainName = domainName.split('>')[0];
                            }
                        }
                        if (senderEmailName && senderEmailName.length && (senderEmailName).indexOf('<') > -1) {
                            senderName = senderEmailName.split("<")[0];
                            senderEmailName = senderEmailName.split("<")[1]
                        }
                        if (res.data.sender && res.data.sender.fake_ccs) {
                            for (let i = 0; i < res.data.sender.fake_ccs.length; i++) {
                                fakeCC.push(res.data.sender.fake_ccs[i].email)
                            }
                        }
                        if (res.data.sender && res.data.sender.headers) {
                            for (let i = 0; i < res.data.sender.headers.length; i++) {
                                headers.push({
                                    key: res.data.sender.headers[i].key,
                                    value: res.data.sender.headers[i].value
                                })
                            }
                        }
                    } catch (err) { }
                    this.setState({
                        fakeCC, headers, senderName, domainName,
                        host: res.data.sender.host,
                        senderEmailName: senderEmailName,
                        username: res.data.sender.username ? res.data.sender.username : ''
                    })
                    this.loadDomain()
                })
                .catch(error => apiError(error))
        } else {
            this.loadDomain();
        }
    }

    validateSenderCustom = async () => {
        if (this.state.senderEmailName.length === 0) {
            toast.error("Please Enter Sender Name");
            return null;
        }
        if (this.state.domainName.length === 0) {
            toast.error("Please Select Domain");
            return null;
        }
        if (!this.state.host || this.state.host.length === 0 || (this.state.host).trim().length === 0) {
            toast.error("Please Enter SMTP Host");
            return null;
        }
        var from_address = `${this.state.senderEmailName}@${this.state.domainName}`

        if (this.state.senderName.length > 0) {
            from_address = `${this.state.senderName}<${from_address}>`
        }
        const data = {
            host: this.state.host,
            fakeCC: this.state.fakeCC,
            headers: this.state.headers,
            from_address: from_address,
            campaignTemplateId: this.props.campaignTemplateId
        }
        if (this.state.password !== "*****") {
            data['password'] = this.state.password;
            data['username'] = this.state.username;
            if (!this.state.username || this.state.username.length === 0) {
                toast.error("Please Enter UserName");
                return null;
            }
        }
        return data;
    }

    validateSenderAutomatic = () => {
        if (this.state.senderEmailName.length === 0) {
            toast.error("Please Enter Sender Name");
            return null;
        }
        if (this.state.domainName.length === 0) {
            toast.error("Please Select Domain");
            return null;
        }
        var from_address = `${this.state.senderEmailName}@${this.state.domainName}`

        if (this.state.senderName.length > 0) {
            from_address = `${this.state.senderName}<${from_address}>`
        }
        const data = {
            fakeCC: this.state.fakeCC,
            from_address: from_address,
            headers: this.state.headers,
            campaignTemplateId: this.props.campaignTemplateId
        }
        return data;
    }

    handleCreateSender = async () => {
        if (this.props.senderType === 'custom') {
            let data = await this.validateSenderCustom();
            if (data) {
                this.setState({ loading: true })
                if (this.props.senderId) {
                    data['username'] = this.state.username;
                    axios.patch('/api/senders/custom/' + this.props.senderId, data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                } else {
                    axios.post('/api/senders/custom/', data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                            this.props.handleSenderId(res.data.sender.id);
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                }
            }
        } else {
            let data = this.validateSenderAutomatic()
            if (data) {
                this.setState({ loading: true })
                if (this.props.senderId) {
                    axios.patch('/api/senders/automatic/' + this.props.senderId, data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                } else {
                    axios.post('/api/senders/automatic/', data)
                        .then(res => {
                            this.setState({ loading: false })
                            this.props.handleTabChange('next');
                            this.props.handleSenderId(res.data.sender.id);
                        })
                        .catch(error => {
                            this.setState({ loading: false })
                            apiError(error)
                        })
                }
            }
        }
    }

    sendTestMail = async () => {
        if (this.state.email.length === 0) {
            toast.error("Please Enter Email Id");
            return;
        }
        if (this.props.senderType === 'custom') {
            let data = await this.validateSenderCustom();
            if (data) {
                data['email'] = this.state.email;
                data['senderId'] = this.state.senderId;
                data['username'] = this.state.username;
                this.setState({ loading: true })
                axios.post('/api/senders/custom/test', data)
                    .then(res => {
                        this.setState({ show: false, loading: false })
                        toast.success('Test Email Send Successfully')
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
            }
        } else {
            let data = this.validateSenderAutomatic();
            if (data) {
                data['email'] = this.state.email;
                data['senderId'] = this.state.senderId;
                this.setState({ loading: true })
                axios.post('/api/senders/automatic/test', data)
                    .then(res => {
                        this.setState({ show: false, loading: false })
                        toast.success('Test Email Send Successfully')
                    })
                    .catch(error => {
                        this.setState({ loading: false })
                        apiError(error)
                    })
            }
        }
    }

    loadDomain = () => {
        axios.get('/api/pages/url', {})
            .then(res => {
                let domains = [], landing_urls = res.data.landing_urls;
                for (let i = 0; i < landing_urls.length; i++) {
                    if (!(landing_urls[i].name).startsWith('http')) {
                        domains.push(landing_urls[i].name)
                    }
                }
                if (domains.indexOf(this.state.domainName) === -1) {
                    domains.push(this.state.domainName);
                }
                this.setState({ domains: domains })
            })
            .catch(error => {
                apiError(error)
            })
    }

    haldleFakeEmail = () => {
        const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i);
        if (validEmailRegex.test(this.state.fakeCCEmail)) {
            let fakeCC = this.state.fakeCC;
            let fakeCCEmail = this.state.fakeCCEmail;
            let index = fakeCC.length === 0 ? -1 : fakeCC.indexOf(fakeCCEmail);
            if (index === -1) {
                fakeCC.push(fakeCCEmail)
                this.setState({ fakeCCEmail: '', fakeCC });
            } else {
                toast.warn('Same Email Id Already Added')
            }
        } else {
            toast.error('Please Enter Valid Email Id')
        }
    }

    deleteFakeCCEmail = (email) => {
        let fakeCC = this.state.fakeCC;
        const index = fakeCC.indexOf(email);
        if (index > -1) fakeCC.splice(index, 1);
        this.setState({ fakeCC });
    }

    haldleHeader = () => {
        let key = this.state.headerKey, value = this.state.headerValue;
        if (key.length === 0) {
            toast.error('Please Enter Header Key')
            return;
        }
        if (value.length === 0) {
            toast.error('Please Enter Header Value')
            return;
        }
        let headers = this.state.headers;
        headers.push({
            key, value
        })
        this.setState({ headers, headerKey: '', headerValue: '' })
    }

    deleteHeader = (key) => {
        let headers = this.state.headers, index = -1;
        for (let i = 0; i < headers.length; i++) {
            if (headers[i].key === key) {
                index = i;
                break;
            }
        }
        if (index !== -1) {
            headers.splice(index, 1);
            this.setState({ headers })
        }
    }

    loadDMISetting = () => {
        axios.get('/api/company/dmi', {})
            .then(res => {
                this.setState({
                    isDMI: res.data.isDMI
                });
            })
            .catch(err => {
                apiError(err);
            })
    }

    render() {
        return (
            <>
                <div className="tabContent-block email-template-wrap">
                    <div className='rightSectionHeader'>
                        <div className="row justify-content-between d-flex align-items-center">
                            <div className="col-md-6 tab-right-header">
                                <h6>Attacker Profile</h6>
                                <p>Create attacker profile</p>
                            </div>
                            <div className="col-md-6 text-right">
                                <div className="d-flex float-right">
                                    <Button variant="primary" type="button" onClick={e => this.setState({ show: true })}> <AddSharpIcon className="btn-icon mr-2 ml-2"></AddSharpIcon> Send Test Email </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='campListGrids attackerAccordion'>
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" className=" tableGrid">
                                    <div className="row align-items-center ">
                                        <div className="col-md-1 expandGrid mr-2"><ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon></div>
                                        Whitelist Our IPs for Phishing Simulations
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row align-items-center ">
                                            <div className="warningCard">
                                                <h5><b>Note:</b> Please whitelist our IPs below to ensure our phishing simulations and training emails reach your inbox instead of being blocked or marked as spam. For more information <a variant="link" href="https://helpdesk.threatcop.com/portal/en/kb/articles/do-i-need-to-whitelist-the-sender-id" target="_blank" rel="noopener noreferrer">Read More </a></h5>
                                                <p> (SMTP IP ) 168.245.74.19 ,  (Landing Page IP) 13.234.117.252</p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1" className=" tableGrid">
                                    <div className="row align-items-center ">

                                        <div className="col-md-1 expandGrid mr-2">
                                            <ChevronRightSharpIcon className="right-arrow"></ChevronRightSharpIcon>
                                        </div>

                                        <div className='col-md-3 pl-0'>
                                            Mail Delivery Configuration
                                        </div>

                                        <div className='col-md-8 d-flex justify-content-end pr-0'>
                                            <div className='dFlex'>
                                                <Form.Check type="radio" label="DMI" name="powerOption" checked={this.state.isDMI} />
                                                <Form.Check type="radio" label="SMTP" name="powerOption" checked={!this.state.isDMI} />

                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row align-items-center ">
                                            <div className="warningCard">
                                                <h5><b>Note:</b> Mail Delivery Configuration is necessary to define how emails
                                                    are delivered to users' inboxes. This setup ensures that emails are properly
                                                    routed and reach the intended recipients efficiently.</h5>
                                                <p><b>Current Setting :</b> Your current configuration is set to {this.state.isDMI? 'Direct Mail Injection (DMI)': 'Simple Mail Transfer Protocol (SMTP)'}.</p>

                                                <ul className='maildeliveryList mt-3'>
                                                    <li><b>1. If set to DMI:</b> Emails will be delivered directly to recipients' inboxes without passing through standard email servers.</li>
                                                    <li><b>2. If set to SMTP:</b> This method is mainly used for sending emails from a mail client (such as Outlook or Gmail) to a mail server.</li>
                                                    <li><b>3. Default Method:</b> If 'DMI Only' is selected, emails will be sent exclusively via the DMI method. If only 'DMI' is enabled, emails will first try the DMI method, and if not configured, they will use the backup method, SMTP."</li>
                                                </ul>
                                                <p className='mt-3 text-decoration-underline'>
                                                    <Link to="/settings/?tab=dmi"><TfiSettings /> Go to Settings</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                    <div className="attackCard">
                        <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Sender Display Name</h5>
                                <p className='lead'><span className='red-star'>*</span> This name will show in the inbox; if empty, the sender's email will display instead.</p>
                            </div>

                            <div className='row'>
                                <div className='col-sm-5'>
                                    <Form.Group controlId="from" className='mb-0'>
                                        <Form.Label className='form-label'>Sender Name </Form.Label>
                                        <Form.Control className='widthSet' type="text" placeholder="eg: your organisation name" value={this.state.senderName} onChange={e => this.setState({ senderName: e.target.value })} />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="attackCard">
                        <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Sender Domain</h5>
                                <p className='lead'><span className='red-star'>*</span> Email address of the sender visible to recipients. Eg: xyz@domain.com</p>
                            </div>
                            <div className="domainWrap mb-3">
                                <Form.Group className='mb-0'>
                                    <Form.Label className='form-label'>Enter Sender Name </Form.Label>
                                    <Form.Control type="text" placeholder="eg: username" value={this.state.senderEmailName} onChange={e => this.setState({ senderEmailName: e.target.value })} />
                                </Form.Group>
                                <p style={{ margin: '34px 10px 10px 10px' }}>@</p>
                                <Form.Group className='mb-0'>
                                    <Form.Label className='form-label'>Select Domain</Form.Label>
                                    <Form.Control as="select" value={this.state.domainName} onChange={e => this.setState({ domainName: e.target.value })}>
                                        <option value=''>Select Domain for Sender</option>
                                        {this.state.domains.map((domain, index) => <option value={domain} key={index}>{domain}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            {this.state.billingType === "Threatcop" ? <div className="mr-2 mt-1"> <Form.Check type="switch" onChange={this.props.handleSenderType} checked={this.props.senderType === "custom"} id="custom-switch" label="Custom" /> </div> : null}
                            {this.props.senderType === 'custom' ?
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <Form.Group controlId="host">
                                            <Form.Label>Host
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='top'>Enter the server name for your SMTP server.</Tooltip>}>
                                                    <IoInformation className="tooltip-icon"></IoInformation>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control className='widthSet' type="text" disabled={this.state.allDisabled} placeholder="smtp.example.com" value={this.state.host} onChange={e => this.setState({ host: e.target.value })} />
                                        </Form.Group>
                                    </div>

                                    <div className='col-sm-5'>
                                        <Form.Group controlId="formPlaintextEmail">
                                            <Form.Label>Username
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                                    <IoInformation className="tooltip-icon"></IoInformation>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control className='widthSet' type="text" disabled={this.state.allDisabled} autoComplete="new-user" placeholder="Username" value={this.state.username} onChange={e => this.setState({ username: e.target.value })} />
                                        </Form.Group>
                                    </div>
                                    <div className='col-sm-5'>
                                        <Form.Group controlId="formPlaintextPassword" className='mb-0'>
                                            <Form.Label>Password
                                                <OverlayTrigger
                                                    placement='top'
                                                    overlay={<Tooltip id='top'>This mode help you to classify a Sender ID</Tooltip>}>
                                                    <IoInformation className="tooltip-icon"></IoInformation>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control className='widthSet' type="password" disabled={this.state.allDisabled} autoComplete="new-password" placeholder="*****" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                        </Form.Group>
                                    </div>
                                </div> : ''
                            }

                        </div>
                    </div>
                    <div className="attackCard">
                        <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Header Key Details (Optional)</h5>
                                <p className='lead'><span className='red-star'>*</span> You can configure a custom header to avoid being flagged or blocked by Google.</p>
                                <p className='lead mt-0 font-weight-bold'>Eg: Key="Purpose", Value="Employee Training"</p>
                            </div>
                            <Form.Group controlId="from">
                                <div className="row mb-3">
                                    <div className='col-sm-5'>
                                        <Form.Group className='mb-0'>
                                            <Form.Label className='form-label'>Header Key </Form.Label>
                                            <Form.Control type="text" placeholder="Enter header key" value={this.state.headerKey} onChange={e => this.setState({ headerKey: e.target.value })} />
                                        </Form.Group>
                                    </div>

                                    <div className='col-sm-5'>
                                        <Form.Group className='mb-0 '>
                                            <Form.Label className='form-label'>Header Value </Form.Label>
                                            <Form.Control type="text" placeholder="Enter header value" value={this.state.headerValue} onChange={e => this.setState({ headerValue: e.target.value })} />
                                        </Form.Group>
                                    </div>

                                    <div className='col-sm-2 d-flex align-items-center'>
                                        <Form.Group className='mb-0 mr-2'>
                                            <Form.Label className='form-label'>&nbsp;</Form.Label>
                                            <Button className="setbtnPadding" onClick={this.haldleHeader}>Add</Button>
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className='tableContainerWrapInner'>
                                    <Table className="training-table" borderless>
                                        <thead>
                                            <tr>
                                                <th>Header Key</th>
                                                <th>Header Value</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.headers.map((data, index) => {
                                                return (
                                                    <tr key={index} className='setbgColor'>
                                                        <td>{data.key}</td>
                                                        <td>{data.value}</td>
                                                        <td>
                                                            <Button className="btn-danger-rgba deletebtn" onClick={e => this.deleteHeader(data.key)}><DeleteSharpIcon className="table-icon text-danger"></DeleteSharpIcon></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="attackCard">
                        <div className='midCard'>
                            <div className='headerPara'>
                                <h5>Fake CC (Optional)</h5>
                                <p className='lead'><span className='red-star'>*</span>Utilize this function to include a dummy email in the cc field for recipients. It's important to note that the email used in the fake cc will not actually receive the message, but recipients will be able to see it</p>
                            </div>
                            <div className="row mb-0">
                                <div className='col-sm-5'>
                                    <Form.Group className='mb-0 '>
                                        <Form.Label className='form-label'>Enter Email Id's</Form.Label>
                                        <Form.Control className=' w-100' type="text" placeholder="Enter CC EmailId" value={this.state.fakeCCEmail} onChange={e => this.setState({ fakeCCEmail: e.target.value })} />
                                    </Form.Group>
                                </div>

                                <div className='col-sm-5 d-flex align-items-center'>
                                    <Form.Group className='mb-0'>
                                        <Form.Label className='form-label'>&nbsp;</Form.Label>
                                        <Button className="setbtnPadding" onClick={this.haldleFakeEmail}>Add Email</Button>
                                    </Form.Group>
                                </div>

                            </div>
                            {this.state.fakeCC.map((email, index) => {
                                return <span key={index} pill className="mt-2 primaryBadge mb-2 d-inline-block" variant="primary">
                                    {email} {' '} <CancelIcon className="training-icon" onClick={() => this.deleteFakeCCEmail(email)}></CancelIcon>
                                </span>
                            })}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className='footer-wrapper justify-content-end d-flex'>
                        <Button variant="secondary" className="btn closeBtn mr-2" onClick={e => this.props.handleTabChange('prev')}><ArrowBackOutlinedIcon className="btn-icon"></ArrowBackOutlinedIcon> Previous</Button>
                        <Button variant="success" disabled={this.state.loading} type="button" onClick={this.handleCreateSender}><Spinner loading={this.state.loading}></Spinner><ArrowForwardOutlinedIcon className={this.state.loading ? "btn-icon d-none" : "btn-icon"}></ArrowForwardOutlinedIcon> Go To Next</Button>
                    </div>
                </div>
                <TestEmailModal
                    show={this.state.show}
                    email={this.state.email}
                    loading={this.state.loading}
                    sendTestMail={this.sendTestMail}
                    handleEmail={e => this.setState({ email: e.target.value })}
                    handleEmailModal={e => this.setState({ show: !this.state.show })}
                ></TestEmailModal>
            </>
        )
    }
}