import React, { Component } from 'react';
import { Card } from '@mui/material';
import { toast } from 'react-toastify';
import { Image, Modal, Row, Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { PiArrowsOutThin,} from "react-icons/pi";

import axios from '../../../axios';
import apiError from '../../../error';
import './import-landing-from-store.css';

export default class ImportLandingFromStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            hasMore: true,
            page: 1,
            landingPageTemplateId: null,
        };
    }

    loadLandingPagesFromStore = () => {
        axios.post(`/api/store/list-landingpages`, { page: this.state.page })
            .then(response => response.data)
            .then(data => {
                if (data && data.response) {
                    this.setState(prevState => ({
                        templates: prevState.page === 1 ? data.response : [...prevState.templates, ...data.response],
                        hasMore: data.response.length > 0,
                        page: prevState.page + 1
                    }));
                } else {
                    this.setState({ hasMore: false });
                }
            })
            .catch(apiError);
    }

    componentDidMount() {
        this.loadLandingPagesFromStore();
    }

    loadHtml = (landingPageTemplateId) => {
        axios.get(`/api/store/fetch-landing/${landingPageTemplateId}`)
            .then(response => response.data)
            .then(responseData => {
                if (responseData && responseData.response) {
                    this.props.handleLoadSelectedStoreTemplateHtml(responseData.response.html, responseData.response.name, responseData.response.capture_credentials, responseData.response.capture_passwords);
                    this.props.handleClose();
                } else {
                    toast.error('LandingPage HTML not found');
                }
            })
            .catch(apiError);
    }

    handleCardClick = () => {
        this.loadHtml(this.state.landingPageTemplateId);
    }

    render() {
        const { templates, hasMore, landingPageTemplateId } = this.state;
        const { showImportModal, handleClose } = this.props;

        return (
            <Modal className="info-filter mainfooterModal emailRight right" style={{ overflowY: 'auto' }} show={showImportModal} onHide={handleClose} autoFocus keyboard>
                <Modal.Header closeButton>
                <div>
                     <h5>Landing Page Template</h5>
                </div>
                </Modal.Header>
                <Modal.Body>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadLandingPagesFromStore}
                        hasMore={hasMore}
                        loader={<div className="loader" key={0}>Loading.......</div>}
                        useWindow={false}
                    >
                        <Row className='landingPageCard emailCard'>
                            {templates.map((template, index) => (
                                <div key={index} className='col-md-4 p-0'>
                                     <div className="img-hover-wrap templateView" 
                                            // onClick={e => {
                                            //     this.state.templateImg?
                                            //     this.setState({
                                            //         showThumbnail: true,
                                            //         currentSrc: this.state.templateImg
                                            //     }):
                                            //     toast.info('Email Template Image Not Available')
                                            // }}
                                      >

                                    <div className={`cardWrap ${this.state.landingPageTemplateId === template.id? 'selectActive': ''}`}
                                        style={{ 
                                            position: 'relative', 
                                            cursor: 'pointer',
                                            boxShadow: landingPageTemplateId === template.id ? '0 5px 10px rgba(0, 0, 0, 0.3)' : '0 1px 3px rgba(0, 0, 0, 0.2)',
                                            transform: landingPageTemplateId === template.id ? 'scale(0.98)' : 'scale(1)',
                                            transition: 'transform 0.1s, box-shadow 0.1s'
                                        }} 
                                        onClick={() => this.setState({landingPageTemplateId: template.id})}
                                    >
                                        <Image crossOrigin="anonymous" alt="no image" src={template.thumbnail} />
                                        <div className='hover-btn'>
                                            <PiArrowsOutThin className="btn-icon" />
                                        </div>

                                        <div className='cardContent'>
                                            <h6>{template.name}</h6>
                                            <div className='cardfooterBox'>
                                                <span className='badge badgeTwo'>Finance & Acc</span>
                                                <span className='badge badgeOne'>Finance</span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    
                                </div>
                            ))}
                        </Row>
                    </InfiniteScroll>
                </Modal.Body>
                <div className="modalFooter" style={{paddingRight:"115px"}}>
                    <div className="row ">
                        <div className="col-md-12 text-right">
                            <button onClick={handleClose} type="button" className="btn closeBtn">Cancel</button>
                            <Button disabled={this.state.landingPageTemplateId? false: true} onClick={() => this.handleCardClick()} className='btn btn-success ml-2'>Select Landing Page</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}
