import React, { Component, createRef } from 'react';
import { SketchPicker } from 'react-color';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoInformation } from "react-icons/io5";
import Accordion from 'react-bootstrap/Accordion';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TouchAppSharpIcon from '@material-ui/icons/TouchAppSharp';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp';

import "./generate.css";
import axios from '../../../axios';
import apiError from '../../../error';

export default class GenerateTemplate extends Component {

    constructor(props) {
        super(props);
        this.selectorRef = createRef(null);
        this.state = {
            categories: [],
            languages: [],
            show: true,
            key: "home",
            generationAttemptsCount: "",
            backgroundHeader: "",
            backgroundFooter: "",
            getUpdatedHtml: '',
            arrowDown: false,
            arrowDown2: false,
            arrowDown3: false,
            alignment: "",
        }
        this.loadCategory();
        this.loadLanguage();
        this.generationAttempts()
    }

    handleChangeComplete = (color) => {
        this.props.updateBackgroundHeaderWithIdx(color.hex, this.props.currentIndex)
        let indexHeaderFooter = [...this.selectorRef.current.children];
        indexHeaderFooter.map((elm, idx) => {
            if (elm.classList.contains("Aitemplate-header")) {
                indexHeaderFooter[idx].style.backgroundColor = color.hex;
            }
            if (elm.classList.contains("Aitemplate-footer")) {
                indexHeaderFooter[idx].style.backgroundColor = color.hex;
            }
            return elm;
        });
    };

    componentDidMount = () => {
        if (this.selectorRef.current != null) {
            let indexHeaderFooter = [...this.selectorRef.current.children];
            let defaultLogoPosition = this.props[`logoPosition${this.props.currentIndex + 1}`];
            let defaultBackgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
            if(!defaultBackgroundColor || defaultBackgroundColor==="") this.props.updateBackgroundHeaderWithIdx(this.props.defaultColor, this.props.currentIndex);
            if(!defaultLogoPosition || defaultLogoPosition==="") this.props.updateLogoPositionWithIdx(this.props.logoPosition, this.props.currentIndex)
            indexHeaderFooter.map((elm, idx) => {
                if (elm.classList.contains("Aitemplate-header")) {
                    indexHeaderFooter[idx].style.backgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
                    indexHeaderFooter[idx].style.textAlign = this.props[`logoPosition${this.props.currentIndex + 1}`];
                }
                if (elm.classList.contains("Aitemplate-footer")) {
                    indexHeaderFooter[idx].style.backgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
                }
                return elm;
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.templateResponse.length !== this.props.templateResponse.length) {
            this.props.updateCurrentIndex(this.props.templateResponse.length - 1);
            this.generationAttempts();
        }
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    loadCategory = () => {
        axios.get('/api/campaign/template/category', {})
            .then(res => {
                this.setState({ categories: res.data.categories });
            })
            .catch(err => apiError(err));
    }

    loadLanguage = () => {
        axios.get('/api/campaign/template/language', {})
            .then(res => {
                this.setState({ languages: res.data.languages });
            })
            .catch(err => apiError(err));
    }

    generationAttempts = () => {
        axios.get('/api/campaign/template/generate/generationAttempts')
            .then(res => {
                this.setState({ generationAttemptsCount: res.data.generationAttempts.generationAttempts });
            })
            .catch(err => apiError(err));
    }

    handleTemplateRendering = (e, idx) => {
        this.props.updateCurrentIndex(idx);
        this.setState({ getUpdatedHtml: this.selectorRef.current });
        setTimeout(() => {
            let indexHeaderFooter = [...this.selectorRef.current.children];
            indexHeaderFooter.map((elm, idx) => {
                if (elm.classList.contains("Aitemplate-header")) {
                    indexHeaderFooter[idx].style.backgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
                    indexHeaderFooter[idx].style.textAlign = this.props[`logoPosition${this.props.currentIndex + 1}`];
                }
                if (elm.classList.contains("Aitemplate-footer")) {
                    indexHeaderFooter[idx].style.backgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
                }
                return elm;
            });
        }, 0);
    }

    selectLogoPosition = (e) => {
        this.props.updateLogoPositionWithIdx(e.target.value, this.props.currentIndex);
        const index = [...this.selectorRef.current.children];
        index.map((elm, idx) => {
            if (elm.classList.contains("Aitemplate-header")) {
                index[idx].style.textAlign = e.target.value;
            }
            return elm;
        });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    regenerateTemplate = () => {
        this.props.handleGenerateAi();
        this.setState({ 
            background: "#1E1C1C" 
        });
        this.props.updateLogoPosition("", this.props.currentIndex)
    }

    render() {
        return (
            <div className="aimainSection">
                <div className='setscrollHeight'>
                    <div className="row m-0" style={{ height: "100%" }}>
                        <div className="col-sm-4 tabClass px-0">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={this.props.tabValue}
                                onSelect={this.props.validateInfoTemplate}
                                className="m-0">
                                <Tab eventKey="information" title={<> <Badge className="circle_badge" variant='light'>1</Badge> Information  </>}>
                                    <Form.Group>
                                        <Form.Label>Template Name</Form.Label>
                                        <Form.Control type="text" value={this.props.templateName} onChange={this.props.handleCampaignName} placeholder="Campaign template name" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Template Category
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>These are the categories of attack templates</Tooltip>}>
                                                <IoInformation className="tooltip-icon"></IoInformation>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control value={this.props.categoryId} onChange={this.props.handleCategoryId} as="select">
                                            <option value='0'>Select Campaign Template Category</option>
                                            {this.state.categories.map((category, index) => {
                                                return <option key={index} value={category.id}>{category.name}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Languages
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>This will be only for labelling purpose/This will label your attack template</Tooltip>}>
                                                <IoInformation className="tooltip-icon"></IoInformation>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control value={this.props.languageId} onChange={this.props.handleLanguageId} as="select">
                                            <option value='0'>Select Language</option>
                                            {this.state.languages.map((language, index) => {
                                                return (
                                                    <option key={index} value={language.id}>{language.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Type of Simulation
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>These are the categories of simulation</Tooltip>}>
                                                <IoInformation className="tooltip-icon"></IoInformation>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control as="select" disabled={this.props.disableState} value={JSON.stringify({ id: this.props.simulationTypeId, name: this.props.simulationTypeName })} onChange={this.props.handleSimulationType}>
                                            <option value={JSON.stringify({ id: -1, name: '' })}>Select Simulation Type</option>
                                            {this.props.simulation_type.map((type, index) => {
                                                return <option key={index} disabled={!type.active} value={JSON.stringify({ id: type.id, name: type.name })} >{type.name}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Attack Vector
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={<Tooltip id='top'>Different type of cyber Attack</Tooltip>}>
                                                <IoInformation className="tooltip-icon"></IoInformation>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control as="select" disabled={this.props.disableState} value={JSON.stringify({ id: this.props.attackTypeId, name: this.props.attackTypeName })} onChange={this.props.handleAttackType}>
                                            <option value={JSON.stringify({ id: -1, name: '' })}>Select Attack Type</option>
                                            {this.props.attack_type.filter((vector, index) => {
                                                if (vector.attack_type.name === "Phishing") {
                                                    return vector
                                                } else return null;
                                            }).map((vector, index) => {
                                                return <option key={index} value={JSON.stringify({ id: vector.attack_type.id, name: vector.attack_type.name })} >{vector.attack_type.name}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Tab>
                                <Tab eventKey="generate" title={<> <Badge className="circle_badge" variant='light'>2</Badge> Generate Template  </>} disabled={this.props.tabBtn}>
                                    <Form.Group>
                                        <Form.Label >Enter Prompt</Form.Label>
                                        <Form.Control as="textarea" rows={4} value={this.props.context} onChange={this.props.handleContext} placeholder="I want to provide amazon gift voucher of 100$ to my employees" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Theme URL</Form.Label>
                                        <Form.Control type="text" value={this.props.companyUrl} onChange={this.props.handleCompanyUrl} placeholder="Theme URL" />
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Company Logo</Form.Label>
                                        <div className='formFile'>
                                            <Form.Control type="file" className='form-control' accept="image/*" onChange={this.props.handleCompanyLogo} placeholder="Upload File" style={{ padding:"8.5px 15px"}}/>
                                            <span>{this.props.companyLogo}</span>
                                        </div>
                                    </Form.Group>
                                    {this.props.templateResponse.length > 0 &&
                                        <>
                                            <label className="form-label mb-0"> Customize Template</label>
                                            <Accordion>
                                                <Card className="cardCss">
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={(e) => this.setState({ arrowDown: !this.state.arrowDown })} className={this.state.arrowDown ? "activeCollapse" : 'deactiveCollapse'}>
                                                            Theme Color <NavigateNextSharpIcon className="down-arrow" style={{ top: "11px" }}></NavigateNextSharpIcon> </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <SketchPicker
                                                                color={this.props[`backgroundHeader${this.props.currentIndex + 1}`]}
                                                                onChangeComplete={this.handleChangeComplete}
                                                            />
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            <Accordion>
                                                <Card className="cardCss">
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={(e) => this.setState({ arrowDown2: !this.state.arrowDown2 })} className={this.state.arrowDown2 ? "activeCollapse" : 'deactiveCollapse'}>
                                                            Logo Alignment <NavigateNextSharpIcon className="down-arrow" style={{ top: "11px" }}></NavigateNextSharpIcon></Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <div>
                                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                                    <Form.Control as="select" onChange={this.selectLogoPosition} value={this.props[`logoPosition${this.props.currentIndex + 1}`]}>
                                                                        <option value="">Select Logo Position</option>
                                                                        <option value="left">Left</option>
                                                                        <option value="center">Middle</option>
                                                                        <option value="right">Right</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </>
                                    }
                                </Tab>
                            </Tabs>
                        </div>
                        <div className="col-sm-8 rightBox">
                            <div className="template_box">
                                <div className="templateDiv">
                                    <p className="badgeWrap">Attempt Used: {this.props.templateResponse.length} / 5 </p>
                                    <p className="badgeWrap">Total Attempts: {this.state.generationAttemptsCount} </p>
                                </div>
                                <div className={this.props.lang ? "template_wraper textAlignWrap" : "template_wraper"} >
                                    {this.props.templateResponse.length ?
                                        <div className="template">
                                            <div ref={this.selectorRef} dangerouslySetInnerHTML={{ __html: this.props.templateResponse[this.props.currentIndex] }}></div>
                                        </div> :
                                        <div className="info_generate"><h5>Click on Generate template to view this section</h5></div>
                                    }
                                </div>
                                <div className="radioBtn">
                                    {this.props.templateResponse.length > 0 ?
                                        <label className="textTemplate">* Select a template to proceed to the next step </label> : ''
                                    }
                                    <div className="radioBtnFlex checkBoxWrap setMarginCss ml-1">
                                        {this.props.templateResponse && this.props.templateResponse.map((elm, idx) => {
                                            return (
                                                <div key={`templateResponse-${idx}`}>
                                                    <Form.Check
                                                        type="radio"
                                                        id={`disabled-default-${idx}`}
                                                        label={`#${idx + 1} Template `}
                                                        checked={idx === this.props.currentIndex}
                                                        className="form_check"
                                                        onChange={(e) => this.handleTemplateRendering(e, idx)}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='capFooter text-right aiGenerate'>
                    {this.props.templateResponse.length === 0 ?
                        <>
                            {this.props.tabValue === "information" ?
                                <div className="footer_template">
                                    <Button variant="success" onClick={() => this.props.validateInfoTemplate("generate")}> <ArrowForwardSharpIcon className="arrowIcon" /> Next Step</Button>
                                </div> :
                                <div className="footer_template">
                                    <Button variant="secondary" onClick={this.props.previousTab} className="mr-2"> <ArrowBackSharpIcon className="arrowIcon" /> Back</Button>
                                    <Button variant="success" onClick={() => {
                                        this.props.handleGenerateAi();
                                        this.setState({ background: "#1E1C1C" })
                                        this.props.updateLogoPosition("", this.props.currentIndex)
                                    }}>
                                        <TouchAppSharpIcon className="arrowIcon" /> Generate</Button>
                                </div>
                            }
                        </> :
                        <div>
                            {this.props.attackTypeName && this.props.attackTypeName.length && this.props.attackTypeName !== "Vishing" && this.props.attackTypeName !== "Training + Quiz" && this.props.attackTypeName !== "Ransomware" ?
                                <Form.Group controlId="formBasicCheckbox" className="checkbox">
                                    <Form.Check type="checkbox" label="Credential Harvesting" checked={this.props.credential_harvesting} onChange={this.props.handleHarvesting} />
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={<Tooltip id='top'>Redirection of the user to a landing page luring them to give away their credentials.</Tooltip>}>
                                        <IoInformation className="tooltip-icon mt-1"></IoInformation>
                                    </OverlayTrigger>
                                </Form.Group> : ''
                            }
                            {this.props.tabValue !== "information" && this.props.templateResponse.length>0 && 
                                <Button variant="warning" className='mx-2' onClick={this.regenerateTemplate}>Regenerate Template</Button>
                            }
                            <Button variant="success" onClick={(e) => {
                                let defaultColor = this.props[`defaultColor${this.props.currentIndex + 1}`];
                                this.props.updateDefaultColor(defaultColor);
                                let defaultLogoPosition = this.props[`logoPosition${this.props.currentIndex + 1}`] ?? "";
                                let defaultBackgroundColor = this.props[`backgroundHeader${this.props.currentIndex + 1}`];
                                if(!defaultBackgroundColor || defaultBackgroundColor==="") defaultBackgroundColor = this.props.defaultColor;
                                this.props.updateLogoPosition(defaultLogoPosition);
                                this.props.updateBackgroundHeader(defaultBackgroundColor);
                                this.props.handleCreateCampaignTemplate(e, this.props.currentIndex, this.state.getUpdatedHtml);
                            }}>Go To Next</Button>
                        </div>
                    }
                </div>
                {this.props.templateResponse.length > 5 &&
                    <Modal show={this.state.show} centered onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                <h6 className="mb-0">Attempt Exhausted</h6>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>You have reached your maximum limit for template generation. Please select anyone template to proceed.</p>
                        </Modal.Body>
                    </Modal>
                }
            </div >
        )
    }
}
