import { toast } from 'react-toastify';

const apiError = (error) => {
    if(error && error.response && error.response.status && error.response.status === 440){
        for(let i=0; i<error.response.data.errors.length; i++){
            toast.error(error.response.data.errors[i]);
        }
        setTimeout(() => {
            window.location = '/login?next='+window.location.pathname;
        }, 2000)
    } else if(error && error.response && error.response.data && error.response.data.errors){
        for(let i=0; i<error.response.data.errors.length; i++){
            toast.error(error.response.data.errors[i]);
        }
    } else if(error.response && error.response.data && error.response.data.isJoi){
        for(let i=0; error.response.data.details && i<error.response.data.details.length; i++){
            toast.error(error.response.data.details[i].message);
        }
    }else {
        if(navigator.onLine){
            toast.error('Something Went Wrong!');
        } else {
            toast.error('We Are Offline, Please Check Your Internet Connetion !');
        }
    }
}

export default apiError;