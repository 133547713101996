import React, { Component } from "react";
import { toast } from "react-toastify";
import { PiCopy } from "react-icons/pi";
import { IoInformation } from "react-icons/io5";
import { Button, Form } from 'react-bootstrap';

import './landing-edit.css';
import axios from '../../../axios';
import apiError from '../../../error';
import Loader from '../../../component/loader/loader';

export default class Landing_URL_Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            ns1: '',
            ns2: '',
            txtarea: '',
            originalNS: [],
            domainName: '',
            loading: false,
            urlId: parseInt(this.props.match.params.urlId, 10),
        }
    }
    componentDidMount = () => {
        if (this.state.urlId) {
            this.load();
        }
    }

    load = () => {
        this.setState({ loading: true })
        axios.get(`/api/pages/url/${this.state.urlId}`, {})
            .then(res => {
                this.setState({ loading: false, domainName: res.data.domainName, ns1: res.data.ns1, ns2: res.data.ns2, originalNS: res.data.originalNS ? res.data.originalNS : [] })
            })
            .catch(err => {
                apiError(err)
                this.setState({ loading: false })
            })
    }

    handleCopy = (id, content) => {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        var element = document.getElementById(`copy-${id}`);
        if (element) {
            element.innerHTML = "Copied";
            setTimeout(() => {
                element.innerHTML = "";
            }, 1000);
        }
    }

    verifyDomain = () => {
        if (this.state.urlId) {
            this.setState({ loading: true })
            axios.get(`/api/pages/validate/${this.state.urlId}`, {})
                .then(res => {
                    this.setState({ loading: false })
                    if (res.data.status) {
                        this.props.history.push(`/landing-url/preview/${this.state.urlId}`);
                    } else {
                        this.load();
                        toast.info('Details Not Verified. Please Update NS Record And Try Again.');
                    }
                })
                .catch(err => {
                    apiError(err)
                    this.setState({ loading: false })
                })
        }
    }

    render() {
        return (
            <div className="content-wrap nameserverWrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <div className="">
                                    <h4 className="page-title">Nameserver Setup</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentbar">
                    <div className="noteSection nameServnote">
                        <p><IoInformation className="tooltip-icon"></IoInformation><span style={{ color: 'blue' }}>{this.state.domainName}</span> is not yet active on Cloudfare</p>
                    </div>
                    <div className="newCampCreate">
                        <div className="campBlock pt-2">
                            <div className="compHeading"><span className="point">1</span>Log into your Network Solutions Account</div>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Existing Nameserver</Form.Label>
                                <div className="serverBox">
                                    {this.state.originalNS.map((ns, index) => {
                                        return <span key={index} className="secondaryBadge" variant="secondary">{ns}</span>
                                    })}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="campBlock">
                            <div className="compHeading"><span className="point">2</span>Replace the cloudflare's nameservers</div>
                            <div className="flexForm">
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nameservers 1</Form.Label>
                                    <Form.Control disabled type="text" placeholder="" value={this.state.ns1} />
                                    <span className="copy_text_copied ml-2" id="copy-nameserver1"></span>
                                </Form.Group>
                                <div className="copyIcon mb-3" onClick={e => this.handleCopy('nameserver1', this.state.ns1)}>
                                    <PiCopy></PiCopy>
                                </div>
                            </div>
                            <div className="flexForm">
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Nameservers 2</Form.Label>
                                    <Form.Control type="text" disabled placeholder="" value={this.state.ns2} />
                                    <span className="copy_text_copied ml-2" id="copy-nameserver2"></span>
                                </Form.Group>
                                <div className="copyIcon" onClick={e => this.handleCopy('nameserver2', this.state.ns2)}>
                                    <PiCopy></PiCopy>
                                </div>
                            </div>
                            <Button variant="success" onClick={this.verifyDomain}>Verify Your NS Record</Button>
                        </div>
                    </div>
                </div>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}