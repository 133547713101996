import React, { Component } from "react";
import './iamuser.css';
import axios from '../../../axios';
import apiError from '../../../error';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import userEdit from '../../../assets/user-edit.png';
import Spinner from '../../../component/spinner/spinner';
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";
import { Tab, Tabs, Table, Form, Button } from 'react-bootstrap';
export default class IamUserEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            roles: [],
            roleIds: [],
            policies: [],
            policyIds: [],
            userId: parseInt(this.props.match.params.id, 10),

            email: '',
            lastname: '',
            firstname: '',
            emailError: false,
            disabledUser: true,
            buttonLoading: false,
            firstnameError: false,
            resetButtonLoading: false,

        }
        this.load();
        this.loadPolicyAndRoles();
    }

    load = () => {
        if(this.state.userId && this.state.userId > 0){
            axios.get('/api/iam-user/'+this.state.userId, {})
            .then(res => {
                this.setState({
                    email: res.data.employee.email,
                    lastname: res.data.employee.lastname,
                    firstname: res.data.employee.firstname,
                })
            })
            .catch(err => apiError(err));
        }
    }

    handleResetPassword = () => {
        this.setState({resetButtonLoading: true})
        axios.post('/api/iam-user/resetPassword/', {
            userId: this.state.userId
        })
        .then(res => {
            this.setState({resetButtonLoading: false})
            toast.success("Reset Password Link Has Been Sent To Employee Email Id");
        })
        .catch(err => {
            this.setState({resetButtonLoading: false})
            apiError(err)
        });
    }

    createUser = () => {
        if(this.state.firstname.length === 0 || (this.state.firstname).trim().length === 0){
            toast.error("Please Enter FirstName");
            this.setState({firstnameError: true})
            return false;
        } else if(this.state.email.length === 0){
            toast.error("Please Enter Email Id");
            this.setState({emailError: true})
            return false;
        }
        this.setState({buttonLoading: true})
        if(this.state.userId && this.state.userId > 0){
            axios.patch('/api/iam-user/addEmployee/'+this.state.userId, {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
            })
            .then(res => {
                this.setState({buttonLoading: false})
                toast.success("User Updated Successfully");
            })
            .catch(err => {
                this.setState({buttonLoading: false})
                apiError(err)
            });
        } else {
            axios.post('/api/iam-user/addEmployee', {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
            })
            .then(res => {
                this.props.load();
                this.setState({buttonLoading: false})
                toast.success("User Added Successfully");
            })
            .catch(err => {
                this.setState({buttonLoading: false})
                apiError(err)
            });
        }
    }

    loadPolicyAndRoles = () => {
        axios.get('/api/iam-user/show/'+this.state.userId, {})
        .then(res => {
            let roleIds = [], policyIds = [];
            if(res.data.userPolicy && res.data.userPolicy.length){
                for(let i=0; i<res.data.userPolicy.length; i++){
                    if(res.data.userPolicy[i].policyId){
                        policyIds.push(res.data.userPolicy[i].policyId)
                    } else if(res.data.userPolicy[i].roleId){
                        roleIds.push(res.data.userPolicy[i].roleId)
                    }
                }
            }
            this.setState({ roleIds: roleIds, policyIds: policyIds }, () => {
                this.loadPolicy();
                this.loadRole();
            })
        })
        .catch(err => apiError(err));
    }

    loadPolicy = () => {
        axios.get('/api/policy/get', {})
        .then(res => {
            this.setState({ policies: res.data.policies })
        })
        .catch(err => apiError(err));
    }

    loadRole = () => {
        axios.get('/api/roles', {})
        .then(res => {
            this.setState({ roles: res.data.roles })
        })
        .catch(err => apiError(err));
    }

    handlePolicyUpdate = (policyId) => {
        axios.post('/api/iam-user/addPolicy', {
            policyId: policyId,
            userId: this.state.userId,
        }).catch(err => apiError(err));
    }

    handleRoleUpdate = (roleId) => {
        axios.post('/api/iam-user/addRole', {
            roleId: roleId,
            userId: this.state.userId,
        }).catch(err => apiError(err));
    }

    render() {
        return (
            <div className="content-wrap">
                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-12 pl-0">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title"><a href="" onClick={(e) => this.props.history.push("/iam/users")} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon"/></a>Edit User</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contentbar">
                        <div className="page-content" id="tab-wrapper">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h6>Edit User Profile</h6>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <Form.Label>First Name<span className="red-star">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter first name" isInvalid={this.state.firstnameError} value={this.state.firstname} onChange={e => this.setState({firstname: e.target.value, firstnameError: false, disabledUser: false})} />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter last name" value={this.state.lastname} onChange={e => this.setState({lastname: e.target.value, disabledUser: false})}/>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="">
                                        <Form.Group>
                                            <Form.Label>Email Id<span className="red-star">*</span></Form.Label>
                                            <Form.Control type="email" disabled={true} placeholder="Enter email id" value={this.state.email} onChange={e => {}}/>
                                        </Form.Group>
                                    </div>
                                    <Link to="#">
                                        <Button variant="secondary" className="btn btn-warning mr-2" disabled={this.state.resetButtonLoading} onClick={this.handleResetPassword}><Spinner loading={this.state.resetButtonLoading}></Spinner> Change Password </Button>
                                        <Button variant="primary" onClick={this.createUser} disabled={this.state.buttonLoading || this.state.disabledUser}><Spinner loading={this.state.buttonLoading}></Spinner>Update User </Button>
                                    </Link>
                                </div>
                                <div className="col-md-6">
                                    <img src={userEdit} alt="user edit" className="img-fluid"></img>
                                </div>
                            </div>
                        </div>
               </div>
             
               
                <div className="page-content" id="tab-wrapper">
                    <Tabs defaultActiveKey="roles" id="uncontrolled-tab-example" className="iamusertabPleft">
                        <Tab eventKey="roles" title="Roles">
                            <div className="tableContainerWrapInner">
                                <Table responsive className="mb-0">
                                    <thead className="bg-light">
                                        <tr>
                                            <th>Id</th>
                                            <th>Role Name</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.roles.map((role, index) => {
                                            return <tr key={'role-'+index}>
                                                <td>{index + 1}</td>
                                                <td>{role.name}</td>
                                                <td>{role.type}</td>
                                                <td>{role.description}</td>
                                                {this.state.roleIds.indexOf(role.id) !== -1? 
                                                    <td><Form.Check type="switch" defaultChecked={true} onChange={e => this.handleRoleUpdate(role.id)} id={ 'custom-switch-role-' + index } /></td>: 
                                                    <td><Form.Check type="switch" onChange={e => this.handleRoleUpdate(role.id)} id={ 'custom-switch-role-' + index }/></td> 
                                                } 
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab>


                        <Tab eventKey="policies" title="Policies">
                            <div className="tableContainerWrapInner">
                                <Table responsive>
                                    <thead className="bg-light">
                                        <tr>
                                            <th>Id</th>
                                            <th>Policy Name</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.policies.map((policy, index) => {
                                            return <tr key={'policy-'+index}>
                                                <td>{index + 1}</td>
                                                <td>{policy.name}</td>
                                                <td>{policy.type}</td>
                                                <td>{policy.description}</td>
                                                {this.state.policyIds.indexOf(policy.id) !== -1? 
                                                    <td><Form.Check type="switch" defaultChecked={true} onChange={e => this.handlePolicyUpdate(policy.id)} id={ 'custom-switch-policy-' + index } /></td>: 
                                                    <td><Form.Check type="switch" onChange={e => this.handlePolicyUpdate(policy.id)} id={ 'custom-switch-policy-' + index }/></td> 
                                                } 
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                </div>
          
        );
    }
}