import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { VisibilityOffSharp } from "@mui/icons-material";
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';

import axios from "../../axios";
import apiError from "../../error";
import threatcop from "../../assets/threatcop-b.png";
import Spinner from "../../component/spinner/spinner";
import { setCookie, eraseCookie, appType } from "../../function";

import "./login.css";

export default class Signin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			otp: "",
			email: "",
			timer: 30,
			type: null,
			password: "",
			companyId: 0,
			otpType: null,
			showTimer: "",
			intervalId: "",
			loading: false,
			companyData: {},
			twoFactor: false,
			isPassword: false,
			disableEmail: false,
			isValidEmail: false,
			showPassword: false,
			showCompanyName: false,
			hasSSO: false,
			ssoloading: false,
			appType: appType(),
		};
		this.clearCoockies();
	}

	clearCoockies = () => {
		/* remove cookie from browser */
		eraseCookie("isTAC");
		eraseCookie("TAC_URL");	
		eraseCookie("token");
		eraseCookie("logo");
		eraseCookie("userType");
		eraseCookie("username");
		eraseCookie("userAgent");
		eraseCookie("firstname");
		eraseCookie("billingType");
		eraseCookie("isDashboard");
		eraseCookie("accessLevel");

		/* remove local storate data from browser */
		localStorage["email"] = "";
		localStorage["lastname"] = "";
		localStorage["firstname"] = "";

	};

	validate = () => {
		if (this.state.email.length === 0) {
			toast.error("Please Enter Valid Email Id");
			return false;
		}
		if (this.state.type === null || this.state.companyId === 0 || this.state.companyId === "0") {
			toast.error("Please Select Login Company");
			return false;
		}
		if (this.state.password.length === 0) {
			toast.error("Please Enter Password");
			return false;
		}
		if (this.state.twoFactor) {
			if (this.state.otp.length === 0) {
				toast.error("Please Enter OTP");
				return false;
			}
		}
		return true;
	};

	useQuery = () => {
		return new URLSearchParams(window.location.search);
	};

	handleSubmitEmail = () => {
		this.setState({ loading: true });
		axios.post("/api/auth/validateEmail", {
				email: this.state.email,
			})
			.then((res) => {
				this.setState({
					loading: false,
					disableEmail: true,
					companies: res.data.company ? res.data.company : [],
				});
				if (res.data.company.length === 1) {
					let companyId = res.data.company && res.data.company.length ? res.data.company[0].companyId : 0;
					this.handleValidateCompany(companyId, true);
					this.setState({ companyId: companyId });
				} else {
					this.setState({ showCompanyName: true, isValidEmail: true });
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				apiError(error);
			});
	};

	handleValidate = () => {
		if (this.state.email && this.state.email.length > 0) {
			this.handleSubmitEmail();
		} else {
			toast.error("Please Enter Email Id");
		}
	};

	handleValidateCompany = async (companyId) => {
		if (this.state.email && this.state.email.length > 0 && companyId !== 0) {
			for (let i = 0; i < this.state.companies.length; i++) {
				if (parseInt(companyId, 10) === parseInt(this.state.companies[i].companyId, 10)) {
					await this.setState({
						loading: true,
						type: this.state.companies[i].type,
						companyId: this.state.companies[i].companyId,
					});
				}
			}
			if (this.state.type === null || parseInt(this.state.companyId, 10) === 0) {
				toast.error("Please Select Login Company");
				return false;
			}
			this.setState({ loading: true });
			axios
				.post("/api/auth/validateCompany", {
					type: this.state.type,
					email: this.state.email,
					companyId: this.state.companyId,
				})
				.then(async (res) => {
					if(this.state.companies.length === 1 && res.data.sso){
						this.handleSubmitSSO();
					} else {
						this.setState({
							timer: 30,
							loading: false,
							isPassword: true,
							isValidEmail: true,
							hasSSO: res.data.sso,
							otpType: res.data.otpType,
							twoFactor: res.data.twoFactor,
						});
						if (res.data.twoFactor) {
							let intervalId = await setInterval(() => {
								this.decrement();
							}, 1000);
							this.setState({ intervalId: intervalId });
						}
					}
				})
				.catch((error) => {
					this.setState({ loading: false });
					apiError(error);
				});
		} else {
			toast.error("Please Enter Email Id");
		}
	};

	decrement() {
		if (this.state.timer > 0) {
			var minutes = "0" + Math.floor(this.state.timer / 60);
			var seconds = "0" + (this.state.timer - minutes * 60);
			var time = minutes.substr(-2) + ":" + seconds.substr(-2);
			this.setState({ timer: this.state.timer - 1, showTimer: time });
		}
	}

	resendOTP = async () => {
		clearInterval(this.state.intervalId);
		axios.post("/api/auth/validateCompany", {
				type: this.state.type,
				email: this.state.email,
				companyId: this.state.companyId,
			})
			.then(async (res) => {
				this.setState({ timer: 30 });
				let intervalId = await setInterval(() => {
					this.decrement();
				}, 1000);
				this.setState({ intervalId: intervalId });
			})
			.catch((error) => {
				apiError(error);
			});
	};

	handleSubmit = async () => {
		const isValid = this.validate();
		if (isValid) {
			this.setState({ loading: true });
			axios.post("/api/auth/login", {
					otp: this.state.otp,
					type: this.state.type,
					email: this.state.email,
					password: this.state.password,
					companyId: this.state.companyId,
				})
				.then((res) => {
					localStorage["firstname"] = res.data.firstname;
					localStorage["lastname"] = res.data.lastname;
					localStorage["email"] = res.data.email;

					setCookie("token", res.data.token, 180);
					setCookie("logo", res.data.path, 30000);
					setCookie("userType", res.data.type, 30000);
					setCookie("userAgent", res.data.userAgent, 30000);
					setCookie("firstname", res.data.firstname, 30000);
					setCookie("username", res.data.companyName, 30000);
					setCookie("isDashboard", res.data.dashboard, 30000);
					setCookie("accessLevel", res.data.accessLevel, 30000);
					setCookie("billingType", res.data.billingType, 30000);

					window.location = this.useQuery().get("next") ? this.useQuery().get("next") : "/";
				})
				.catch((error) => {
					this.setState({ loading: false });
					apiError(error);
				});
		}
	};

	handleSubmitSSO = () => {
		if('caches' in window){
			caches.keys().then((names) => {
				names.forEach(name => {
					caches.delete(name);
				})
			});
		}
		window.location = `/api/auth/login/sso/${this.state.companyId}`;
	}

	render() {
		return (
			<div className="login-wrap" id="auth-section">
				<div className="row m-0">
					<div className="col-sm-9 col-md-8 col-lg-6 col-xl-5 right-wrap mx-auto">
						<div className="col-sm-12 col-md-8 col-lg-9 mx-auto">
							<div className="text-center">
								<img src={threatcop} alt="email" className="threatcop-logo"></img>
							</div>
							<div className="content">
								<div className="login-box">
									<h4>Log in to your account</h4>
									<Form.Group controlId="formBasicEmail">
										<label>Email Address</label>
										<Form.Control
											type="email"
											autoComplete="off"
											disabled={this.state.disableEmail}
											value={this.state.email}
											onChange={(e) => this.setState({ email: e.target.value })}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													this.handleValidate();
												}
											}}
											placeholder="Please enter your registered email Id"
										/>
									</Form.Group>
									{this.state.showCompanyName ? (
										<Form.Group controlId="exampleForm.ControlSelect">
											<Form.Control
												as="select"
												className="form-company"
												value={this.state.companyId}
												onChange={(e) => this.setState({ companyId: e.target.value }, () => this.handleValidateCompany(this.state.companyId))}
											>
												<option value="0">Select Login Company</option>
												{this.state.companies.map((company, index) => {
													return (
														<option key={index} value={parseInt(company.companyId, 10)}>
															{company.companyName}
															{`( ${company.type} )`}
														</option>
													);
												})}
											</Form.Control>
										</Form.Group>
									) : null}
									{this.state.isPassword ? (
										<Form.Group controlId="formBasicEmail">
											<label>Password</label>
											<div className="showPasswordPeekViewAlignment">
												<Form.Control
													autoComplete="off"
													type={this.state.showPassword ? "text" : "password"}
													value={this.state.password}
													onChange={(e) => this.setState({ password: e.target.value })}
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															this.handleSubmit();
														}
													}}
													placeholder="Password"
												/>
												<div
													className="showPassword"
													onClick={() => {
														this.setState({ showPassword: !this.state.showPassword });
													}}
												>
													{this.state.showPassword ? <VisibilitySharpIcon /> : <VisibilityOffSharp />}
												</div>
											</div>
										</Form.Group>
									) : null}
									{this.state.twoFactor ? (
										<Form.Group controlId="formBasicEmail">
											<Form.Control
												type="text"
												value={this.state.otp}
												onChange={(e) => this.setState({ otp: e.target.value })}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														this.handleSubmit();
													}
												}}
												placeholder="One Time Password"
											/>
											{this.state.otpType && this.state.otpType === "email" ? (
												<span className="otp-timer">{this.state.twoFactor && this.state.timer > 0 ? this.state.showTimer : null}</span>
											) : null}
										</Form.Group>
									) : null}
									{this.state.otpType && this.state.otpType === "email" && this.state.twoFactor && this.state.timer <= 0 ? (
										<Button className="btn-link float-left" onClick={this.resendOTP}>
											Resend OTP
										</Button>
									) : null}
									
									{!this.state.isValidEmail ? (
										<Button disabled={this.state.loading} onClick={this.handleValidate} className="btn-login w-100">
											<Spinner loading={this.state.loading}></Spinner> Next
										</Button>
									) : (
										<> 
											<Button disabled={this.state.loading} onClick={this.handleSubmit} className="btn-login w-100">
												<Spinner loading={this.state.loading}></Spinner> Login
											</Button>
											{this.state.hasSSO?
												<Button disabled={this.state.ssoloading} onClick={this.handleSubmitSSO} className="btn-login w-100">
													<Spinner loading={this.state.ssoloading}></Spinner> Login via SSO
												</Button>: null
											}
											<Button variant="secondary" onClick={() => window.location.reload()}>
												<ArrowBackSharpIcon className="btn-icon"></ArrowBackSharpIcon>Go Back
											</Button>
										</>
									)}

									{this.state.isValidEmail ? (
										<p className="mt-2">Forgot Your Password? <a className="link-color ml-1" onClick={() => this.props.history.push("/forgot-password")}>Reset Now</a></p>
									) : null}

									{this.state.appType === "SaaS" && (
										<p className="mt-2">
											Don't have an account?{" "}
											<a className="link-color ml-1" onClick={() => this.props.history.push("/register")}>
												Click Here
											</a>
										</p>
									)}
								</div>
							</div>
						</div>
						<ToastContainer position="top-right" autoClose={5000} />
					</div>
				</div>
			</div>
		);
	}
}
