import swal from 'sweetalert';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Modal, Form, Button } from 'react-bootstrap';

import axios from '../../axios';
import apiError from '../../error';
import Spinner from '../spinner/spinner';

import './rightside-modal.css'

export default class RightSideModalGroup extends Component {
    state = {
        domains: [],
        loadingleft: false,
        loadingright: false,
        updateValue: true,
        disableBtn: false,
        statusCheck: false,
        disabledEdit: true,
        overrule: false,
        overruleLoc: false,
        phone: this.props.phone,
        email: this.props.email,
        groupId: this.props.groupId,
        parentId: this.props.parentId,
        lastname: this.props.lastname,
        targetId: this.props.targetId,
        position: this.props.position,
        firstname: this.props.firstname,
        managerEmail: this.props.managerEmail,
        location: this.props.location,
        department: this.props.department,
        updateEmail:false,
    }

    componentDidMount = () => {
        this.loadDomain();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.email !== prevProps.email || this.props.groupId !== prevProps.groupId) {
            if (this.state.updateValue) {
                this.setState({
                    disabledEdit: true,
                    statusCheck: false,
                    phone: this.props.phone,
                    email: this.props.email,
                    groupId: this.props.groupId,
                    lastname: this.props.lastname,
                    parentId: this.props.parentId,
                    targetId: this.props.targetId,
                    firstname: this.props.firstname,
                    managerEmail: this.props.managerEmail,
                    location: this.props.location,
                    department: this.props.department,
                    position: this.props.position ? this.props.position : '',
                    overrule: this.props.overrule,
                    overruleLoc: this.props.overruleLoc,
                    updateEmail:this.props.updateEmail,
                })
            } else {
                this.setState({ groupId: this.props.groupId,updateEmail: this.props.updateEmail,})
            }
        }
    }

    loadDomain = () => {
        axios.get('/api/domain/', {})
            .then(res => {
                if (res.data.domains && res.data.domains.length) {
                    let domains = [];
                    for (var i = 0; i < res.data.domains.length; i++) {
                        let domain = res.data.domains[i];
                        if (domain.isVerify) {
                            domains.push(domain.name)
                        }
                    }
                    this.setState({ domains: domains });
                }
            })
            .catch(err => {
                apiError(err)
            })
    }

    createGroup = () => {
        if (!this.props.groupName && this.props.groupName.length === 0) {
            toast.error("Please enter group name first");
            return false;
        }
        this.setState({ loadingleft: true, updateValue: false });
        axios.post('/api/groups', {
            name: this.props.groupName,
            parentId: this.props.parentId,
        }, {})
            .then(res => {
                this.setState({ groupId: res.data.groupId, loadingleft: false });
                this.props.handleGroupId(res.data.groupId);
                this.addOrUpdateTarget();
                this.props.nextPath('/department/groups/' + res.data.groupId);
            })
            .catch(err => {
                this.setState({ loadingleft: false, });
                apiError(err);
            })
    }

    handleValidate = () => {
        if (!this.state.groupId || this.state.groupId === 0 || parseInt(this.state.groupId, 10) === 0) {
            this.createGroup();
            return false;
        } else if (this.state.firstname.length === 0) {
            toast.error("Please Enter First Name");
            return false;
        } else if (this.state.email.length === 0) {
            toast.error("Please Enter Email Id");
            return false;
        } else if ((this.state.domains).indexOf((this.state.email).split('@')[1]) === -1) {
            toast.error("Domain Is Not Verified");
            return false;
        }
        return {
            targetId: this.state.targetId,
            groupId: this.state.groupId,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            position: this.state.position,
            department: this.state.department,
            location: this.state.location,
            managerEmail: this.state.managerEmail,
            overrule: this.state.overrule,
            overruleLoc: this.state.overruleLoc,
        }
    }
 

    addOrUpdateTarget = async (status) => {
        var data = this.handleValidate();
        if (data) {
            this.setState({ disableBtn: true });
            (this.state.targetId && this.state.targetId !== "0") ? this.setState({statusCheck: true}) : this.setState({statusCheck: false})
            status ? this.setState({loadingright: true}) : this.setState({ loadingleft : true })
            
            axios.post('/api/groups/target', data, {})
                .then(res => {
                    const { error } = res.data
                    switch (error) {
                        case "TARGET_DEPT_CONFLICT": {
                            return swal({
                                title: "Departments conflict occured",
                                icon: "warning",
                                text: "Are you sure you want to update department? This action will replace the department for this member with the new one.",
                                buttons: ["Cancel", "Update",],
                                confirmButton: "Update",
                                dangerMode: true,
                            })
                                .then((willSuccess) => {
                                    if (willSuccess) {
                                        return this.setState({ overrule: true }, () => {
                                            this.addOrUpdateTarget(status)
                                        });
                                    }
                                    else {
                                        swal("Cancelled", "Your action was cancelled.", "info");
                                        this.setState({ updateEmail:true })
                                    }
                                    if (status) this.props.handleModal(false); this.setState({lodaingleft: false});
                                    this.setState({ loadingright: false, disableBtn: false, statusCheck: true});
                                }).catch(() => {})
                        }
                        case "TARGET_DEPT_DELETE_CONFIRMATION": {
                            return swal({
                                title: "Departments conflict occurred",
                                icon: "warning",
                                text: "Are you sure you want to remove this user from its associated department?",
                                buttons: ["Cancel", "Yes Delete it!"],
                                confirmButton: "Update",
                                dangerMode: true,
                            })
                                .then(willSuccess => {
                                    if (willSuccess) {
                                        return this.setState({ overrule: true }, () => {
                                            this.addOrUpdateTarget(status)
                                        });
                                    }
                                    else {
                                        swal("Cancelled", "Your action was cancelled.", "info");
                                        this.setState({updateEmail:true})
                                    }
                                    if (status) this.props.handleModal(false); this.setState({lodaingleft: false});
                                    this.setState({  loadingright: false, disableBtn: false, statusCheck: true});
                                }).catch(err => null)
                        }
                        case "TARGET_LOCATION_CONFLICT": {
                            return swal({
                                title: "Location conflict occured",
                                icon: "warning",
                                text: "Are you sure you want to update location? This action will replace the location for this member with the new one.",
                                buttons: ["Cancle", "Update"],
                                confirmButton: "Update",
                                dangerMode: true,
                            })
                                .then(willSuccess => {
                                    if (willSuccess) {
                                        return this.setState({ overruleLoc: true }, () => {
                                            this.addOrUpdateTarget(status)
                                        });
                                    }
                                    else {
                                        swal("Cancelled", "Your action was cancelled.", "info");
                                        this.setState({updateEmail:true})
                                    }
                                    if (status) this.props.handleModal(false); this.setState({lodaingleft: false});
                                    this.setState({ loadingright: false, disableBtn: false, statusCheck: true});
                                }).catch(err => null)
                        }
                        case "TARGET_LOCATION_DELETE_CONFIRMATION": {
                            return swal({
                                title: "Location conflict occurred",
                                icon: "warning",
                                text: "Are you sure you want to removed the location info for this user. This will be applied company wide.",
                                buttons: ["Cancel", "Yes Delete it!",],
                                confirmButton: "Update",
                                dangerMode: true,
                            })
                                .then(willSuccess => {
                                    if (willSuccess) { return this.setState({ overruleLoc: true }, () => { this.addOrUpdateTarget(status) }); }
                                    else {
                                        swal("Cancelled", "Your action was cancelled.", "info");
                                        this.setState({updateEmail:true})
                                    }
                                    if (status) this.props.handleModal(false); this.setState({lodaingleft: false});
                                    this.setState({ loadingright: false, disableBtn: false, statusCheck: true});
                                }).catch(err => null)
                        }
                        default: 
                    }
                    this.state.statusCheck ? toast.success('User Updated Successfully'): toast.success('User Added Successfully')
                    if (status) this.props.handleModal(false)
                    this.props.load();
                    this.setState({
                        updateValue: true,
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        position: '',
                        department: '',
                        location: '',
                        targetId: '0',
                        managerEmail: '',
                        loadingleft: false,
                        loadingright: false,
                        overrule: false,
                        overruleLoc: false,
                    })
                    
                    status ? this.setState({loadingleft: false}) : this.setState({loadingright: false});
                    this.setState({ disableBtn: false, statusCheck: false });
                })
                .catch(err => {
                    this.setState({ loadingleft: false})
                    apiError(err);
                })
        }
    }


    checkAlreadyExists = (status = false) => {
        if (this.state.firstname.length === 0) {
            toast.error("Please Enter First Name");
            return false;
        } else if (this.state.email.length === 0) {
            toast.error("Please Enter Email Id");
            return false;
        } else if ((this.state.domains).indexOf((this.state.email).split('@')[1]) === -1) {
            toast.error("Domain Is Not Verified");
            return false;
        } else {
            status?this.setState({loadingright:true}):this.setState({loadingleft:true,updateEmail: false});
            axios.post('/api/groups/checkAlreadyExists', {
                email: this.state.email,
                groupId: this.state.groupId,
            }, {})
                .then(res => {  
                    if (res.data.success) {
                        swal({
                            title: `This email is already added in ${res.data.name} group.`,
                            icon: "warning",
                            text: "Still if you want to add click on Yes otherwise No!",
                            buttons: ['No', 'Yes'],
                        })
                            .then(willSuccess => {
                                if (willSuccess) {
                                    this.addOrUpdateTarget(status);
                                }
                            else{
                                status?this.setState({loadingright:false}):this.setState({loadingleft:false,updateEmail: true});
                            }
                            });
                    } else {
                       
                        this.addOrUpdateTarget(status);
                    }
                })
                .catch(err => apiError(err))
        }
    }

    render() {
        return (
            <Modal className="info-filter mainfooterModal right-modal-wrap right" show={this.props.show} onHide={() => this.props.handleModal(false)} autoFocus keyboard>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.updateEmail ? "Update":"Add"} User </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="addUserDetail">
                      <h6>Personal Details</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Label>First Name<span className="red-star">*</span></Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" value={this.state.firstname} onChange={(e) => this.setState({ firstname: e.target.value, disabledEdit: false })} placeholder="First Name" />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" value={this.state.lastname} onChange={(e) => this.setState({ lastname: e.target.value, disabledEdit: false })} placeholder="Last Name" />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                                <Form.Label>Email<span className="red-star">*</span></Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" disabled={this.state.updateEmail} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, disabledEdit: false })} placeholder="Please enter email" />
                                </Form.Group>
                            </div>

                            <div className="col-md-6">
                            <Form.Label>Phone</Form.Label>
                                <PhoneInput
                                    country={'in'}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone: phone, disabledEdit: false })}>
                                    </PhoneInput>
                            </div>
                         </div>
                    </div>

                    <div className="addUserDetail">
                         <h6>Official Details</h6>
                        <div className='row'>
                        <div className='col-md-6'>
                            <Form.Label >Position</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" value={this.state.position} onChange={(e) => this.setState({ position: e.target.value, disabledEdit: false })} placeholder="Please enter position" />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                            <Form.Label >Department</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" value={this.state.department} onChange={(e) => this.setState({ department: e.target.value, disabledEdit: false })} placeholder="Please enter department" />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                            <Form.Label >Location</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="new-password" value={this.state.location} onChange={(e) => this.setState({ location: e.target.value, disabledEdit: false })} placeholder="Please enter location" />
                                </Form.Group>
                            </div>

                            <div className='col-md-6'>
                            <Form.Label >Manager's Email</Form.Label>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" autoComplete="email" value={this.state.managerEmail} onChange={(e) => this.setState({ managerEmail: e.target.value, disabledEdit: false })} placeholder="Please enter manager's email" />
                                </Form.Group>
                            </div>
                        </div>
                    </div>       
            </Modal.Body>

                <div className="modalFooter">
				    <div className="row">
						<div className="col-md-12 text-right">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <Button variant="success" className="mr-2" onClick={e => this.checkAlreadyExists(false)} disabled={this.state.loading || this.state.disabledEdit}><Spinner loading={this.state.loading}></Spinner>{this.props.targetId && parseInt(this.props.targetId, 10) !== 0 ? 'Update and add another' : 'Save and add another'}</Button>
                                <Button variant="success" onClick={e => this.checkAlreadyExists(true)} disabled={this.state.loading || this.state.disabledEdit}><Spinner loading={this.state.loading}></Spinner>{this.props.targetId && parseInt(this.props.targetId, 10) !== 0 ? 'Update User' : 'Add User '}</Button>
                            </div>
                        </div>
                         </div>
                     </div>
                    </div>

            </Modal>
        );
    }
}