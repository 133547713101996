import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import MoreVertSharpIcon from "@material-ui/icons/MoreVertSharp";

import { getDate } from '../../function';

export default class Department extends Component {
    render() {
        const group = this.props.group;
        return (
            <tbody>
                <tr>
                    <td>
                        {group.name}
                    </td>
                    <td>
                        {getDate(group.createdAt)}
                    </td>
                    <td>
                        {group.targets} {group.targets > 1 ? 'Members' : 'Member'}
                    </td>
                    <td>
                        {group.status === "completed" ? <span className='badge alert-success'>{group.status}</span> : ''}
                        {group.status === "failed" ? <span className='badge alert-danger '>{group.status}</span> : ''}
                        {group.status === "pending" ? <span className='badge alert-warning'>{group.status}</span> : ''}
                    </td>
                    <td>
                        <Dropdown
                            className="action-dropdown dropdownIcon" ref={this.selectRef}>
                            <Dropdown.Toggle className="btn-only" id="dropdown-basic">
                                <MoreVertSharpIcon className="dot-icon"></MoreVertSharpIcon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={e => this.props.nextPath('/department/groups/' + this.props.group.id)}>Edit</Dropdown.Item>
                                <Dropdown.Item onClick={e => this.props.handleDeleteGroup(this.props.group.id)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                </tr>
            </tbody>
        )
    }
}