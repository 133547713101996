import swal from 'sweetalert';
import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';

// import TwoFa from './two-fa';
import axios from '../../../axios';
import apiError from '../../../error';
import { setCookie } from '../../../function';
import Loader from '../../../component/loader/loader';

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            lastname: '',
            firstname: '',
            modal: false,
            otpType: null,
            loading: true,
            disabled: true,
            twoFactor: false,
        }
    }

    componentDidMount = () => {
        this.loadProfile();
    }

    loadProfile = () => {
        this.setState({ loading: true })
        axios.get('/api/user', {})
            .then(res => {
                this.setState({
                    loading: false,
                    email: res.data.user.email,
                    firstname: res.data.user.firstname,
                    lastname: res.data.user.lastname,
                    twoFactor: res.data.user.twoFactor,
                    otpType: res.data.user.twoFactor ? res.data.user.otpType : null,
                })
            })
            .catch(err => {
                apiError(err);
                this.setState({ loading: false });
            })
    }

    handleProfile = () => {
        if (this.state.firstname.length === 0 || (this.state.firstname).trim().length === 0) {
            toast.error("Please Enter FirstName");
            return false;
        }
        if (this.state.lastname.length === 0 || (this.state.lastname).trim().length === 0) {
            toast.error("Please Enter LastName");
            return false;
        }
        axios.patch('/api/user', {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
        })
            .then(res => {
                toast.success("Profile Updated Successfully");
                setCookie('firstname', this.state.firstname, 30000);
            })
            .catch(err => {
                apiError(err);
            })
    }

    deleteAccount = () => {
        swal({
            title: "Do you want to delete this Account?",
            icon: "info",
            text: "If you delete account you will not recover your data!",
            buttons: ['No', 'Yes'],
        })
            .then(willSuccess => {
                if (willSuccess) {
                    this.setState({ loading: true })
                    axios.post('/api/user/disabled', {})
                        .then(r => {
                            toast.success('Please check your mail for confirmation!');
                            this.setState({ loading: false })
                        })
                        .catch(err => {
                            apiError(err);
                            this.setState({ loading: false })
                        })
                }
            })
    }

    render() {
        return (
            <div className="tab-wrap">
                <div className='tabHeading'>
                    <h6>User Information</h6>
                    <p>User-specific settings and personal information stored within the system. </p>
                </div>
                <div className='col-md-4 pl-0'>
                    <div className='profile-form'>
                        <Form>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First name" value={this.state.firstname} onChange={e => this.setState({ firstname: e.target.value, disabled: false })} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Last name" value={this.state.lastname} onChange={e => this.setState({ lastname: e.target.value, disabled: false })} />
                            </Form.Group>
                            {this.state.twoFactor &&
                                <Form.Group>
                                    <Form.Label style={{ marginTop: '35px' }}>Two Factor is Enabled through <span style={{ color: 'green' }}>{this.state.otpType === 'email' ? "Email" : "Mobile App"}</span></Form.Label>
                                </Form.Group>
                            }

                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" disabled={true} value={this.state.email} />
                            </Form.Group>
                            <div className='formBtn'>
                                <Button variant="success" className="btn btn-success mr-2" type="button" disabled={this.state.disabled} onClick={this.handleProfile}>Update</Button>
                            </div>

                        </Form>
                    </div>
                </div>

                <div className='profile-form'>
                    <div className="tabHeading">
                        <h6>Delete Account</h6>
                        <p>Deleting your account will remove all your content, messages and data associated with it</p>
                    </div>
                    <Button className="deleteBtn" onClick={this.deleteAccount}>Delete my account</Button>
                </div>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}