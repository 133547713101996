import React, { Component } from "react";
import { Button, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { VisibilityOffSharp } from "@mui/icons-material";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import axios from '../../axios';
import apiError from "../../error";
import threatcop from '../../assets/threatcop-b.png';
import Spinner from '../../component/spinner/spinner';
import './resetpassword.css'

export default class ResetPassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            password: '',
            loading: false,
            isVerify: false,
            confirmPassword: '',
            showPassword: false,
            showConfirmPassword: false
        }
    }

    handlePassword = () => {
        if(this.state.password.length === 0){
            toast.error("Please Enter Password")
            return ;
        } else if(this.state.confirmPassword.length === 0){
            toast.error("Please Enter Confirm Password")
            return ;
        }
        if(this.state.password !== this.state.confirmPassword){
            toast.error("Password And Confirm Password Does Not Match")
            return ;
        }

        this.setState({ loading: true });
        axios.post('/api/auth/verify', {
            password: this.state.password,
            email: this.props.match.params.email,
            verf_key: this.props.match.params.token,
            confirmPassword: this.state.confirmPassword
        })
        .then(res => {
            this.setState({ isVerify: true, loading: false });
        })
        .catch(err => {
            this.setState({ loading: false });
            apiError(err)
        })
    }

    render() {
        return (
            <div className="login-wrap" id="auth-section">
                <div className="row m-0">
                    <div className="col-sm-4 right-wrap mx-auto">
                        <div className="col-sm-10 mx-auto p-0">
                        <div className="text-center">
                                <img src={threatcop} alt="email" className="threatcop-logo"></img>
                            </div>
                                <div className="content">
                                    {!this.state.isVerify?
                                        <div className="login-box">
                                            <h4>Create Password</h4>
                                            <Form.Group controlId="formBasicEmail">
                                             <label>Password</label>
                                                <div className="showPasswordPeekViewAlignment">
                                                    <Form.Control 
                                                    type={this.state.showPassword ? "text" : "password"}
                                                    value={this.state.password} 
                                                    onChange={(e) => this.setState({ password: e.target.value  })}  
                                                    placeholder="Password" />
                                                    <div
                                                        className="showPassword"
                                                        onClick={() => {
                                                            this.setState({ showPassword: !this.state.showPassword });
                                                        }}
                                                    >
                                                        {this.state.showPassword ? <VisibilitySharpIcon /> : <VisibilityOffSharp />}
                                                        </div>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                <label>Confirm Password</label>
                                                <div className="showPasswordPeekViewAlignment">
                                                    <Form.Control 
                                                    type={this.state.showConfirmPassword ? "text" : "password"}
                                                    value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value  })}  
                                                    placeholder="Confirm Password" />
                                                    <div
                                                        className="showPassword"
                                                        onClick={() => {
                                                            this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
                                                        }}
                                                    >
                                                        {this.state.showConfirmPassword ? <VisibilitySharpIcon /> : <VisibilityOffSharp />}
                                                        </div>
                                                </div>
                                            </Form.Group>
                                            
                                            <div className="resetpasswordNote">  
                                            <p>Note: Password must be</p>
                                              
                                                <ul>
                                                    <li>Between 8 and 15 characters long</li>
                                                    <li>Must contain at least one numeric digit</li>
                                                    <li>One uppercase letter</li>
                                                    <li>One lowercase letter</li>
                                                    <li>one special character ($@!%*#?&)</li>
                                                </ul>
                                             </div>
                                           
                                            <Button className="btn-login w-100" disabled={this.state.password.length === 0} onClick={this.handlePassword}> <Spinner loading={this.state.loading}></Spinner> Save Password</Button>
                                        </div>: 
                                        <div className="forget-msg">
                                            <h5>Reset Password</h5>
                                            <h6>Your email id and password has been verified</h6>
                                            <p>Click Here to<Button className="btn-link ml-1" onClick={() => this.props.history.push('/login')}>Login</Button></p>
                                        </div>
                                    }
                                </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" autoClose={5000}/>
            </div>
        );
    }
}