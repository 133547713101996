import React, { Component } from "react";
import { getRandomColor } from '../../../function';

import '../evs.css'
export default class EVStable extends Component {

    render() {

        const evs = this.props.evs, score = parseInt(this.props.evs.percent);
        const evsColor = getRandomColor(evs.target.firstname);
        var fullname = evs.target.firstname + " ";
        fullname += evs.target.lastname ? evs.target.lastname : '';

        return (
            <tr>
                <td>
                    <div className="flexTableData">
                        <div className="circle">
                            <p className="circle-inner">{evsColor && evsColor.name ? evsColor.name : fullname.charAt(0)}</p>
                        </div>
                        <div>
                            <h6>{fullname}</h6>
                        </div>
                    </div>
                </td>
                <td>{evs.target.email}</td>
                <td>{evs.target.phone ? evs.target.phone : '---'}</td>
                <td>
                    {(score >= 66) ? <p style={{ "color": "red" }}>{Math.trunc(score)}%</p> : (score >= 33) ? <p style={{ "color": "#F1B44C" }}>{Math.trunc(score)}%</p> : <p style={{ "color": "#82b8f5" }}>{Math.trunc(score)}%</p>}
                </td>
                <td>
                    <a className="viewCss" onClick={e => this.props.nextPath('/evs/' + evs.id)}>View</a>
                </td>
            </tr>
        );
    }
}