import React, { Component } from "react";
import jquery from "jquery";
import swal from "sweetalert";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import ArrowBackSharpIcon from "@material-ui/icons/ArrowBackSharp";

import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../../component/loader/loader";
import Game from "../../../component/create-template/game/game";
import Pages from "../../../component/create-template/pages/pages";
import Preview from "../../../component/create-template/preview/preview";
import NavTabs from "../../../component/create-template/nav-tabs/nav-tabs";
import Training from "../../../component/create-template/training/training";
import Templates from "../../../component/create-template/templates/templates";
import TemplatesSMS from "../../../component/create-template/templates-sms/templates-sms";
import CampaignName from "../../../component/create-template/campaign-name/campaign-name";
import TemplatesCall from "../../../component/create-template/templates-call/templates-call";
import AttackProfile from "../../../component/create-template/attacker-profile/attacker-profile";
import AttachmentTemplate from "../../../component/create-template/templates/attachment-template";
import GenerateTemplate from "../../../component/create-template/generate-template/generate-template";
import TemplatesWhatsApp from "../../../component/create-template/templates-message/templates-message";
import AttackProfileSMS from "../../../component/create-template/attacker-profile-sms/attacker-profile-sms";
import AttackProfileCall from "../../../component/create-template/attacker-profile-call/attacker-profile-call";
import AttackProfileWhatsApp from "../../../component/create-template/attacker-profile-whatsapp/attacker-profile-whatsapp";

import "./campaign.css";
import { SOCKET_EVENTS, socket } from "../../../socket";

export default class CampaignCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSocketConnected: false,
			attack_type: [],
			templateName: "",
			simulation_type: [],
			campaignNameShow: true,
			generateTemplateShow: true,
			attackTypeId: -1,
			simulationTypeId: -1,
			attack_type_name: {},
			simulation_type_name: {},
			pageId: 0,
			gameId: 0,
			senderId: 0,
			templateId: 0,
			trainingId: 0,
			categoryId: 0,
			languageId: 0,
			loading: true,
			redisKey: "",
			landing_url: "",
			showNextTab: false,
			disableState: false,
			senderType: "automatic",
			campaignNameLoading: false,
			credential_harvesting: false,
			campaignTemplateId: parseInt(this.props.match.params.id, 10),
			companyUrl: "",
			context: "",
			companyLogo: "",
			filePath: "",
			message: "",
			show: false,
			templateResponse: [],
			count: 5,
			htmlContent: "",
			subContent: "",
			generationAttemptsCount: "",
			tabBtn: true,
			tabValue: "information",
			generationAttempts: "",
			showColorLogoFeature: false,
			lang: false,
			currentIndex: 0,
			defaultColor: null,
			defaultColor1: "",
			defaultColor2: "",
			defaultColor3: "",
			defaultColor4: "",
			defaultColor5: "",
			colorIndex: 0,
			backgroundHeader: null,
			logoPosition: null,
			backgroundHeader1: "",
            backgroundHeader2: "",
            backgroundHeader3: "",
            backgroundHeader4: "",
            backgroundHeader5: "",
            logoPosition1: "",
            logoPosition2: "",
            logoPosition3: "",
            logoPosition4: "",
            logoPosition5: "",
			/* course template */
			sendCourseTemplateId: null,
			courseCompletionTemplateId: null,
			courseQuizFailReminderTemplateId: null,
			sendQuizTemplateId: null,
		};
	}

	load = async () => {
		if (this.state.campaignTemplateId) {
			axios
				.get("/api/campaign/template/" + this.state.campaignTemplateId, {})
				.then((res) => {
					this.setState({
						loading: false,
						landing_url: res.data.landing_url,
						pageId: res.data.campaign_template.pageId,
						gameId: res.data.campaign_template.gameId,
						templateName: res.data.campaign_template.name,
						senderId: res.data.campaign_template.senderId,
						trainingId: res.data.campaign_template.courseId,
						templateId: res.data.campaign_template.templateId,
						senderType: res.data.campaign_template.senderType,
						categoryId: res.data.campaign_template.categoryId,
						languageId: res.data.campaign_template.languageId,
						attackTypeId: res.data.campaign_template.attackTypeId,
						simulationTypeId: res.data.campaign_template.simulationTypeId,
						disableState: res.data.campaign_template.simulationTypeId ? true : false,
						credential_harvesting: res.data.campaign_template.credential_harvesting,
						/* course template */
						sendCourseTemplateId: res.data.campaign_template.sendCourseTemplateId,
						courseCompletionTemplateId: res.data.campaign_template.courseCompletionTemplateId,
						courseQuizFailReminderTemplateId: res.data.campaign_template.courseQuizFailReminderTemplateId,
						sendQuizTemplateId: res.data.campaign_template.sendQuizTemplateId,
					}, () => this.loadSimulaitonType());
				})
				.catch((error) => {
					this.setState({ loading: false });
					apiError(error);
				});
		} else {
			this.loadSimulaitonType();
		}
	};

	loadSimulaitonType = async () => {
		this.setState({ loading: true });
		axios
			.get("/api/simulation/type", {})
			.then((res) => {
				this.setState({ loading: false, simulation_type: res.data.simulation_type });
				for (let i = 0; res.data.simulation_type && i < res.data.simulation_type.length; i++) {
					if (res.data.simulation_type[i].id === this.state.simulationTypeId) {
						this.setState({ simulation_type_name: res.data.simulation_type[i].name });
					}
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
				apiError(error);
			});
		if (this.state.simulationTypeId && this.state.simulationTypeId !== -1) {
			axios
				.get("/api/simulation/type/" + this.state.simulationTypeId, {})
				.then((res) => {
					this.setState({ attack_type: res.data.simulation_attack });
					for (let i = 0; res.data.simulation_attack && i < res.data.simulation_attack.length; i++) {
						if (res.data.simulation_attack[i].attack_type.id === this.state.attackTypeId) {
							this.setState({ attack_type_name: res.data.simulation_attack[i].attack_type.name, showNextTab: true });
						}
					}
				})
				.catch((error) => apiError(error));
		}
	};

	handleSimulationType = async (e) => {
		let simulation_type = JSON.parse(e.target.value);
		this.setState({
			attackTypeId: -1,
			attack_type_name: "",
			simulationTypeId: simulation_type.id,
			simulation_type_name: simulation_type.name,
		});
		if (parseInt(simulation_type.id, 10) !== -1) {
			this.setState({ loading: true });
			axios
				.get("/api/simulation/type/" + simulation_type.id, {})
				.then((res) => this.setState({ attack_type: res.data.simulation_attack, loading: false, showNextTab: true }))
				.catch((error) => {
					apiError(error);
					this.setState({ loading: false });
				});
		} else {
			this.setState({ attack_type: [] });
		}
	};

	handleHarvesting = () => {
		this.setState({ credential_harvesting: !this.state["credential_harvesting"] });
	};

	handleAttackType = async (e) => {
		let attack_type = JSON.parse(e.target.value);
		this.setState({
			attackTypeId: attack_type.id,
			attack_type_name: attack_type.name,
		});
		if (attack_type.name === "Ransomware") {
			this.setState({ credential_harvesting: true });
		}
	};

	handleSenderType = () => {
		axios
			.post("/api/campaign/template/senderType/" + this.state.campaignTemplateId, {})
			.then((res) => this.setState({ senderType: res.data.senderType }))
			.catch((error) => apiError(error));
	};

	handleCampaignName = (e) => {
		this.setState({ templateName: e.target.value });
	};

	handleCampaignNameShow = () => {
		this.setState({ campaignNameShow: !this.state.campaignNameShow });
	};

	handleSenderId = (senderId) => {
		this.setState({ senderId: senderId });
	};

	handleTemplateId = (templateId) => {
		this.setState({ templateId: templateId });
	};

	handlePageId = (pageId) => {
		this.setState({ pageId: pageId });
	};

	handleCategoryId = (e) => {
		this.setState({ categoryId: e.target.value });
	};

	handleLanguageId = (e) => {
		let langValue = e.target[e.target.value].text;
		if (langValue === "Arabic" || langValue === "Urdu" || langValue === "Persian" || langValue === "Turkish") {
			this.setState({ lang: true });
			this.setState({ languageId: e.target.value });
		} else this.setState({ lang: false });
		this.setState({ languageId: e.target.value });
	};

	handleTraining = (trainingId) => {
		this.setState({ trainingId: trainingId });
	};

	handleCompanyUrl = (e) => {
		this.setState({ companyUrl: e.target.value });
	};
	handleContext = (e) => {
		this.setState({ context: e.target.value });
	};
	handleClose = () => {
		this.setState({ show: false });
	};

	handleValidateAI = () => {
		//eslint-disable-next-line
		const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
		if (this.state.companyUrl.length === 0 || this.state.companyUrl.trim().length === 0 || !urlRegex.test(this.state.companyUrl)) {
			toast.error("Please Enter Valid Company URL");
			return false;
		} else if (this.state.context.length === 0 || this.state.context.trim().length === 0) {
			toast.error("Please Enter Context");
			return false;
		} else if (this.state.filePath.length === 0 || this.state.filePath.trim().length === 0) {
			toast.error("Please Select Company Logo");
			return false;
		} else if (this.state.languageId === 0 || this.state.languageId.trim().length === 0) {
			toast.error("Please Select Language");
		}
		return {
			companyUrl: this.state.companyUrl,
			context: this.state.context,
			filePath: this.state.filePath,
			languageId: this.state.languageId,
		};
	};

	validateInfoTemplate = (key) => {
		let data = this.handleValidate();
		if (data) {
			this.setState({ tabBtn: false, tabValue: key });
		}
	};

	previousTab = () => {
		this.setState({ tabBtn: false, tabValue: "information" });
	};

	initializeSocket() {
		if (!socket.connected) socket.connect();
		else this.setState({ isSocketConnected: true })

		socket.on('connect', () => {
			this.setState({ isSocketConnected: true })
		}, function (error) {});
		
		socket.on('disconnect', () => {
			this.setState({ isSocketConnected: false });
		})

		socket.on(SOCKET_EVENTS.AI_TEMPLATE_DATA, this.saveAiTemplate.bind(this))
	}

	saveAiTemplate = (response) => {
		this.setState({ loading: false });
		let regexForSubject = /<h2\s*id=['"]emailSubject['"]>(.*?)<\/h2>/i;
		let aiTemplateSubjectHtml = response.content;
		let match = aiTemplateSubjectHtml.match(regexForSubject);
		let subjectMatched;
		if (match) subjectMatched = match[1];
		else subjectMatched = "";

		let removedContent = aiTemplateSubjectHtml.replace(regexForSubject, "").trim();

		this.setState({ [`defaultColor${this.state.colorIndex + 1}`]: response.color });
		this.setState({ [`logoPosition${this.state.colorIndex + 1}`]: "left" });
		this.setState({[`backgroundHeader${this.state.colorIndex + 1}`]: response.color})

		let value = this.state.colorIndex + 1;
		this.setState({ colorIndex: value })

		this.setState({ showColorLogoFeature: true });
		this.setState({
			htmlContent: removedContent,
			count: this.state.count - 1,
			generationAttempts: response.generationAttempts,
			subContent: subjectMatched,
		});
		let newTemplateResponse = [...this.state.templateResponse];
		newTemplateResponse.push(removedContent);
		const MAX_RESPONSES = 10; // adjust this value to your desired limit
		if (newTemplateResponse.length > MAX_RESPONSES) {
			newTemplateResponse.shift();
		}

		this.setState({ templateResponse: newTemplateResponse });
		this.setState({defaultColor: response.color})
		this.setState({logoPosition: "left"})
	}

	updateCurrentIndex = (newIndex) => {
        this.setState({ currentIndex: newIndex });
    };

	updateDefaultColor = (defaultColor) => {
		this.setState({ defaultColor: defaultColor });
	}
	updateBackgroundHeader = (bgHeader) => {
		this.setState({ backgroundHeader: bgHeader });
	}
	updateBackgroundHeaderWithIdx = (backgroundHeader, idx) => {
		this.setState({
            [`backgroundHeader${idx + 1}`]: backgroundHeader,
        });
	}

	updateLogoPosition = (logoPos) => {
		this.setState({ logoPosition: logoPos });
	}
	updateLogoPositionWithIdx = (logoPosition, idx) => {
		this.setState({
			[`logoPosition${idx + 1}`]: logoPosition
		});
	}

	handleGenerateAi = async () => {
		try {
			if (this.state.templateResponse.length >= 5) {
				toast.error('You Have Reached Your Maximum Limit For Template Generation. Please Select Anyone Template To Proceed.');
				socket.disconnect();
				return;
			}
			if (!this.state.isSocketConnected) return console.log("Socket Still Not Connected")
			let data = this.handleValidateAI();
			if (data) {
				this.setState({ loading: true })
				const res = await axios.post("/api/campaign/template/generate", data)
				if (res) {
					this.findAttempts();
				} else this.setState({ loading: false });
			}
		} catch (err) {
			this.setState({ loading: false });
			apiError(err);
		};
	}

	handleAPiForImage = (formData) => {
		axios
			.post("/api/campaign/template/generate/asset", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				this.setState({ filePath: res.data.filePath });
			})
			.catch((error) => { });
	};

	handleCompanyLogo = (e) => {
		if (e.target.files.length > 0) {
			this.setState({ companyLogo: e.target.files[0].name });
			let formData = new FormData();
			formData.append("companyLogo", e.target.files[0]);
			this.handleAPiForImage(formData);
		} else if (e.target.files.length === 0) {
			this.setState({ filePath: "" });
			this.setState({ companyLogo: "" });
		}
	};

	nextPath = (path) => {
		this.props.history.push(path);
	};

	handleTabChange = (type) => {
		try {
			setTimeout(function () {
				if (type === "next") {
					let tabId = jquery(".nav-link.active").parent().next().find("a").attr("id");
					document.getElementById(tabId).click();
				} else {
					let tabId = jquery(".nav-link.active").parent().prev().find("a").attr("id");
					document.getElementById(tabId).click();
				}
			}, 0);
		} catch (err) {
			console.log(err);
		}
	};

	componentDidMount = () => {
		this.initializeSocket();
		this.load();
		const pathname = window.location.pathname;
		if (pathname === "/templates/ai") {
			this.setState({ generateTemplateShow: false, templateResponse: [] });
		}
	};



	findAttempts = () => {
		axios
			.get("/api/campaign/template/generate/generationAttempts")
			.then((res) => {
				let attempts = JSON.stringify(res.data.generationAttempts.generationAttempts);
				this.setState({ generationAttemptsCount: attempts });
				if (this.state.generationAttemptsCount === "0") {
					this.setState({ loading: false });
					swal({
						title: "You have reached Maximum Attempt limits",
						icon: "warning",
						confirmButtonText: "Redirect",
					}).then((result) => {
						if (result) {
							window.location.href = "/templates/ai";
						} else {
							swal("Cancelled", "Your action was canceled.", "info");
						}
					});
				}
			})
			.catch((err) => {
				this.setState({ loading: false });
				apiError(err)
			});
	};

	handleValidate = () => {
		if (this.state.templateName.length === 0 || this.state.templateName.trim().length === 0) {
			toast.error("Please Enter Campaign Name");
			return false;
		} else if (parseInt(this.state.categoryId, 10) === -1 || parseInt(this.state.categoryId, 10) === 0) {
			toast.error("Please Select Category");
			return false;
		} else if (parseInt(this.state.languageId, 10) === 0) {
			toast.error("Please Select Language");
			return false;
		} else if (parseInt(this.state.simulationTypeId, 10) === -1) {
			toast.error("Please Select Simulation Type");
			return false;
		} else if (parseInt(this.state.attackTypeId, 10) === -1) {
			toast.error("Please Select Attack Vector");
			return false;
		}
		return {
			categoryId: this.state.categoryId,
			languageId: this.state.languageId,
			attackTypeId: this.state.attackTypeId,
			name: this.state.templateName.trim(),
			simulationTypeId: this.state.simulationTypeId,
			credential_harvesting: this.state.credential_harvesting,
		};
	};
	handleCreateCampaignTemplate = async (e, index) => {
		if (index) await this.setState({ currentIndex: index })
		const data = this.handleValidate();
		if (data) {
			if (this.state.campaignTemplateId > 0) {
				this.setState({ campaignNameLoading: true, loading: true });
				axios
					.patch("/api/campaign/template/" + this.state.campaignTemplateId, data)
					.then((res) => {
						this.setState({ campaignNameShow: false, campaignNameLoading: false, loading: false });
					})
					.catch((error) => {
						this.setState({ campaignNameLoading: false, loading: false });
						apiError(error);
					});
			} else {
				await this.setState({ loading: true });
				axios
					.post("/api/campaign/template", data)
					.then((res) => {
						toast.success("Campaign Template Created Successfully");
						this.setState({
							loading: false,
							disableState: true,
							campaignNameShow: false,
							landing_url: res.data.landing_url,
							senderType: res.data.campaign_template.senderType,
							campaignTemplateId: res.data.campaign_template.id,
						});
						this.props.history.push("/templates/" + res.data.campaign_template.id);
					})
					.catch((error) => {
						this.setState({ loading: false });
						apiError(error);
					});
			}
		}
	};

	render() {
		return (
			<div className="content-wrap">
				<div className="breadcrumbbar">
					<div className="flexRow row">
						<div className="col-md-4">
							<div className="page-head d-flex align-items-cEnter justify-content-between">
								<h4 className="page-title">
									<a href="" onClick={(e) => this.props.history.push("/templates")} className="bar_Icons mr-2"><ArrowBackSharpIcon className="btn-icon" />
									</a>Create Template</h4>
							</div>
						</div>
						<div className="col-md-8 flexFilterButtons">
						</div>
					</div>
				</div>
				<div className="page-content">
					{this.state.campaignNameShow ? (
						this.state.generateTemplateShow ? (
							<CampaignName
								categoryId={this.state.categoryId}
								languageId={this.state.languageId}
								showNextTab={this.state.showNextTab}
								attack_type={this.state.attack_type}
								disableState={this.state.disableState}
								templateName={this.state.templateName}
								attackTypeId={this.state.attackTypeId}
								handleTabChange={this.handleTabChange}
								handleHarvesting={this.handleHarvesting}
								handleAttackType={this.handleAttackType}
								handleCategoryId={this.handleCategoryId}
								handleLanguageId={this.handleLanguageId}
								attackTypeName={this.state.attack_type_name}
								simulation_type={this.state.simulation_type}
								handleCampaignName={this.handleCampaignName}
								simulationTypeId={this.state.simulationTypeId}
								handleSimulationType={this.handleSimulationType}
								campaignTemplateId={this.state.campaignTemplateId}
								simulationTypeName={this.state.simulation_type_name}
								campaignNameLoading={this.state.campaignNameLoading}
								handleCampaignNameShow={this.handleCampaignNameShow}
								credential_harvesting={this.state.credential_harvesting}
								handleCreateCampaignTemplate={this.handleCreateCampaignTemplate}
								lang={this.state.lang}
							></CampaignName>
						) : (
							<GenerateTemplate
								categoryId={this.state.categoryId}
								languageId={this.state.languageId}
								showNextTab={this.state.showNextTab}
								attack_type={this.state.attack_type}
								disableState={this.state.disableState}
								templateName={this.state.templateName}
								attackTypeId={this.state.attackTypeId}
								handleTabChange={this.handleTabChange}
								handleHarvesting={this.handleHarvesting}
								handleAttackType={this.handleAttackType}
								handleCategoryId={this.handleCategoryId}
								handleLanguageId={this.handleLanguageId}
								lang={this.state.lang}
								attackTypeName={this.state.attack_type_name}
								simulation_type={this.state.simulation_type}
								handleCampaignName={this.handleCampaignName}
								simulationTypeId={this.state.simulationTypeId}
								handleSimulationType={this.handleSimulationType}
								campaignTemplateId={this.state.campaignTemplateId}
								simulationTypeName={this.state.simulation_type_name}
								campaignNameLoading={this.state.campaignNameLoading}
								handleCampaignNameShow={this.handleCampaignNameShow}
								credential_harvesting={this.state.credential_harvesting}
								handleCreateCampaignTemplate={this.handleCreateCampaignTemplate}
								companyUrl={this.state.companyUrl}
								context={this.state.context}
								companyLogo={this.state.companyLogo}
								show={this.state.show}
								// companyName={this.state.companyName}
								// handleCompanyName={this.handleCompanyName}
								handleCompanyUrl={this.handleCompanyUrl}
								handleContext={this.handleContext}
								handleCompanyLogo={this.handleCompanyLogo}
								handleGenerateAi={this.handleGenerateAi}
								handleClose={this.handleClose}
								count={this.state.count}
								htmlContent={this.state.htmlContent}
								templateResponse={this.state.templateResponse}
								validateInfoTemplate={this.validateInfoTemplate}
								previousTab={this.previousTab}
								tabBtn={this.state.tabBtn}
								tabValue={this.state.tabValue}
								generationAttempts={this.state.generationAttempts}
								showColorLogoFeature={this.state.showColorLogoFeature}
								currentIndex={this.state.currentIndex}
								updateCurrentIndex={this.updateCurrentIndex}
								defaultColor={this.state.defaultColor}
								updateDefaultColor={this.updateDefaultColor}
								updateBackgroundHeader={this.updateBackgroundHeader}
								updateLogoPosition={this.updateLogoPosition}
								backgroundHeader={this.state.backgroundHeader}
								logoPosition={this.state.logoPosition}
								backgroundHeader1={this.state.backgroundHeader1}
								backgroundHeader2={this.state.backgroundHeader2}
								backgroundHeader3={this.state.backgroundHeader3}
								backgroundHeader4={this.state.backgroundHeader4}
								backgroundHeader5={this.state.backgroundHeader5}
								logoPosition1={this.state.logoPosition1}
								logoPosition2={this.state.logoPosition2}
								logoPosition3={this.state.logoPosition3}
								logoPosition4={this.state.logoPosition4}
								logoPosition5={this.state.logoPosition5}
								updateBackgroundHeaderWithIdx={this.updateBackgroundHeaderWithIdx}
								updateLogoPositionWithIdx={this.updateLogoPositionWithIdx}
								defaultColor1={this.state.defaultColor1}
								defaultColor2={this.state.defaultColor2}
								defaultColor3={this.state.defaultColor3}
								defaultColor4={this.state.defaultColor4}
								defaultColor5={this.state.defaultColor5}
							></GenerateTemplate>
						)
					) : (
						<>
							<Tab.Container
								id="left-tabs-example"
								defaultActiveKey={
									this.state.attack_type_name === "Phishing" ||
										this.state.attack_type_name === "Ransomware" ||
										this.state.attack_type_name === "Attachment" ||
										this.state.attack_type_name === "QRcode" ? "third" :
										this.state.attack_type_name === "Smishing" ||
											this.state.attack_type_name === "WhatsApp" ? "third-sms" :
											this.state.attack_type_name === "Training + Quiz" ? "second" : "second-call"
								}
							>
								<div className="row create-tab">
									<div className="col-md-3 left tempalte-nav-tab">
										<NavTabs
											pageId={this.state.pageId}
											gameId={this.state.gameId}
											senderId={this.state.senderId}
											templateId={this.state.templateId}
											trainingId={this.state.trainingId}
											awarenessId={this.state.awarenessId}
											attack_type={this.state.attack_type_name}
											simulation_type={this.state.simulation_type_name}
											campaignTemplateId={this.state.campaignTemplateId}
											handleCampaignNameShow={this.handleCampaignNameShow}
											credential_harvesting={this.state.credential_harvesting}
										></NavTabs>
									</div>
									<div className="col-md-9 contentRgt">
										<Tab.Content>
											{this.state.attack_type_name === "Phishing" ||
												this.state.attack_type_name === "QRcode" ||
												this.state.attack_type_name === "Ransomware" ||
												this.state.attack_type_name === "Attachment" ||
												this.state.attack_type_name === "Training + Quiz" ? (
												<Tab.Pane eventKey="second">
													<AttackProfile
														senderId={this.state.senderId}
														senderType={this.state.senderType}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														handleSenderType={this.handleSenderType}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfile>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Smishing" ? (
												<Tab.Pane eventKey="second-sms">
													<AttackProfileSMS
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
														landing_url={this.state.landing_url}
													></AttackProfileSMS>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "WhatsApp" ? (
												<Tab.Pane eventKey="second-sms">
													<AttackProfileWhatsApp
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfileWhatsApp>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Vishing" ? (
												<Tab.Pane eventKey="second-call">
													<AttackProfileCall
														senderId={this.state.senderId}
														handleSenderId={this.handleSenderId}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
													></AttackProfileCall>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Phishing" || this.state.attack_type_name === "Ransomware" || this.state.attack_type_name === "QRcode" ? (
												<Tab.Pane eventKey="third">
													<Templates
														templateId={this.state.templateId}
														subContent={this.state.subContent}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
														templateResponse={this.state.templateResponse}
														currentIndex={this.state.currentIndex}
														defaultColor={this.state.defaultColor}
														updateBackgroundHeader={this.updateBackgroundHeader}
														updateLogoPosition={this.updateLogoPosition}
														backgroundHeader={this.state.backgroundHeader}
														logoPosition={this.state.logoPosition}
													></Templates>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Attachment" ? (
												<Tab.Pane eventKey="third">
													<AttachmentTemplate
														templateId={this.state.templateId}
														subContent={this.state.subContent}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
														templateResponse={this.state.templateResponse}
														currentIndex={this.state.currentIndex}
														defaultColor={this.state.defaultColor}
														updateBackgroundHeader={this.updateBackgroundHeader}
														updateLogoPosition={this.updateLogoPosition}
														backgroundHeader={this.state.backgroundHeader}
														logoPosition={this.state.logoPosition}
													></AttachmentTemplate>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Smishing" ? (
												<Tab.Pane eventKey="third-sms">
													<TemplatesSMS
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesSMS>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "WhatsApp" ? (
												<Tab.Pane eventKey="third-sms">
													<TemplatesWhatsApp
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesWhatsApp>
												</Tab.Pane>
											) : null}
											{this.state.attack_type_name === "Vishing" ? (
												<Tab.Pane eventKey="third-call">
													<TemplatesCall
														templateId={this.state.templateId}
														handleTabChange={this.handleTabChange}
														handleTemplateId={this.handleTemplateId}
														campaignTemplateId={this.state.campaignTemplateId}
													></TemplatesCall>
												</Tab.Pane>
											) : null}
											<Tab.Pane eventKey="fourth">
												<Pages
													pageId={this.state.pageId}
													handlePageId={this.handlePageId}
													landing_url={this.state.landing_url}
													handleTabChange={this.handleTabChange}
													attack_type_name={this.state.attack_type_name}
													campaignTemplateId={this.state.campaignTemplateId}
												></Pages>
											</Tab.Pane>
											<Tab.Pane eventKey="six">
												{this.state.simulation_type_name.indexOf("Training") > -1 && (
													<Training
														trainingId={this.state.trainingId}
														handleTraining={this.handleTraining}
														handleTabChange={this.handleTabChange}
														campaignTemplateId={this.state.campaignTemplateId}
														sendCourseTemplateId={this.state.sendCourseTemplateId}
														courseCompletionTemplateId={this.state.courseCompletionTemplateId}
														courseQuizFailReminderTemplateId={this.state.courseQuizFailReminderTemplateId}
														sendQuizTemplateId={this.state.sendQuizTemplateId}
													></Training>
												)}
											</Tab.Pane>
											<Tab.Pane eventKey="seven">
												<Game
													gameId={this.state.gameId}
													handleTabChange={this.handleTabChange}
													campaignTemplateId={this.state.campaignTemplateId}
												></Game>
											</Tab.Pane>
											<Tab.Pane eventKey="eight">
												<Preview nextPath={this.nextPath} campaignTemplateId={this.state.campaignTemplateId}></Preview>
											</Tab.Pane>
										</Tab.Content>
									</div>
								</div>
							</Tab.Container>
						</>
					)}
				</div>
				{this.state.loading ? <Loader></Loader> : null}
			</div>
		);
	}
}
