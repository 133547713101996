import { toast } from "react-toastify";
import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";

import axios from "../../../axios";
import apiError from "../../../error";
import Loader from "../../loader/loader";

export default class CampaignExpired extends Component {
	constructor(props) {
		super(props);
		this.state = {
			day: 0,
			disabled: true,
		};
	}

	componentDidMount = () => {
		this.loadDay();
	};

	loadDay = () => {
        axios.get('/api/meta/days', {})
            .then(res => {
                this.setState({ day: res.data.day })
            })
            .catch(err => {
                apiError(err);
            })
    }

    handleDay = () => {
		if(this.state.day > 7){
			toast.info('Cmapign Expired Time Should Not Grater Than 7 Day.')
			return ;
		}
        axios.post('/api/meta/days', {
            day: this.state.day
        })
            .then(res => {
                toast.success("Day Updated Successfully");
            })
            .catch(err => {
                apiError(err);
            })
    }

	render() {
		return (
			<div className="tab-wrap">
				<div className="tabHeading">
					<h6>Campaign Auto Expiry</h6>
					<p>
					Indicates the number of days after which your campaign will expire.
					</p>
				</div>

				<div className="col-md-3 pl-0">
					<div className="profile-form">
						<Form>
							<Form.Group>
								<Form.Label>Set Campaign Auto Expiry (In Days)</Form.Label>
								<Form.Control
									type="number"
									value={this.state.day}
									onChange={(e) =>
										this.setState({ disabled: false, day: e.target.value })
									}
								/>
							</Form.Group>

							<div>
								<Button
									variant="success"
									type="button"
									onClick={this.handleDay}
									disabled={this.state.disabled}
								>
									Update
								</Button>
							</div>
						</Form>
					</div>
				</div>
				{this.state.loading ? <Loader></Loader> : null}
			</div>
		);
	}
}
