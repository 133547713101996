import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import React, { Component } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import FilterListSharpIcon from '@material-ui/icons/FilterListSharp';

import Loader from '../../../component/loader/loader';
import SMSSend from '../../../component/reports/charts/sms-send';

import CallPicked from '../../../component/reports/charts/call-picked';
import CallInitiated from '../../../component/reports/charts/call-initiated';
import AttachmentOpened from '../../../component/reports/charts/attachment-open';
import EmailSend from '../../../component/reports/charts/email-send';
import SystemHack from '../../../component/reports/charts/system-hack';
import ComponentLoader from '../../../component/loader/component-loader';
import EmailOpened from '../../../component/reports/charts/email-opened';
import TopCategory from '../../../component/reports/charts/top-category';
import ThreatScore from '../../../component/reports/charts/threat-score';
import LinkClicked from '../../../component/reports/charts/link-clicked';
import EmailClicked from '../../../component/reports/charts/email-clicked';
import AnalyticChart from '../../../component/reports/charts/analyticChart';
import DataSubmitted from '../../../component/reports/charts/data-submitted';
import EmailReported from '../../../component/reports/charts/email-reported';
import EmailSubmitted from '../../../component/reports/charts/email-submitted';
import ReportModal from '../../../component/reports/report-filter/report-filter';

import axios from '../../../axios';
import apiError from '../../../error';

import './campaign-analytics.css';

export default class CampaignAnalytics extends Component {
    constructor(props){
        super(props);
        this.printReport = this.printReport.bind(this);
        this.reportRef = React.createRef();
        this.state = {
            send: 0,
            opened: 0,
            clicked: 0,
            submitted: 0,
            loading: true,
            attackTypes: [],
            attackTypeId: 1,

            endDate: new Date(),
            startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),

            campaigns: [],
            campaignIds: [],
            selectedCampaignIds: [],

            groupLabel: [],
            groupSeries: [],

            categoryLabel: [],
            categorySeries: [],

            attacktypeName: {},
            reportModal: false,
            isDataLoaded: false,
            credentialHarvesting: 'NA',

            Phishingevents: {},
            Smishingevents: {},
            Vishingevents: {},
            Ransomwareevents: {},
            WhatsAppevents:{},
            Attachmentevents:{},
            QRcodeevents:{},

            locations: [],
            location: '',
        }
        this.loadAttackType();
    }

    loadAttackType = () => {
        axios.get('/api/attacktypes', {})
            .then(res => {
                if(res.data.attackTypes && res.data.attackTypes.length) {
                    let attacktypeName = {};
                    for (let i = 0; i < res.data.attackTypes.length; i++) {
                        attacktypeName[res.data.attackTypes[i].id] = res.data.attackTypes[i].name
                    }
                    this.setState({
                        attacktypeName: attacktypeName,
                        attackTypes: res.data.attackTypes,
                        attackTypeId: res.data.attackTypes[0].id,
                    }, () => {
                        this.loadCampaigns();
                    });
                }
            })
            .catch(err => apiError(err))
    }

    handleRefresh = () => {
        this.load();
        this.topCategory();
        this.groupRisk();
        this.loadLocation();
    }

    load = async() => {
        await this.setState({ loading: true, isDataLoaded: false })
        axios.post('/api/pdfreport', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let send = 0, submitted = 0, events = {}, clicked = 0, opened = 0;
                for (let i = 0; i < res.data.results.length; i++) {
                    let event = res.data.results[i];
                    if (event.message === 'Email Sent' || event.message === 'Message Sent' || event === 'SMS Sent' || event === 'Call Initiated') {
                        send = parseInt(event.count, 10);
                    } else if (event.message === 'Data Submitted' || event.message === "Attachment Opened") {
                        submitted = parseInt(event.count, 10);
                    } else if (event.message === 'Link Clicked' || event.message === 'Call Picked') {
                        clicked = parseInt(event.count, 10);
                    } else if (event.message === 'Email Opened') {
                        opened = parseInt(event.count, 10);
                    }
                    events[event.message] = parseInt(event.count, 10);
                }
                this.setState({
                    opened: opened,
                    loading: false,
                    clicked: clicked,
                    isDataLoaded: true,
                    send: send ? send : 0,
                    submitted: submitted ? submitted : 0,
                    [this.state.attacktypeName[this.state.attackTypeId] + 'events']: events,
                });
            })
            .catch(err => {
                apiError(err);
                this.setState({ events: {}, loading: false, isDataLoaded: true })
            })
    }
    topCategory = async () => {
        axios.post('/api/pdfreport/topCategory', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let label = [], series = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let category = res.data.results[i];
                    label.push(category.campaign_template.category.name);
                    if (this.state.credentialHarvesting !== 'true') {
                        if (this.state.attacktypeName[this.state.attackTypeId].name !== 'Vishing') {
                            series.push(parseInt(category['linkclicked'], 10));
                        } else {
                            series.push(parseInt(category['callpicked'], 10));
                        }
                    } else {
                        series.push(parseInt(category['datasubmitted'], 10));
                    }
                }
                this.setState({ categoryLabel: label, categorySeries: series })
            })
            .catch(err => {
                apiError(err);
            })
    }

    groupRisk = async () => {
        axios.post('/api/pdfreport/groupRisk', {
            location: this.state.location,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                let label = [], series = [];
                for (let i = 0; i < res.data.results.length; i++) {
                    let category = res.data.results[i];
                    label.push(category.group.name);
                    if (this.state.credentialHarvesting === 'false') {
                        if (this.state.attacktypeName[this.state.attackTypeId] !== 'Vishing') {
                            series.push(parseInt(category['linkclicked'], 10));
                        } else {
                            series.push(parseInt(category['callpicked'], 10));
                        }
                    } else {
                        series.push(parseInt(category['datasubmitted'], 10));
                    }
                }
                this.setState({ groupLabel: label, groupSeries: series })
            })
            .catch(err => {
                apiError(err);
            })
    }

    loadCampaigns = async() => {
        await this.setState({ loading: true })
        axios.get('/api/campaigns/list', {
            params: {
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                attackTypeId: this.state.attackTypeId
            }
        })
            .then(res => {
                var campaigns = [], selectedCampaignIds = [];
                for (var i = 0; i < res.data.campaigns.length; i++) {
                    campaigns.push({
                        value: res.data.campaigns[i].id,
                        label: res.data.campaigns[i].name
                    })
                    selectedCampaignIds.push(res.data.campaigns[i].id)
                }
                this.setState({
                    loading: false,
                    campaigns: campaigns,
                    campaignIds: campaigns,
                    selectedCampaignIds: selectedCampaignIds,
                }, () => {
                    this.handleRefresh();
                });
            })
            .catch(err => {
                apiError(err);
                this.setState({ campaigns: [], loading: false, selectedCampaignIds: [] })
            })
    }

    loadLocation = async () => {
        axios.post('/api/pdfreport/location', {
            endDate: this.state.endDate,
            startDate: this.state.startDate,
            attackTypeId: this.state.attackTypeId,
            selectedCampaignIds: this.state.selectedCampaignIds,
            credentialHarvesting: this.state.credentialHarvesting
        })
            .then(res => {
                this.setState({ locations: res.data.results });
            })
            .catch(err => {
                apiError(err);
            })
    }

    handleSelect = (startDate, endDate) => {
        this.setState({
            campaignIds: [],
            endDate: endDate,
            startDate: startDate,
            selectedCampaignIds: [],
        }, () => this.loadCampaigns())
    }

    handleCampaigns = (e) => {
        let selectedCampaignIds = [];
        for (let i = 0; i < e.length; i++) {
            selectedCampaignIds.push(e[i].value)
        }
        this.setState({ selectedCampaignIds: selectedCampaignIds, campaignIds: e })
    }

    downloadReport = async () => {
        const input = document.getElementById("report");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            this.downloadBase64File(imgData, 'report.png');
        })
    }

    downloadBase64File = (base64Data, fileName) => {
        const downloadLink = document.createElement("a");
        const linkSource = base64Data;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    printReport = () => {
        html2canvas(this.reportRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            let imageHeight = this.reportRef.current?.clientHeight;
            let imageWidth = this.reportRef.current?.clientWidth;
            let ratio = imageHeight / imageWidth;

            let orientation = 'portrait';

            if (ratio < 1) {
                orientation = 'landscape';
            }

            const pdf = new jsPDF({
                orientation: orientation,
                unit: 'pt',
                format: [canvas.width + 40, canvas.height + 40],
            });

            pdf.addImage(imgData, "JPEG", 20, 20, canvas.width, canvas.height);
            pdf.save(`Campaign-Analytics.pdf`);
        }).catch(err => {
            apiError(err);
        });
    }

    render() {
        const status = this.state[this.state.attacktypeName[this.state.attackTypeId] + 'events'];
        return (
            <div className="content-wrap">

                <div className="breadcrumbbar">
                    <div className="flexRow row">
                        <div className="col-md-4">
                            <div className="page-head d-flex align-items-center justify-content-between">
                                <h4 className="page-title">Campaigns Analytics</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <div className="page-content" id="graph-wrap">
                            <div className="row bg-light py-2 align-items-center">
                                <div className="col-md-3">
                                    <h6 className="tab-head mb-0">My Campaigns Report</h6>
                                </div>
                                <div className="col-md-9 text-left">
                                    <div className="d-flex justify-content-end">
                                        <Form.Group controlId="exampleForm.ControlSelect1" className="mr-2 mb-0">
                                            <Form.Control as="select" value={this.state.attackTypeId} onChange={e => this.setState({ attackTypeId: parseInt(e.target.value, 10) }, () => this.loadCampaigns())}>
                                                {this.state.attackTypes.map((attacktype, index) => {
                                                    return <option key={index} value={attacktype.id}>{attacktype.name}</option>
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button variant="primary" className="mr-2" onClick={this.printReport}>Download Report</Button>
                                        <Button variant="dark" onClick={() => this.setState({ reportModal: true })}><FilterListSharpIcon className="btn-icon" />Filter</Button>
                                    </div>
                                </div>
                            </div>
                            <div id="report" ref={this.reportRef}>
                                {this.state.isDataLoaded ?
                                    <div className="row align-items-center graph-row">
                                        {this.state.attacktypeName[this.state.attackTypeId] === "Phishing" || this.state.attacktypeName[this.state.attackTypeId] === "Ransomware" || this.state.attacktypeName[this.state.attackTypeId] === "QRcode" ?
                                            <div className="row justify-content-between">
                                                {'Email Sent' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <EmailSend
                                                            value={status['Email Sent'] ? status['Email Sent'] : 0}
                                                            percent={status['Email Sent'] ? 100 : 0}
                                                        ></EmailSend>
                                                    </div> : null
                                                }{'Email Opened' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <EmailOpened
                                                            value={status['Email Opened'] ? status['Email Opened'] : 0}
                                                            percent={Math.ceil((status['Email Opened'] * 100) / status['Email Sent']) ? Math.ceil((status['Email Opened'] * 100) / status['Email Sent']) : 0}
                                                        ></EmailOpened>
                                                    </div> : null
                                                }{'Link Clicked' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <EmailClicked
                                                            value={status['Link Clicked'] ? status['Link Clicked'] : 0}
                                                            percent={Math.ceil((status['Link Clicked'] * 100) / status['Email Sent']) ? Math.ceil((status['Link Clicked'] * 100) / status['Email Sent']) : 0}
                                                        ></EmailClicked>
                                                    </div> : null
                                                }{'Data Submitted' in status ?
                                                    this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ?
                                                        <div className="col-md-2 mb-3">
                                                            <EmailSubmitted
                                                                value={status['Data Submitted'] ? status['Data Submitted'] : 0}
                                                                percent={Math.ceil((status['Data Submitted'] * 100) / status['Email Sent']) ? Math.ceil((status['Data Submitted'] * 100) / status['Email Sent']) : 0}
                                                            ></EmailSubmitted>
                                                        </div> : null : null
                                                 }
                                                {'System Hacks' in status ?
                                                    this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ?
                                                        <div className="col-md-2 mb-3">
                                                            <SystemHack
                                                                value={status['System Hacks'] ? status['System Hacks'] : 0}
                                                                percent={Math.ceil((status['System Hacks'] * 100) / status['Email Sent']) ? Math.ceil((status['System Hacks'] * 100) / status['Email Sent']) : 0}
                                                            ></SystemHack>
                                                        </div> : null : null
                                                }{'Email Reported' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <EmailReported
                                                            value={status['Email Reported'] ? status['Email Reported'] : 0}
                                                            percent={Math.ceil((status['Email Reported'] * 100) / status['Email Sent'])}
                                                        ></EmailReported>
                                                    </div> : null
                                                }
                                            </div> : null
                                        }{this.state.attacktypeName[this.state.attackTypeId] === "Smishing" ?
                                            <div className="row justify-content-between">
                                                {'SMS Sent' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <SMSSend
                                                            value={status['SMS Sent'] ? status['SMS Sent'] : 0}
                                                            percent={status['SMS Sent'] ? 100 : 0}
                                                        ></SMSSend>
                                                    </div> : null
                                                }{'Link Clicked' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <LinkClicked
                                                            value={status['Link Clicked'] ? status['Link Clicked'] : 0}
                                                            percent={Math.ceil((status['Link Clicked'] * 100) / status['SMS Sent']) ? Math.ceil((status['Link Clicked'] * 100) / status['SMS Sent']) : 0}
                                                        ></LinkClicked>
                                                    </div> : null
                                                }{'Data Submitted' in status ?
                                                    this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ?
                                                        <div className="col-md-2 mb-3">
                                                            <DataSubmitted
                                                                value={status['Data Submitted'] ? status['Data Submitted'] : 0}
                                                                percent={Math.ceil((status['Data Submitted'] * 100) / status['SMS Sent']) ? Math.ceil((status['Data Submitted'] * 100) / status['SMS Sent']) : 0}
                                                            ></DataSubmitted>
                                                        </div> : null : null
                                                }
                                            </div> : null
                                        }{this.state.attacktypeName[this.state.attackTypeId] === "Vishing" ?
                                            <div className="row justify-content-between">
                                                <div className="col-md-2 mb-3">
                                                    <CallInitiated
                                                        value={status['Call Initiated'] ? status['Call Initiated'] : 0}
                                                        percent={100}
                                                    ></CallInitiated>
                                                </div>
                                                <div className="col-md-2 mb-3">
                                                    <CallPicked
                                                        value={status['Call Picked'] ? status['Call Picked'] : 0}
                                                        percent={Math.ceil((status['Call Picked'] * 100) / status['Call Initiated']) ? Math.ceil((status['Call Picked'] * 100) / status['Call Initiated']) : 0}
                                                    ></CallPicked>
                                                </div>
                                                {this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ?
                                                    <div className="col-md-2 mb-3">
                                                        <EmailSubmitted
                                                            value={status['Data Submitted'] ? status['Data Submitted'] : 0}
                                                            percent={Math.ceil((status['Data Submitted'] * 100) / status['Call Initiated']) ? Math.ceil((status['Data Submitted'] * 100) / status['Call Initiated']) : 0}
                                                        ></EmailSubmitted>
                                                    </div> : null
                                                }
                                            </div> : null
                                        }

                                          {this.state.attacktypeName[this.state.attackTypeId] === "Attachment" ?
                                               <div className="row justify-content-between">
                                               {'Email Sent' in status ?
                                                   <div className="col-md-2 mb-3">
                                                       <EmailSend
                                                           value={status['Email Sent'] ? status['Email Sent'] : 0}
                                                           percent={status['Email Sent'] ? 100 : 0}
                                                       ></EmailSend>
                                                   </div> : null
                                               }{'Email Opened' in status ?
                                                   <div className="col-md-2 mb-3">
                                                       <EmailOpened
                                                           value={status['Email Opened'] ? status['Email Opened'] : 0}
                                                           percent={Math.ceil((status['Email Opened'] * 100) / status['Email Sent']) ? Math.ceil((status['Email Opened'] * 100) / status['Email Sent']) : 0}
                                                       ></EmailOpened>
                                                   </div> : null
                                               }{'Attachment Opened' in status ?
                                                       <div className="col-md-2 mb-3">
                                                           <AttachmentOpened
                                                               value={status['Attachment Opened'] ? status['Attachment Opened'] : 0}
                                                               percent={Math.ceil((status['Attachment Opened'] * 100) / status['Email Sent']) ? Math.ceil((status['Attachment Opened'] * 100) / status['Email Sent']) : 0}
                                                           ></AttachmentOpened>
                                                       </div> : null
                                                }
                                               {/*{'Email Reported' in status ?
                                                   <div className="col-md-2 mb-3">
                                                       <EmailReported
                                                           value={status['Email Reported'] ? status['Email Reported'] : 0}
                                                           percent={Math.ceil((status['Email Reported'] * 100) / status['Email Sent'])}
                                                       ></EmailReported>
                                                   </div> : null
                                               } */}
                                           </div> : null
                                        }

                                         {this.state.attacktypeName[this.state.attackTypeId] === "WhatsApp" ?
                                            <div className="row justify-content-between">
                                                {'Message Sent' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <SMSSend
                                                            value={status['Message Sent'] ? status['Message Sent'] : 0}
                                                            percent={status['Message Sent'] ? 100 : 0}
                                                        ></SMSSend>
                                                    </div> : null
                                                }{'Link Clicked' in status ?
                                                    <div className="col-md-2 mb-3">
                                                        <LinkClicked
                                                            value={status['Link Clicked'] ? status['Link Clicked'] : 0}
                                                            percent={Math.ceil((status['Link Clicked'] * 100) / status['Message Sent']) ? Math.ceil((status['Link Clicked'] * 100) / status['Message Sent']) : 0}
                                                        ></LinkClicked>
                                                    </div> : null
                                                }
                                                {'Data Submitted' in status ?
                                                    this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ?
                                                        <div className="col-md-2 mb-3">
                                                            <DataSubmitted
                                                                value={status['Data Submitted'] ? status['Data Submitted'] : 0}
                                                                percent={Math.ceil((status['Data Submitted'] * 100) / status['Message Sent']) ? Math.ceil((status['Data Submitted'] * 100) / status['Message Sent']) : 0}
                                                            ></DataSubmitted>
                                                        </div> : null : null
                                                }

                                            </div> : null

                                        }   
                                    </div> : <ComponentLoader></ComponentLoader>
                                }
                                <div className="row">
                                    <div className="col-md-4 right-border">
                                        <h6>High Risk Category</h6>
                                        {this.state.isDataLoaded ?
                                            <TopCategory
                                                label={this.state.categoryLabel}
                                                series={this.state.categorySeries}
                                            ></TopCategory> : <ComponentLoader></ComponentLoader>
                                        }
                                    </div>
                                    <div className="col-md-4 right-border">
                                        <h6>Phish Risk</h6>
                                        {this.state.isDataLoaded ?
                                            <ThreatScore
                                                send={this.state.send}
                                                submitted={this.state.credentialHarvesting === 'true' || this.state.credentialHarvesting === 'NA' ? this.state.submitted : this.state.clicked}
                                            ></ThreatScore> : <ComponentLoader></ComponentLoader>
                                        }
                                    </div>
                                    <div className="col-md-4">
                                        <h6>High Risk Group</h6>
                                        {this.state.isDataLoaded ?
                                            <AnalyticChart
                                                label={this.state.groupLabel}
                                                series={this.state.groupSeries}
                                            ></AnalyticChart> : <ComponentLoader></ComponentLoader>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <ReportModal
                    /* for date range picker */
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleSelect={this.handleSelect}
                    /* for attack type */
                    attackTypes={this.state.attackTypes}
                    attackTypeId={this.state.attackTypeId}
                    attacktypeName={this.state.attacktypeName}
                    handleAttackType={e => this.setState({ attackTypeId: parseInt(e.target.value, 10) }, () => this.loadCampaigns())}
                    /* for campaigns */
                    campaigns={this.state.campaigns}
                    selectCampaigns={this.state.campaignIds}
                    handleCampaigns={this.handleCampaigns}
                    /* for modal */
                    reportModal={this.state.reportModal}
                    reportHandle={e => this.setState({ reportModal: false })}
                    /* for credencial harvesting */
                    credentialHarvesting={this.state.credentialHarvesting}
                    handleHarvesting={e => this.setState({ credentialHarvesting: e.target.value })}
                    /* for location */
                    location={this.state.location}
                    locations={this.state.locations}
                    handleLocation={e => this.setState({ location: e.target.value })}
                    /* clear filtes */
                    clearFilters={e => this.setState({
                        campaignIds: [],
                        location: null,
                        reportModal: false,
                        endDate: new Date(),
                        selectedCampaignIds: [],
                        credentialHarvesting: 'NA',
                        startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
                    }, () => this.loadCampaigns())}
                    applyFilter={e => this.setState({ reportModal: false }, () => this.handleRefresh())}
                ></ReportModal>
                {this.state.loading ? <Loader></Loader> : null}
            </div>
        );
    }
}